import { FontAwesome5 } from "@expo/vector-icons";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import merge from 'lodash/merge'
import { 
    StyleSheet, 
    TouchableHighlight, 
    View, 
    Image, 
    Linking, 
    ImageProps, 
    Animated, 
    Text,
    Platform,
} from 'react-native'
import backend from "../backend/backend";
import Colors from "../constants/Colors";
import { ListingType, BusinessItem, PlanFee } from "../constants/Models";
import { MediaQueryContext, ScreenSize } from "../contexts/MediaQueryContext";
import RoundyButton from "./RoundyButton";
import { MediumText, RegularText } from "./StyledText";
import RequestAppointment from "./RequestAppointment";
import Offering from "./BusinessListingSub/Offering";
import MatchText from "./BusinessListingSub/MatchText";
import { Title, LinkItem, Ratings, ContactInfo, asyncImageStyles, planDescStyles, imageStyles, styles } from "./BusinessListingSub";
import { ExpandCaret } from "./global";

// TODO this component is monolithic. We really need to break it into smaller sub components.

const openLink = (website: string) => { 
    if (Platform.OS == 'web'){
        window.open(website, '_blank');
    } else {
        Linking.openURL(website)
    }
}

interface BusinessListingProps {
    item: BusinessItem;
    isExpanded: boolean;

    /// Overall press
    onPress: () => void;
    onClaimAction?: () => Promise<void>;
    onEditAction?: () => void;
    setWayToSave?: Function;

    requestAppointment?: boolean;
}

const BusinessListing = ({ item: inputItem, isExpanded, onPress, onClaimAction, onEditAction, requestAppointment, setWayToSave }: BusinessListingProps) => {
    const { screenSize } = useContext(MediaQueryContext)
    const [item, setItem] = useState(inputItem)
    const [plansState, setPlansState] = useState<{[x: string]: boolean}>({})
    const [appointmentModalVisible, setAppointmentModalVisible] = useState(false)

    const toggleAppointmentModal = () => {
        onPress()
        setAppointmentModalVisible(!appointmentModalVisible);
    }

    const togglePlanOpen = (planText: string) => {
        setPlansState({
            ...plansState,
            [planText]: (plansState && plansState[planText]) ? !plansState[planText] : true,
        })
    }

    useEffect(() => {
        if (!isExpanded) { return }
        const placeId = item.meta?.google?.placeId
        if (!placeId) {
            console.log(`No placeId to fetch here`)
            return
        }
        if (item != inputItem) {
            console.log(`Expanded changed and items are already diff`)
            return
        }

        backend.searchItemDetails(placeId)
        .then((gItem: any) => {
            const allNewGoogleData = merge(gItem, item);
            setItem({
                ...item,
                ...allNewGoogleData,
                imageUrl: item.imageUrl,
                matchPercent: item.matchPercent,
            })
        })
        .catch(ex => {
            console.error(`Error retrieving details `, ex)
        })

    }, [isExpanded])

    const isClaimed = (item && item.meta) ? item.meta.status == 'claimed': true
    const isCompactScreen = screenSize <= ScreenSize.tablet
    const isRegular = item.type == ListingType.regular
    const showImage =  (!isCompactScreen || isExpanded) && (item.imageUrl && item.imageUrl.length > 0)
    const showMatch = item.matchPercent
    const showContact = isExpanded
    const showReviews = isExpanded && item.reviews && (item.reviews.count > 0 || item.reviews.rating > 0)
    const showMap = isExpanded
    const showDetails = isExpanded

    let plans: PlanFee[] | undefined = [
        ...((item.pricing.adultInOfficePlan) ? [item.pricing.adultInOfficePlan] : []),
        ...((item.pricing.childInOfficePlan) ? [item.pricing.childInOfficePlan] : []),
        ...((item.pricing.perioInOfficePlan) ? [item.pricing.perioInOfficePlan] : []),
    ]

    interface PlanFeePlusText extends PlanFee {
        text: string;
    }
    const formattedAlternatePlans = item.pricing.plans?.map(plan => { 
        const annually = (plan.annually) ? `${plan.annually} ` : ''
        const monthly = (plan.monthly) ? `${plan.monthly} ` : ''
        const deposit = (plan.deposit) ? `${plan.deposit}` : ''  

        let planString = `${plan.name} ${annually}${monthly}${deposit}`

        return Object.assign({}, plan, { text: planString })
    });
    const formattedPlans = plans.reduce((all: PlanFeePlusText[], currentPlan: PlanFee) => {
        let returnStr = `${currentPlan.name}: `
        const hasAnnual = currentPlan.annually && Number(currentPlan.annually) > 0 && Number(currentPlan.annually) != NaN
        const hasMonthly = currentPlan.monthly && Number(currentPlan.monthly) > 0 && Number(currentPlan.monthly) != NaN
        const hasDeposit = currentPlan.deposit && Number(currentPlan.deposit) > 0 && Number(currentPlan.deposit) != NaN

        const monthlyText = hasMonthly ? `$${currentPlan.monthly}/mo${hasDeposit ? ` + $${currentPlan.deposit}` : ''}` : ''

        /// If annually
        if (hasAnnual && hasMonthly) {
            return all.concat([{ ...currentPlan, text: returnStr += `$${currentPlan.annually}/year or ${monthlyText}` }]);
        }
        else if (hasAnnual) {
            return all.concat([{ ...currentPlan, text: returnStr += `$${currentPlan.annually}/year` }]);
        }
        else if (hasMonthly) {
            return all.concat([{ ...currentPlan, text: returnStr += monthlyText }]);
        }
        else {
            // skip N/A records
            return all
        }
        // return `${currentPlan.name}: $${currentPlan.annually}/year or ${currentPlan.monthly}/mo + $${currentPlan.deposit} deposit`
    }, [])
        .concat(formattedAlternatePlans || [])
        .slice(0, 3)

    /// Savings available should be shown if the listing has any plans at all
    const showSavingsAvailable = formattedPlans.length > 0 || item.pricing.inOfficePlan

    const showPlans = isExpanded && formattedPlans.length > 0
    const addressString = `${item.contact.address.line1}+${item.contact.address.postalCode}`.replace(/ /g, '+')
    const mapsURL = `https://maps.googleapis.com/maps/api/staticmap?center=${addressString}&style=feature:poi|visibility:off&zoom=17&size=600x300&maptype=roadmap&scale=2&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${addressString}&key=${process.env.EXPO_GOOGLE_MAPS_API_KEY}`
    const IopLink = (planName: string | undefined, link: string | undefined) => (planName && link) ? (
        <View key={`${planName}`} style={{ flex: 1, marginLeft: 40 }}>
            <LinkItem eventLabel="Business Listing - Iop" color={Colors.primary} text={link.replace(/https?:\/\//, '')} action={() => openLink(link)} />
        </View>
    ) : [];

    const onClaimPress = async () => {
        if (!onClaimAction) { return }
        try {
            await onClaimAction()
        }
        catch (ex) {
            console.error(`Error claiming: ${ex}`)
        }
    }
    
    return (
        <>
            <TouchableHighlight onPress={onPress} underlayColor={'transparent'}>
                <View style={[styles.container, styles.column, isExpanded && styles.containerExpanded]}>
                    <View style={(isCompactScreen) ? styles.column : styles.row}>

                    {isCompactScreen && <ExpandCaret isExpanded={isExpanded} style={{position: 'absolute', top: 0, right: -16 }} />}

                        
                        {/* Left/Top Area */}
                        <View style={[{ marginRight: 14 }, (isExpanded && !isCompactScreen) ? { marginBottom: 80 } : {}]}>
                            { isCompactScreen && <Title style={{flex: 1}} name={item.name} owner={item.owner} /> }


                            <View style={[{flexDirection: 'row'}, isCompactScreen && {flexWrap: 'wrap', justifyContent: 'space-between'}]}>
                                {/* Image */ }
                                {
                                    showImage 
                                    && item.imageUrl 
                                        && <Image 
                                                source={{uri: item.imageUrl}}  
                                                style={[
                                                    isCompactScreen 
                                                    ? imageStyles.compact 
                                                    : imageStyles.big, 
                                                    !isCompactScreen 
                                                    && {marginRight: (!showMatch) ? 12 : 0}
                                                ]} />
                                }
                                {
                                    !showImage 
                                    && isRegular 
                                        && !isCompactScreen 
                                            && <RegularText>Regular Listing</RegularText>
                                }

                                {/* Match Text */}
                                { showMatch && <MatchText matchPercent={item.matchPercent} isCompact={isCompactScreen} /> }
                                <View style={{flexDirection: 'column'}}>


                                { isCompactScreen && 
                                    <Offering style={{position: null, left: null}} available={showSavingsAvailable} title='Savings Available' /> 
                                  
                                }
                                {/* Hiding for now  { isCompactScreen && <Offering available={item.pricing.inOfficePlan} title='In-Office Plan' /> } */}
                                </View>


                                {!showImage && isRegular && isCompactScreen && <RegularText style={{width: '100%'}}>Regular Listing</RegularText>}
                            </View>

                            { /* Rating */ }
                            <Ratings count={item.reviews?.count ?? 0} rating={item.reviews?.rating || 0} style={[styles.reviewsContainer, !showReviews && styles.none]} />
                        
                            { /* Contact */ }
                            <ContactInfo 
                                info={item.contact} 
                                style={[styles.contactContainer, !showContact && styles.none]} 
                                openLink={openLink}
                            />
                        

                            { /* If has admin then put that here */ }
                            { (onEditAction && isExpanded) && <RoundyButton eventLabel="Business Listing - Edit Listing" style={styles.editButton} onPress={onEditAction}>Edit Listing</RoundyButton>}

                            { /* Auxillary action (if applicable) */}
                            { 
                                (requestAppointment && isExpanded) && 
                                    <RoundyButton eventLabel="Business Listing - Request Appointment" style={styles.editButton} onPress={toggleAppointmentModal}>Request Appointment</RoundyButton> 
                            }
                            { /* more ways to save and local dental fees */}
                            { isExpanded &&
                                <View>
                                    <RoundyButton eventLabel="Business Listing - More Ways To Save"  style={[styles.editButton, { backgroundColor: 'green' }]} onPress={() => setWayToSave && setWayToSave(true)}>More Ways to Save</RoundyButton> 
                                    <RoundyButton eventLabel="Business Listing - Local Dental Fees" style={[styles.editButton, { backgroundColor: 'gray' }]} onPress={() => openLink('https://www.fairhealthconsumer.org/dental/zip')}>Local Dental Fees</RoundyButton> 
                                </View>
                            }
                        </View>
                        
                        {/* Right/Bottom Area */ }
                        <View style={{flex: 1}}>
                        { !isCompactScreen && 
                          <View style={{ flexDirection: 'row' }}>
                                <Title style={{flex: 1, paddingRight: 12}} name={item.name} owner={item.owner}/>
                                
                                <Offering style={{marginRight: 10, position: null, left: null}} available={showSavingsAvailable} title="Savings Available" />
                                <ExpandCaret isExpanded={isExpanded} style={{ position: 'absolute', left: '99%', width: 10 }} /> 
                          </View>
                        }
                          
                          { showDetails && <View>
                            
                                <RegularText fontSize={16} style={{maxWidth: '75%', marginBottom: 10}}>
                                    {item.details}
                                </RegularText>
                            
                          </View>
                            }
                            <View style={styles.rightContainer}>
                                { showPlans &&
                                <View style={{ maxWidth: '100%' }}>
                                  {
                                      formattedPlans.map((plan, index) => (
                                          <TouchableHighlight style={{marginBottom: 10}} underlayColor='transparent' key={index} onPress={() => togglePlanOpen(plan.name)}>
                                              <View>
                                              { plan && plan.text &&
                                                <RegularText fontSize={16}>
                                                    <ExpandCaret isExpanded={plansState[plan.name]} style={{marginRight: 3}}/>
                                                    <FontAwesome5 name='dollar-sign' color={Colors.green} size={20} style={{marginRight: 8}} />
                                                    {plan.text}
                                                </RegularText>
                                              }
                                              { plansState && plansState[plan.name] && (plan.savingsDescription || plan.iopDescription) &&
                                                  <View style={planDescStyles.margin}>
                                                      {(plan.savingsDescription) ? 
                                                        <>
                                                          <MediumText>Savings Description</MediumText> 
                                                          <RegularText style={planDescStyles.margin}>{plan.savingsDescription}</RegularText>
                                                        </>
                                                        : null }
                                                      {(plan.iopDescription) ? 
                                                        <>
                                                          <MediumText>Plan Description</MediumText> 
                                                          <RegularText style={planDescStyles.margin}>{plan.iopDescription}</RegularText>
                                                        </>
                                                        : null }
                                                      {/* HIDING FOR NOW BASED OFF SLACK REQUEST
                                                      {(plan.name && plan.iopLink) ? <MediumText>Plan Link</MediumText> : null }
                                                      { IopLink(plan?.name, plan?.iopLink) } */}
                                                  </View>
                                              }
                                              </View>
                                          </TouchableHighlight>
                                      ))
                                  }
                                  </View>
                                }
                                
                                {/*<View style={{flexDirection: 'row', display: 'none'}}>
                                 { !isCompactScreen && <Offering available={item.pricing.inOfficePlan} title="In-Office Plan" /> } }
                                </View>
                                */}
                            </View>
                            { (showPlans || !isExpanded) &&
                              <View style={{ flexDirection: 'row' }}>
                                { !showPlans &&
                                  (item.pricing.inOfficePlan === true && isExpanded) && (
                                    <View style={{paddingTop: 32}}>
                                      <RegularText fontSize={16}>
                                          <FontAwesome5 name='dollar-sign' color={Colors.green} size={20} style={{marginRight: 8}} />
                                          Savings available, please call office for more details.
                                      </RegularText>
                                    </View>
                                  )
                                }
                              </View>
                            }

                            { showMap && (
                                <>
                                <View style={{minHeight: 180, flex: 1, backgroundColor: Colors.offwhite, marginTop: 32, marginBottom: 24 }}>
                                    {/* <Text>TODO: Google Maps API</Text> */}
                                    <AsyncImage source={{uri: mapsURL}} style={{flex: 1}} />
                                </View>
                                </>
                            )}
                            { showDetails && (
                              <View>
                                  <Text style={{ fontSize: 12 }}>* In-office plans are between the dental office and patient, and WellifyMe does not control services offered nor pricing for available services. Services, prices, and savings may vary upon the treatment needed and the type of plan recommended by the dental office. WellifyMe subscribers should speak directly with the dental office offering these plans for specific details on services, prices, and savings. Savings will vary based on the individual dental office, location, and plan selected. Savings are discounts off a provider's usual and customary fee offered to WellifyMe subscribers. Please check with your dentist and dental office team for cost estimates before beginning any treatment. In-office plans and dental discount plans are NOT insurance, and are not qualified health plans under the Affordable Care Act.</Text>
                              </View>
                            )}
                        </View>
                    
                        { !isClaimed && onClaimAction && <RoundyButton
                            eventLabel="Business Listing - Claim Listing"
                            style={{position: 'absolute', top: 12, right: 50, maxWidth: 200, height: 50}}
                            onPress={onClaimPress}>Claim Listing</RoundyButton>}
                    </View>
                </View>
            </TouchableHighlight>
            { appointmentModalVisible &&
                <RequestAppointment
                    setVisible={ setAppointmentModalVisible }
                    isExpanded={ isExpanded }
                    isCompactScreen={ isCompactScreen }
                    onPress={ onPress }
                    item={ item }
                />
            }
        </>
    )
}



const AsyncImage = (props: ImageProps) => {
    const [loaded, setLoaded] = useState(false)
    const transition = useRef(new Animated.Value(1)).current


    const startLoading = () => {
        // setLoading(true)
    }

    const endLoading = () => {
        Animated.timing(
            transition,
            {
                duration: 100,
                toValue: 0,
                useNativeDriver: true
            }
        ).start(() => {
            setLoaded(true)
        })
    }

    const showLoading = !loaded
    return (
        <>
            { useMemo(() => {
                return <Image {...props} onLoadStart={startLoading} onLoadEnd={endLoading} />
            }, [])}
            { showLoading && <Animated.View style={[{ opacity: transition }, asyncImageStyles.loading]} /> }
        </>
    ) 
}

export default BusinessListing