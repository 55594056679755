
import Config from '../util/config'

const start = () => {
  // (function (i, s, o, g, r, a, m) {
  //   // @ts-ignore
  //   i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
  //     // @ts-ignore
  //     (i[r].q = i[r].q || []).push(arguments)
  //     // @ts-ignore
  //   }, i[r].l = 1 * new Date(); a = s.createElement(o),
  //     // @ts-ignore
  //     m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
  //   // @ts-ignore
  // })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
  // console.log(Config)
  // ga('create', Config.googleAnalyticsMeasurementId, 'auto');
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  const gtag: Gtag.Gtag = function(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', Config.googleAnalyticsMeasurementId);
  return gtag
}


const events = () => {
  const gtag = start()
  const sendSearchQueryEvent = (query: string) => gtag('event', 'query', {
    event_category: 'search',
    event_label: query,
  })
  const sendSearchPriorityEvent = (priority: string) => gtag('event', 'priority', {
    event_category: 'search',
    event_label: priority,
  })
  const sendButtonClicked = (button: string, category: 'button' | 'link' = 'button') => gtag('event', 'clicked', {
    event_category: category,
    event_label: button,
  })

  return {
    sendSearchQueryEvent,
    sendSearchPriorityEvent,
    sendButtonClicked,
  }
}


export default events()