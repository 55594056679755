import { FontAwesome5 } from "@expo/vector-icons"
import { MailDataRequired } from "@sendgrid/mail"
import { preventAutoHide } from "expo-splash-screen"
import moment from "moment"
import React, { useState } from "react"
import { useMemo } from "react"
import { StyleSheet, Text, TouchableHighlight, View } from "react-native"
import backend from "../backend/backend"
import Colors from "../constants/Colors"
import { BusinessItem } from "../constants/Models"
import call from "../helpers/Call"
import { LinkItem } from "./BusinessListingSub"
import CalendarPicker from "./CalendarPicker"
import CheckBox from "./CheckBox"
import { NewAptEmailTemplate, NewAptPlainTextTemplate } from "./EmailTemplate"
import FormInput from "./FormInput"
import RoundyButton from "./RoundyButton"
import { MediumText, RegularText } from "./StyledText"

const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        maxWidth: '100%',
        borderWidth: 1, 
        borderColor: Colors.border, 
        backgroundColor: Colors.white,
        padding: 16,
        paddingLeft: 32,
        paddingRight: 24,
        paddingTop: 32,
        borderRadius: 8,
        marginBottom: 20
    },
    column: { flexDirection: 'column' },
    containerExpanded: {
        shadowColor: '#050505',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 4 }
    },
    row: { flexDirection: 'row' },
    requestAppInputContainer: {
        maxWidth: 409,
        marginTop: 15
    },
})

type RequestAppointmentProps = {
    setVisible: Function;
    isExpanded: boolean;
    isCompactScreen: boolean;
    onPress: Function;
    item: BusinessItem;
}

export interface AppointmentRequest {
    date1: string;
    date2: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    preferredTime: string;
}

export default ({ 
    setVisible,
    isExpanded, 
    onPress: toggleExpansion, 
    item 
}: RequestAppointmentProps)  => {
    const [date1ModalVisible, setDate1ModalVisible] = useState(false)
    const [date2ModalVisible, setDate2ModalVisible] = useState(false)
    const [didTrySubmitApp, setDidTrySubmitApp] = useState(false);
    const [requestAppSubmitted, setRequestAppSubmitted] = useState(false);

    const businessHasEmail = item.contact && item.contact.email;

    const [requestedAppointment, setRequestedAppointment] = useState<AppointmentRequest>({
        date1: '',
        date2: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        preferredTime: '',
    })


    const msg: MailDataRequired =  useMemo(() => {
        return {
            to: item.contact.email ?? '',
            from: 'appointments@mydentistmatch.com', 
            subject: 'New Appointment Request!',
            text: NewAptPlainTextTemplate(requestedAppointment),
            html: NewAptEmailTemplate(requestedAppointment),
          };
    }, [requestedAppointment])

    return (
        <View style={[
                styles.container, 
                styles.column, 
                isExpanded && styles.containerExpanded, 
        ]}>
            <View>
                <View style={{
                    height: 'auto', 
                    width: '100%', 
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                }}>
                    <View style={{flex: 1,  flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <View style={{ alignItems: 'center', width: '98%' }}>
                            <MediumText fontSize={20} style={{ fontWeight: 'bold' }}>Request Appointment</MediumText>
                        </View>
                        <View style={{ width: '5%' }}>
                            <TouchableHighlight onPress={() => { toggleExpansion(); setVisible(false); }} underlayColor={'transparent'}>
                                <FontAwesome5 name='times' color='gray' size={20} style={{marginRight: 8}} />
                            </TouchableHighlight>
                        </View>
                    </View>
                    { 
                            !requestAppSubmitted 
                        ? 
                         <View style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        {
                            !!item.contact.email
                            ? 
                                <>
                                    <Text style={{ marginTop: 10, flexWrap: 'wrap' }}>Pick 2 dates that would work for an appointment.</Text>
                                    <View style={{ 
                                        display: 'flex', 
                                        flexDirection: 'row', 
                                        justifyContent: 'center', 
                                        flexWrap: 'wrap',
                                        marginTop: 15
                                    }}>
                                        <CalendarPicker 
                                            style={{ marginRight: 21, marginTop: 10 }}
                                            currentDate={ requestedAppointment.date1 }
                                            modalVisible={ date1ModalVisible }
                                            setModalVisible={ setDate1ModalVisible }
                                            label="Date Option 1" 
                                            onChange={(date: any) => setRequestedAppointment(Object.assign({}, requestedAppointment, { date1: moment(date).format('YYYY-MM-DD') }))}
                                        />
                                        <CalendarPicker 
                                            style={{ marginRight: 21, marginTop: 10 }}
                                            currentDate={ requestedAppointment.date2 }
                                            modalVisible={ date2ModalVisible }
                                            setModalVisible={ setDate2ModalVisible }
                                            label="Date Option 2" 
                                            onChange={(date: any) => setRequestedAppointment(Object.assign({}, requestedAppointment, { date2: moment(date).format('YYYY-MM-DD') }))}
                                        />
                                    </View>
                                    <View style={{ margin: 20}}/>
                                    <View style={{ display: 'flex', alignItems: 'center' }}>
                                    <FormInput 
                                        value={ requestedAppointment.firstName }
                                        style={styles.requestAppInputContainer}
                                        onChange={ (firstName: string) => setRequestedAppointment(Object.assign({}, requestedAppointment, { firstName })) }
                                        error={ didTrySubmitApp && !requestedAppointment.firstName }
                                        label="First Name (required)" 
                                        placeholder="First Name"
                                    />
                                    <FormInput 
                                        value={requestedAppointment.lastName} 
                                        style={styles.requestAppInputContainer}
                                        label="Last Name (required)" 
                                        placeholder="Last Name" 
                                        onChange={(lastName) => setRequestedAppointment(Object.assign({}, requestedAppointment, { lastName }))}
                                        
                                        error={ didTrySubmitApp && !requestedAppointment.lastName }
                                    />
                                    <FormInput 
                                        value={requestedAppointment.email} 
                                        style={styles.requestAppInputContainer}
                                        label="Email (required)" 
                                        placeholder="email@website.com" 
                                        onChange={(email) => setRequestedAppointment(Object.assign({}, requestedAppointment, { email }))}
                                        error={ didTrySubmitApp && !requestedAppointment.lastName }
                                    />
                                    <FormInput 
                                        value={requestedAppointment.phone} 
                                        style={styles.requestAppInputContainer}
                                        label='Phone'
                                        placeholder='(###) ###-####'
                                        onChange={( phone: string ) => setRequestedAppointment(Object.assign({}, requestedAppointment, { phone }))}
                                    />
                                    <RegularText style={styles.requestAppInputContainer} fontSize={18}>Preferred Time</RegularText>
                                    <View style={{ 
                                        marginTop: 10, 
                                        flexDirection: 'row', 
                                        justifyContent: 'space-evenly', 
                                        alignItems: 'center', 
                                        maxWidth: 409, 
                                        marginBottom: 15
                                    }}>
                                        <CheckBox  
                                            iconType={{
                                                false: 'circle',
                                                true: 'check-circle',
                                            }}
                                            color='black'
                                            value={requestedAppointment.preferredTime === 'AM'} 
                                            onValueChange={() => setRequestedAppointment(Object.assign({}, requestedAppointment, { preferredTime: 'AM' }))}
                                            style={{ marginBottom: 5, marginRight: 5  }}
                                            size={20}
                                        />
                                        <Text style={{ marginRight: 25, fontSize: 16}}>AM</Text>
                                        <CheckBox  
                                            iconType={{
                                                false: 'circle',
                                                true: 'check-circle',
                                            }}
                                            color='black'
                                            value={requestedAppointment.preferredTime === 'PM'} 
                                            onValueChange={() => setRequestedAppointment(Object.assign({}, requestedAppointment, { preferredTime: 'PM' }))}
                                            style={{ marginBottom: 3, marginRight: 5 }}
                                            size={20}
                                        />
                                        <Text style={{ marginRight: 25}}>PM</Text>
                                        <CheckBox  
                                            iconType={{
                                                false: 'circle',
                                                true: 'check-circle',
                                            }}
                                            color='black'
                                            value={requestedAppointment.preferredTime === 'Anytime'} 
                                            onValueChange={() => setRequestedAppointment(Object.assign({}, requestedAppointment, { preferredTime: 'Anytime' }))}
                                            style={{ marginBottom: 3, marginRight: 5 }}
                                            size={20}
                                        />
                                        <Text style={{ marginRight: 25}}>Anytime</Text>
                                        <CheckBox  
                                            iconType={{
                                                false: 'circle',
                                                true: 'check-circle',
                                            }}
                                            color='black'
                                            value={requestedAppointment.preferredTime === 'ASAP'} 
                                            onValueChange={() => setRequestedAppointment(Object.assign({}, requestedAppointment, { preferredTime: 'ASAP' }))}
                                            style={{ marginBottom: 3, marginRight: 5 }}
                                            size={20}
                                        />
                                        <Text style={{ marginRight: 25}}>ASAP</Text>
                                    </View>
                                </View> 
                            </>
                            :
                                item.contact.phone !== null && item.contact.phone !== undefined  &&
                                <>
                                    <RegularText style={{marginVertical: 10}}>This office doesn't accept online requests at this time, please call them directly:</RegularText>
                                    <LinkItem eventLabel="Request Appointment - Phone" icon='phone' text={item.contact.phone.toString()} action={() => call(item.contact.phone?.toString() ?? "")} /> 
                                </>
                        }
                        </View>
                         :
                            <View style={{ display: 'flex', alignItems: 'center'}}>
                                <RegularText 
                                    fontSize={17}
                                    style={{
                                        maxWidth: 408,
                                        textAlign: 'center',
                                        marginTop: 60,
                                        marginBottom: 300,
                                    }}
                                >{  businessHasEmail ? 
                                    'Thank you for submitting your appointment request. If the office does not contact you within 24-48 hrs, please contact them directly' 
                                    : 
                                    "This office doesn't accept online requests at this time, please call them directly."
                                }</RegularText>
                            </View>
                            
                           
                  
                    }
                    {
                        !!item.contact?.email 
                        &&
                    <View style={{ display: 'flex', alignItems: 'center'}}>
                        <RoundyButton
                            eventLabel="Request Appointment - Submit Request"
                            style={{ maxWidth: 261, height: 50, marginBottom: 20 }} 
                            onPress={async () => {
                                setDidTrySubmitApp(true);
                                if (!requestAppSubmitted && requestedAppointment.firstName && requestedAppointment.lastName && requestedAppointment.email) {
                                    await backend.requestAppointment(msg);
                                    setRequestAppSubmitted(true);
                                } else if (requestAppSubmitted) {
                                    setVisible(false);
                                    toggleExpansion();
                                }
                            }}

                        >{ (requestAppSubmitted) ? 'Done' : 'Submit Request' }</RoundyButton>
                        <Text style={{ maxWidth: 409, color: 'gray', marginBottom: 20}}>**IF THIS IS AN EMERGENCY CONTACT THE OFFICE DIRECTLY. IF YOU ARE NOT CONTACTED BY THE OFFICE TO CONFIRM WITHIN 2 BUSINESS DAYS, PLEASE CALL THEM DIRECTLY.</Text>        
                    </View>
                    }
                </View>
            </View>
        </View>
    )
}