import React, { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import AdminAuth from './Admin/AdminAuth'
import AdminTabNavigator from './Admin/AdminTabNavigator'



export default () => {
    const { user } = useContext(AuthContext)

    const userIsAdmin = user && user.roles && user.roles.includes('admin')
    return userIsAdmin ? <AdminTabNavigator /> : <AdminAuth />
}

