export enum HTTPMethod {
    head = "HEAD",
    get = "GET",
    put = "PUT",
    patch = "PATCH",
    post = "POST",
    delete = "DELETE"
}

export interface Headers { [s: string]: string; }

export class APIError {
    code: number
    message: string
    details?: object | null

    constructor(code: number, message: string, details?: object | null) {
        this.code = code
        this.message = message
        this.details = details
    }
}

export class Request<T> {
    method: HTTPMethod
    path: string

    constructor(method: HTTPMethod, path: string) {
        this.method = method
        this.path = path
    }
}

export class Response<T> {
    data?: T
    error?: Error

    constructor(data?: T, error?: Error) {
        this.data = data
        this.error = error
    }
}

export interface Service {

    // Represents the baseUrl for the service
    baseUrl: string
    
    /// Any specific headers for this service
    headers?: () => Promise<Headers>
}

export class Requestable<T> {
    service: Service
    request: Request<T>
    headers?: Headers
    body?: any
    query?: { [key: string]: string }
    mode?: RequestMode

    constructor(service: Service, request: Request<T>) {
        this.service = service
        this.request = request
    }
    
    withHeaders(headers: Headers): Requestable<T> {
        this.headers = {
            ...this.headers,
            ...headers
        }
        return this
    }

    withBody(body: any): Requestable<T> {
        this.body = body
        return this
    }

    withoutCORS(): Requestable<T> {
        this.mode = 'no-cors'
        return this
    }

    withQuery(params: object): Requestable<T> {
        this.query = {
            ...this.query,
            ...params
        }
        return this
    }

    async call(): Promise<T> {

        let globalHeaders: { [key: string]: string }  = {}

        const serviceHeaders = (this.service.headers) ? await this.service.headers(): null;
        if (serviceHeaders) {
            globalHeaders = {
                ...globalHeaders,
                ...serviceHeaders,
            }
        }

        const contentType = (serviceHeaders && serviceHeaders['Content-Type']) ? serviceHeaders['Content-Type'] : 'application/json';
        if (this.body) {
            globalHeaders['Content-Type'] = contentType
        }

        let url = new URL(`${this.service.baseUrl}/${this.request.path}`)
        
        if (this.query) {
            const query: {[key: string]: string} = this.query
            Object.keys(this.query)
                .forEach(key => {
                    if (query[key]) {
                        url.searchParams.append(key, query[key])
                    }
                })
        }
        
        const response = await fetch(url.href, {
            mode: this.mode,
            method: this.request.method,
            headers: {
                ...globalHeaders,
                ...this.headers
            },
            body: this.body && JSON.stringify(this.body),
        })
        
        if (response.status >= 400) {
            const returnError = new APIError(response.status, response.statusText)
            returnError.details = await response.json().catch(ex => null)

            throw returnError
        }
        else if (response.status == 204) {
            console.log('NO RETURN BODY')
            return {} as T;
        }
        
        try {
            const json = await response.json()
            return json
        }
        catch (ex) {
            console.log(`** Error parsing response as json.  Falling back to text`)
            console.error(ex)
            throw ex
        }
    }

    async rawCall(): Promise<globalThis.Response> {

        let globalHeaders: { [key: string]: string }  = {}

        if (this.service.headers) {
            globalHeaders = {
                ...globalHeaders,
                ...(await this.service.headers())
            }
        }

        if (this.body) {
            globalHeaders['Content-Type'] = 'application/json'
        }

        let url = new URL(`${this.service.baseUrl}/${this.request.path}`)
        
        if (this.query) {
            Object.keys(this.query).forEach(key => {
                if (this.query && this?.query[key]){
                    url.searchParams.append(key, this.query[key])
                }
            })
        }
        
        const response = await fetch(url.href, {
            method: this.request.method,
            headers: {
                ...globalHeaders,
                ...this.headers
            },
            body: this.body && JSON.stringify(this.body),
        })
        
        if (response.status >= 400) {
            const returnError = new APIError(response.status, response.statusText)
            returnError.details = await response.json().catch(ex => null)

            throw returnError
        }
        
        return response
    }
}

export function request<T>(service: Service, request: Request<T>): Requestable<T> {
    return new Requestable<T>(service, request)
}