import React from "react"
import { ActivityIndicator, StyleSheet, ViewProps, View, Animated } from "react-native"
import { MediumText } from "./StyledText"






interface LoadingProps extends Animated.AnimatedProps<ViewProps> {
    text?: string
    fontSize?: number;
}

const styles = StyleSheet.create({
    container: { 
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export default ({ text, fontSize, style, ...props}: LoadingProps) => {
    return (
        <Animated.View style={[styles.container, style]} {...props}>
            <ActivityIndicator animating={true} size={'large'} />
            <MediumText fontSize={fontSize || 24}>{text || 'Loading...'}</MediumText>
        </Animated.View>
    )
}