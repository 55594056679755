import * as React from 'react';
import { MediumText } from '../StyledText';
import { styles } from "./styles";

interface SubHeaderProps {
    headerText: string;
}

export const SubHeader = ({ headerText }: SubHeaderProps) => (
  <MediumText fontSize={ 16 } style={[styles.headerText, { marginTop: 12 }]}>
    { headerText }
  </MediumText>
);
