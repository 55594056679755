import React, { PropsWithChildren } from 'react'
import { IBMPlexSans_500Medium, useFonts } from "@expo-google-fonts/ibm-plex-sans"
import { Link, NavigationAction } from "@react-navigation/native"
import { Button, StyleSheet, ViewProps, Text, View, TextProps, TouchableHighlight, TextStyle } from "react-native"
import Colors from '../constants/Colors'
import events from '../helpers/GoogleAnalytics'

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        backgroundColor: Colors.primary,
        padding: 24,
        borderRadius: 9999999,
        maxWidth: 425,
        maxHeight: 64,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },

    disabled: {
        backgroundColor: Colors.inputBorder
    },
    main: {
        color: Colors.white,
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'IBMPlexSans_500Medium'
    }
})

export interface RoundyButtonProps extends PropsWithChildren<TextProps> {
    onPress: () => void;
    textStyle?: TextStyle;
    disabled?: boolean
    eventLabel: string
}

export default ({ onPress, disabled, style, textStyle, eventLabel, ...props}: RoundyButtonProps) => {
    const [loaded] = useFonts({ IBMPlexSans_500Medium })
    if (!loaded) { return null }
    const onPressWithEvent = () => {
        events.sendButtonClicked(eventLabel)
        onPress()
    }
    return (
        <TouchableHighlight onPress={disabled ? () => {} : onPressWithEvent}  style={[styles.container, style, (disabled ? styles.disabled : {})]}>
            <Text {...props} style={[styles.main, textStyle]} />
        </TouchableHighlight>
    )
}

export interface RoundyButtonLinkProps extends React.PropsWithChildren<TextProps> {
    to: string;
    action?: NavigationAction
}
export const RoundyButtonLink = ({ to, action, ...props }: RoundyButtonLinkProps) => {
    const [loaded] = useFonts({ IBMPlexSans_500Medium })
    if (!loaded) { return null }

    return (
        <Link to={to} action={action} style={styles.container}>
            <Text {...props} style={[props.style, styles.main]} />
        </Link>
    )
}