import React, {useContext} from 'react';
import { Image, View, Text, TouchableOpacity } from 'react-native';
import  { TitleHeader, SubHeader, QuestionAnswer, styles }  from '../components/FAQ';
import { MediumText, RegularText } from '../components/StyledText';
import notSeeingTheMatchYouWant from '../assets/images/not_seeing_the_match_you_want.png';
import HelperPageLayout from '../components/HelperPageLayout';
import { MediaQueryContext, ScreenSize } from '../contexts/MediaQueryContext';
import events from '../helpers/GoogleAnalytics'

export default function FAQ() {
  const { screenSize } = useContext(MediaQueryContext)
  const isCompact = screenSize < ScreenSize.large
  const imageHeight = isCompact ? 300 : 500;
  return (
    <HelperPageLayout header="MDM FAQs">
        <View>
          <QuestionAnswer
            headerNumber="1."
            question="Can I use my benefits at my current dentist?"
            answer="Yes. Many people have great relationships with their current dentist and 
            the great news is if you are one of these people, you can use your benefits at 
            your current dentist! If your current dentist isn’t offering an in-office plan and 
            you’d like for them to do so, you can refer them to us by following the 
            instructions listed below (#2)."
          />
          <QuestionAnswer
            headerNumber="2."
            question="I don’t see my dentist listed on MyDentist Match. What can I do?"
            answer="You can always refer a dentist to us by clicking the “Refer A Dentist” 
            button. From there we can be sure we update their listing with current 
            information and if they offer an in-office plan."
          />
          <Image style={{ width: '100%', height: imageHeight }} source={ notSeeingTheMatchYouWant } />
          <QuestionAnswer
            headerNumber="3."
            question="My current dentist doesn’t offer an in-office plan. What can I do?"
            answer="In-office plans benefit patients AND dentists. You can refer your dentist to 
            us to learn more about in-office plans and how they can benefit you and 
            them. Click here to include your dentist: "
          />

          <TitleHeader headerNumber="4." headerText="What is the difference between an in-office plan (IOP), dental  discount plan (DDP) and dental insurance?" />
          <RegularText fontSize={ 16 } style={[styles.bodyText, { marginTop: 10 }]}>
            Let's break it down: 
          </RegularText>
          <View style={{ marginLeft: 24 }}>
            <SubHeader headerText="1.   In-office plans (IOP)" />
            <RegularText fontSize={ 16 } style={styles.bodyText}>
              An IOP is an innovative approach to paying for dental treatment that 
              saves both the patient and dentists money by eliminating costly 3rd
              parties and insurance companies. IOPs are usually offered directly 
              by a dental office to you, the patient. There is no middleman 
              insurance company or third party which allows the dentist to 
              pass savings directly to you. IOPs allow you to only pay for what you 
              need. Treatment savings are offered, and you can use your dental benefit 
              dollars (HRA/HSA) to pay for treatment. Preventative care is usually 
              included. Compared to traditional insurance and dental discount plans 
              (DDP) where you pay for it whether you use it or not. 
              There are no claim forms, waiting periods, and IOPs eliminate the hassles 
              and confusion that comes with traditional insurance.  
            </RegularText>
            <SubHeader headerText="2.   Dental Discount Plans (DDP)" />
            <RegularText fontSize={ 16 } style={styles.bodyText}>
              A DDP is usually offered by a 3rd party company or insurance company, 
              not a dental office. Treatment savings are offered, and these plans may or
              may not include preventative care. Depending on the dental discount 
              plan, you may be paying to access saving like a Costco or Sam’s Club 
              membership. You can still use your dental benefit dollars (HAS/HRA) to 
              pay for treatment.   
            </RegularText>
            <SubHeader headerText="3.   Traditional dental insurance" />
            <RegularText fontSize={ 16 } style={styles.bodyText}>
              Offered by an insurance company. These are what most people think of 
              when they think of dental benefits. These plans have not changed much in
              over 30 years, and they come with a host of limitations and exclusions like
              waiting periods, downgrades, and high out of pocket costs. What good are
              dental insurance benefits if one, you must wait to use them and two, you 
              cannot access your full benefit dollars? 
              You and/or your employer are paying for dental insurance whether it is 
              needed or not. Claim forms and pre-authorizations can also complicate 
              reimbursements.  
            </RegularText>
          </View>
          <QuestionAnswer
            headerNumber="5."
            question="How do I know what my dentist will charge for services? "
            answer="Before any treatment is performed, including preventative care like 
            cleanings, x-rays, and exams, a dental office should provide a treatment plan 
            with fees listed. Be sure to get a treatment plan with fees clearly listed to 
            know the exact costs of your treatment. "
          />
          <TitleHeader headerNumber="6." headerText="What if I have a dispute regarding my bill or treatment?" />
          <View style={{ marginLeft: 30 }}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <MediumText fontSize={ 18 } style={[styles.headerText, { marginTop: -2 }]}>
                { 'A:  ' }
              </MediumText>
              <RegularText fontSize={ 16 } style={styles.bodyText}> 
                Resolving Disputes
              </RegularText>
            </View>
            <RegularText fontSize={ 16 } style={styles.bodyText}> 
              Millions of people are provided with exceptional dental care  and service at 
              their dentist’s office on a daily basis. As patients we expect proper treatment
              and dentist look to provide what is expected. As with any consumer 
              transaction, you may find yourself in a position that something was not quite
              right and as a result, a dispute occurs. When a dispute does occur, you do 
              have options available to you as a patient to resolve them.
            </RegularText>
            <RegularText fontSize={ 16 } style={styles.bodyText}> 
              1. Address the dispute directly with the dental office as soon
              as possible. Dental offices want to have happy patients and resolving 
              disputes directly is the best-case scenario for both dental offices and 
              &nbsp        patients. Think of it like resolving a dispute between any buyer and seller of 
              goods/services. If you had a problem with a price or service at a local grocery store, 
              you would probably let the manager know about it and find an
              amicable solution. This route of resolution is commonplace in a dental office 
              whether someone has insurance or is paying with cash.
            </RegularText>
            <RegularText fontSize={ 16 } style={styles.bodyText}> 
              { 
                "2. If a dispute cannot first be resolved directly with a dental office, a patient should contact their state dental board. Every state in the United States has a Dental Board that is fully equipped to handle patient complaints and inquiries. State Dental Boards provide licenses to dentists to practice dentistry and take all consumer complaints/inquiries seriously. Resolutions of complaints/inquiries are handled expeditiously.  To find your state dental board, click here. " 
              } 
              <View>
                <TouchableOpacity onPress={ () => {
                  events.sendButtonClicked('Open state Dental Boards link', 'link')                  
                  window.open('https://www.ada.org/en/education-careers/licensure/licensure-dental-students/state-dental-boards', '_blank');
                }}>
                  <Text style={{ color: 'blue', marginTop: 10, marginBottom: 10 }}>https://www.ada.org/en/education-careers/licensure/licensure-dental-students/state-dental-boards</Text>
                </TouchableOpacity>
              </View>
              
              {
                "At the end of the day, you can always leave a review for the dental office to let others know of your experience. Sometimes challenges do arise and how those challenges are handled makes all the difference." 
              }
            </RegularText>
          </View>
          <QuestionAnswer
            headerNumber="7."
            question="Can I book an appointment through MyDentist Match?"
            answer="Through MyDentist Match it is easy to send a dental office your request for
            an appointment time that convenient. When selecting a dentist on MyDentist 
            Match simply click on the “Appointment Request” button and choose the 
            days and time of day that’s best for you. Your request will be sent directly to 
            the dental office. From there you will book directly with your dental office. If 
            you don’t hear from them within 24 hours, call them directly. Dental office 
            loves to schedule appointments to help you!"
          />
          <QuestionAnswer
            headerNumber="8."
            question="How can I leave a review for a dental office?"
            answer="All reviews can be submitted through Google."
          />
          <QuestionAnswer
            headerNumber="9."
            question="What if my card is not working at my dental office?"
            answer="Please contact the number on the back of your card and/or your HR 
            manager."
          />
          <QuestionAnswer
            headerNumber="10."
            question="What if I run out of funds on my benefit card?"
            answer="Please contact your HR manager. You may pay any balances remaining at 
            your dental office according to the office’s policies."
          />
          <QuestionAnswer
            headerNumber="11."
            question="What do I need to take to my dental appointment?"
            answer="Please take your benefit card with you and anything else your dental office
            has asked you to bring. "
          />
        </View>
      </HelperPageLayout>
  );
}