import React, { useState } from "react"
import { StyleSheet, TouchableHighlight, View } from 'react-native'
import ExpandedCaret from "../../components/ExpandedCaret"
import { MediumText, RegularText } from "../../components/StyledText"
import TextInputWrapper from "../../components/TextInputWrapper"
import Colors from "../../constants/Colors"
import { ClaimedBusinessItem, ServiceItem } from "../../constants/Models"




interface AdditionalServiceFeeProps {
    availableService: ServiceItem[];
    item: ClaimedBusinessItem;
    onUpdate: (update: ClaimedBusinessItem) => void;
}

const addServiceStyles = StyleSheet.create({
    container: {
        borderTopColor: Colors.border,
        borderTopWidth: 1,
        borderBottomColor: Colors.border,
        borderBottomWidth: 1,
        paddingVertical: 40,
        marginVertical: 40,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    input: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 18
    }
})
export default (props: AdditionalServiceFeeProps) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const serviceItem = (item: ServiceItem) => {

        const onChange = (val: string) => {
            props.onUpdate({
                ...props.item,
                optionalServices: {
                    ...props.item.optionalServices,
                    [item.key]: val
                }
            })
        }


        const itmVal = props.item.optionalServices && props.item.optionalServices[item.key]
        const itmValString = (itmVal && `${itmVal}`) || ''

        return (
            <TextInputWrapper 
                style={addServiceStyles.input}
                onChangeText={onChange} 
                placeholder={'$00.00'} 
                label={item.label}
                value={itmValString}
                key={item.key} />
        )

    }

    return (
        <>
            <View>
               <TouchableHighlight style={addServiceStyles.container} underlayColor={'transparent'} onPress={() => setIsExpanded(!isExpanded) }>
                <>
                    <View>
                        <MediumText fontSize={24}>Additional Service Fees <RegularText fontSize={24}>(Optional)</RegularText></MediumText>
                        <RegularText fontSize={16} style={{color: Colors.lightGrey}}>
                            These additional fees <MediumText fontSize={16}>will not</MediumText> be displayed on your listing.
                        </RegularText>
                    </View>

                    
                    <ExpandedCaret isExpanded={isExpanded} />
                </>
                </TouchableHighlight>
            </View>

            { isExpanded && props.availableService.map(serviceItem) }
        </>
    )
}

