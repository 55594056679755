import { FontAwesome5 } from "@expo/vector-icons"
import React from "react"
import { View } from "react-native"
import TextInputWrapper from "./TextInputWrapper"

type FormInputProps = {
    value: any;
    onChange: (text: string) => void;
    error?: boolean;
    style?: any;
    label: string;
    placeholder: string;
}

export default ({ 
    value,
    onChange,
    error,
    style,
    label,
    placeholder,
}: FormInputProps)  => {
    return (
        <View>
            <TextInputWrapper 
                value={ value } 
                style={ style }
                label={ label }
                placeholder={ placeholder }
                onChangeText={ onChange }
            />
            { 
                error &&
                <FontAwesome5 
                    name='exclamation-circle' 
                    color='red'
                    size={20} 
                    style={{
                        marginTop: 67, 
                        flexDirection: 'column', 
                        width: 18,
                        position: 'absolute',
                        left: '74%'
                    }} 
                />
            }
        </View>
    )
}