import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import React, { useState } from "react"
import { FlatList, ListRenderItem, ViewStyle } from "react-native"
import { BusinessItem, ClaimedBusinessItem } from "../constants/Models"
import { RootStackParamList } from "../types"
import BusinessListing from "./BusinessListing"



interface SearchResultsListProps {
    data: BusinessItem[];
    style?: ViewStyle;
    allowEdit?: boolean;

    onClaim?: (item: BusinessItem) => Promise<void>
}

const BusinessListingList = ({ data, style, onClaim, allowEdit }: SearchResultsListProps) => {
    const [expandedResults, setExpandedResults] = useState<number[]>([])
    const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

    const onClaimPressed = async (item: BusinessItem) => {
        if (!onClaim) { return }
        return onClaim(item)
    }

    const onEditPressed = (item: ClaimedBusinessItem) => {
        if (!allowEdit) { return }
        
        navigation.navigate('EditListing', { id: item._id as string })
    }

    const renderResult: ListRenderItem<BusinessItem|ClaimedBusinessItem> = ({ item, index }) => {
        const isExpanded = expandedResults.includes(index)
        const onOverallTap = () => {
            if (isExpanded) { 
                const results = [...expandedResults]
                results.splice(results.indexOf(index), 1)

                setExpandedResults(results) 
                
            }
            else { setExpandedResults(expandedResults.concat([index]))}
        }

        let editAction;
        if (allowEdit && item as ClaimedBusinessItem) {
            const realItem = item as ClaimedBusinessItem
            editAction = () => onEditPressed(realItem)
        }

        let claimAction;
        if (onClaim) {
            claimAction = () => onClaimPressed(item)
        }
        return <BusinessListing 
            isExpanded={isExpanded} 
            item={item} 
            onPress={onOverallTap}
            onEditAction={ editAction }
            onClaimAction={ claimAction }
            />
    }

    return (
        <FlatList
            style={style}
            extraData={expandedResults}
            data={data}
            renderItem={renderResult}
            keyExtractor={(d, idx) => (d as ClaimedBusinessItem)._id || `${idx} - ${d.name}`}
        />
    )
}


export default BusinessListingList