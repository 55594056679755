import { FontAwesome5 } from "@expo/vector-icons";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  FlatList,
  Modal,
  TouchableHighlight,
  TouchableHighlightProps,
  View, 
  StyleSheet,
  Alert,
  GestureResponderEvent
} from "react-native";
import backend from "../../backend/backend";
import LoadingComponent from "../../components/LoadingComponent";
import Pagination from "../../components/Pagination";
import RoundyButton from "../../components/RoundyButton";
import { MediumText } from "../../components/StyledText";
import Colors from "../../constants/Colors";
import { User } from "../../constants/Models";
import uriToBlob from "../../util/uriToBlob";
import events from '../../helpers/GoogleAnalytics'


export default () => {
  const createTags = (tagRow: string) => {
    const tags = tagRow.split("|");
    return tags.map((t) => {
      const pair = t.split("::");
      let name = { name: pair[0] };
      let tag = { tag: pair[1] };
      return Object.assign(name, tag);
    });
  };

  const handleImport = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: "text/csv",
      });
      if (result.type == "success") {
        const val = (await uriToBlob(result.uri)) as Blob;
        const txt = await val.text();

        const lines = txt.split(/\r|\n/);
        const headers = lines.shift()?.split(",") || [];
        /// Office Name,Last Name,Website,Phone,Address,City,State,Zip code,IOP,Adult Annual,Adult Monthly,Adult Montly Downpayment
        const data = lines
          .map((row) => {
            const rowData = row.split(",").reduce((data, val, idx) => {
              if (val.length == 0) {
                return data;
              }

              return {
                ...data,
                [headers[idx]]: headers[idx] == "roles" ? val.split("|") : val,
                [headers[idx]]: headers[idx] == "tags" ? createTags(val) : val,
              };
            }, {});

            return rowData;
          })
          .filter((v) => Object.keys(v).length > 0);

        await backend.adminUsersImport(data);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <View>
      <Button onPress={() => handleImport()} title="Import" />
      <DataTableComponent
        columns={[
          { key: "email", title: "Email", width: "30%" },
          { key: "roles", title: "Roles" },
          { key: "tags", title: "Tags" },
          { key: "inactive", title: "Status" },
          { key: "dateCreated", title: "Date"}
        ]}
        loader={backend.adminUsers}
      />
    </View>
  );
};

interface DataTableComponentProps<T> {
  // data: T[]
  columns: { title: string; key: keyof User; width?: string }[];
  loader: (val: { page: number; limit: number; sort?: string }) => Promise<T[]>;
  renderItem?: (val: { item: User; index: number }) => JSX.Element;
}

const DataTableComponent = (props: DataTableComponentProps<User>) => {
  const [paging, setPaging] = useState({ page: 0, limit: 10 });
  const [data, setData] = useState<User[] | null>(null);
  const isFocused = useIsFocused();
  const [sort, setSort] = useState<{ key: keyof User; asc: boolean } | null>(null);
  const navigation = useNavigation()

  useEffect(() => {
    loadData();
  }, [paging.page, sort, isFocused]);

  const loadData = async () => {
    const results = await props.loader({
      ...paging,
      sort: sort ? `${sort.asc ? "" : "-"}${sort.key}` : undefined,
    });

    setData(results);
  };

  const loadPage = (page: number) => {
    setPaging({ ...paging, page });
  };

  const loadNext = () => {
    loadPage(paging.page + 1);
  };
  const loadPrev = () => {
    loadPage(paging.page - 1);
  };

  if (!data) {
    return <LoadingComponent />;
  }

  const SectionHeaders = () => {
    const onSortPressed = (item: keyof User) => {
      if (sort?.key == item) {
        setSort({
          ...sort,
          asc: !sort?.asc,
        });
      } else {
        setSort({ key: item, asc: true });
      }
    };

    return (
      <View
        style={{
          flexDirection: "row",
          borderBottomColor: Colors.border,
          borderBottomWidth: 1,
        }}
      >
        {props.columns.map((h) => (
          <HeaderButtonItem
            style={{ width: h.width, maxWidth: h.width }}
            onPress={() => onSortPressed(h.key)}
            title={h.title}
            isActive={h.key == sort?.key}
            asc={sort?.asc || false}
            key={`${h.key}`}
          />
        ))}
        <MediumText fontSize={18}>Action</MediumText>
      </View>
    );
  };

  const renderItem = ({ item }: { item: User }) => {
    return (
      <View style={{ flexDirection: "row", flex: 1, paddingVertical: 8 }}>
        {props.columns.map((c) => (
          <MediumText
            style={{ flex: 1, width: c.width, maxWidth: c.width, alignSelf: "flex-start" }}
            key={c.key}
          >
            { 
              c.key === "tags" 
              ? item[c.key]
                .map((x: { name: string; tag: string }) => x.tag)
                .join(", ") 
              : c.key == "dateCreated" 
                ? item[c.key] && moment(item[c.key]).format('MMM Do, YYYY')
                : c.key === "inactive" 
                  ? item[c.key] === true ? "inactive" : "active"
                  : item[c.key]
            }
          </MediumText>
        ))}
        <RowControls item={item}/>
      </View>
    );
  };
  const handleDelete = (item: User) => {
    //open modal
    //confirm
    //delete user
    // refetch data
      console.log(item)
  }
  const handleEdit = (item: User) => {
    navigation.navigate('EditUser', { id: item._id })
}

  const RowControls = ({ item }: { item: User }) => {
    const [confirmDeactivate, setConfirmDeactivate] = useState(false);

    const makeUserInactive = async (item: User) => {
      try {
          if (item){
              await backend.updateUser(item._id, { inactive: !item.inactive });
          }

      } catch (ex) {
          Alert.alert('Error', `There was an error saving your changes.  ${(ex as Error).message}`, [{text: 'OK'}])
      }
      setConfirmDeactivate(false);
  }
        return (
            <>
            <TouchableHighlight underlayColor='transparent' style={{paddingHorizontal: 8}} onPress={() => {
              handleDelete(item)
              events.sendButtonClicked('Admin Users - Delete')
            }}>
                <FontAwesome5 name='user-slash' color={Colors.inputBorder} size={22} onPress={() => {
                  setConfirmDeactivate(true)
                }}/>
            </TouchableHighlight>
            <TouchableHighlight underlayColor='transparent' onPress={() => {
              handleEdit(item)
              events.sendButtonClicked('Admin Users - Edit')
            }}>
                <FontAwesome5 name='edit' color={Colors.inputBorder} size={24}/>
            </TouchableHighlight>
            <View
            style={styles.centeredView}>
            <Modal 
              animationType="slide"
              transparent
              visible={confirmDeactivate}
              >
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                    <MediumText fontSize={18}>Are you sure you want to make this user {item.inactive ? "active" : "inactive"}</MediumText>
                    <View style={styles.buttonGroup}>
                      <RoundyButton eventLabel="Admin Users - Cancel" style={[styles.button, {backgroundColor: "gray"}]} onPress={()=>setConfirmDeactivate(false)}>Cancel</RoundyButton>
                      <RoundyButton eventLabel="Admin Users - Confirm" style={styles.button} onPress={()=>makeUserInactive(item)}>Confirm</RoundyButton>
                    </View>
                  </View>
                </View>
              </Modal>

            </View>
            </>
        )
  }

  const hasPrev = paging.page > 0;
  const hasNext = paging.limit == data.length;
  return (
    <View style={{ flex: 1 }}>
      <FlatList
        ListHeaderComponent={SectionHeaders}
        ListFooterComponent={
          <Pagination
            label="Admin Users"
            hasPrev={hasPrev}
            hasMore={hasNext}
            loadNext={loadNext}
            loadPrev={loadPrev}
          />
        }
        data={data}
        renderItem={renderItem}
      />
    </View>
  );
};

interface HeaderButtonItemProps extends TouchableHighlightProps {
  title: string;
  isActive: boolean;
  asc: boolean;
}
const HeaderButtonItem = ({
  isActive,
  title,
  asc,
  style,
  onPress,
  ...props
}: HeaderButtonItemProps) => {
  const onPressWithEvent = (e: GestureResponderEvent) => {
    events.sendButtonClicked(`Header Button - ${title}`)
    if (onPress) {
      onPress(e)
    }
}
  return (
    <TouchableHighlight
      {...props}
      onPress={onPressWithEvent}
      style={[{ flexDirection: "row", flex: 1, paddingVertical: 8 }, style]}
      underlayColor="transparent"
    >
      <>
        <MediumText fontSize={18}>{title}</MediumText>

        {isActive && (
          <FontAwesome5
            style={{ marginLeft: 12 }}
            name={asc ? "caret-up" : "caret-down"}
            size={18}
          />
        )}
      </>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    maxWidth: "75%",
    minWidth: "50%",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonGroup: {
    margin: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  button: {
    width: "60%", 
    padding: 6,
    marginHorizontal: 6
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  }
});