import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import RoundyButton from '../components/RoundyButton';
import { MediumText, RegularText } from '../components/StyledText';
import { FontAwesome5 } from '@expo/vector-icons'

interface MoreWaysToSaveProps {
  setRequestOffice: (arg0: boolean) => void;
  onClose: () => void;
}

export default ({ setRequestOffice, onClose }: MoreWaysToSaveProps)  => {
  return (
    <View style={{ maxWidth: '640px' }}>

        <View style={{flexDirection: 'row', paddingVertical: 12, justifyContent: 'flex-start'}}>
          <RoundyButton eventLabel="More Ways to Save - Back" onPress={onClose}>
            <FontAwesome5 name='arrow-left' color='white' size={16} />
            <RegularText style={{marginLeft: 18}}>Back</RegularText>
          </RoundyButton>

          <View style={{flex: 1}} />
        </View>
        <View>
          <video style={{borderRadius: "16px", marginTop: 10}} width="auto" height="auto" controls preload="metadata">
            <source src={require('../assets/videos/DentalDifference.mp4')+"#t=0.1"} type="video/mp4" />
          </video>
          
        </View>

        <MediumText fontSize={ 24 } style={[styles.headerText, { marginTop: 35 }]}>
          More Ways to Save
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          Like most other costs, healthcare costs are rising and this includes dental care. Joining an in-office plan at a local dental office is a fantastic way to save money. Without an insurance company coming in between your relationship with your practitioner, using in-office plans, dentists can pass savings on to you.
        </RegularText>

        <MediumText fontSize={ 20 } style={styles.subtext}>
          The Best Way to Save = Home Care + Preventative Care
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          Be sure and receive yearly recommended preventative care at the dentist. Also be diligent about both properly brushing 2x/day and properly flossing your teeth daily. “An ounce of prevention is worth a pound of cure.” If dental problems do arise and treatment is needed, its best to address it as soon as possible. Dental disease does not get better by itself and only gets worse over time leading to more treatment and more expense.
        </RegularText>

        <MediumText fontSize={ 20 } style={styles.subtext}>
          Negotiating Payment
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          While not everyone is adept at negotiating fees, one thing everyone can do is ask if you can receive a “Paid in full upfront” savings for your treatment plan as treatment is completed.
        </RegularText>

        <MediumText fontSize={ 20 } style={styles.subtext}>
          Comparing Prices
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          While dental care is very personal and many factors go into the costs of care.It can be good to look at comparisons of fees locally. You can call local dentists for check on pricing. Also, consumer websites like FairHealth.org canhelp with getting a better understanding of local fees.
        </RegularText>
        <RegularText fontSize={ 16 } style={[styles.bodyText, { marginBottom: 56 }]}>
          Cost should not be the only factor in choosing a dentist. Understanding a dentist’s treatment philosophy, type of care provided, and technology used can also help make better decisions on where to spend your benefit dollars. 
        </RegularText>

        <MediumText fontSize={ 24 } style={styles.headerText}>
          What’s the difference between an in-office plan (IOP), dental discount plan (DDP), and dental insurance?
        </MediumText>
        <RegularText fontSize={ 16 } style={[styles.bodyText, { marginBottom: 30 }]}>
          Let’s break it down:
        </RegularText>

        <MediumText fontSize={ 20 } style={styles.subtext}>
          In-Office Plans (IOP)
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          An IOP is an innovative approach to paying for dental treatment that saves both the patient and dentists money by eliminating costly 3rd parties and insurance companies. IOPs are usually offered directly by a dental office to you, the patient. There is no middleman insurance company or third party which allows the dentist to pass savings directly to you. IOPs allow you to only pay for what you need.
        </RegularText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          Treatment savings are offered and you can use your dental benefit dollars (HRA/HSA) to pay for treatment. Preventative care is usually included. Compared to traditional insurance and dental discount plans (DDP) where youpay for it whether you use it or not. There are no claim forms, waiting periods, and IOPs eliminate the hassles andconfusion that comes with traditional insurance.
        </RegularText>

        <MediumText fontSize={ 20 } style={styles.subtext}>
          Dental Discount Plans (DDP)
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          A DDP is usually offered by a 3rd party company or insurance company, not a dental office. Treatment savings are offered and these plans may or may not include preventative care. Depending on the dental discount plan, you may be paying just to access savings. You can still use your dental benefit dollars (HAS/HRA) to pay for treatment.
        </RegularText>

        <MediumText fontSize={ 20 } style={styles.subtext}>
          Traditional Dental Insurance
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          Offered by an insurance company. These are what most people think of whenthey think of dental benefits. These plans have not changed much in over 30 years and they come with a host of limitations and exclusions like waiting periods, downgrades, and high out of pocket costs. 
        </RegularText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          What good are dental insurance benefits if one, you must wait to use them and two, you cannot access your full benefit dollars? You and/or your employer are paying for dental insurance whether it is needed or not. Claim forms and pre-authorizations can also complicate reimbursements.
        </RegularText>

        <MediumText fontSize={ 20 } style={styles.subtext}>
          My current dentist doesn’t offer an in-office plan. What do I do?
        </MediumText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          First, you can use MyDentist Match and find an alternative office that offers an in-office plan. If you are happy with your current dental home and don’t want to switch, the good news is you can still visit your current dentist and use your dental benefit dollars. If they accept credit cards, you’re good to go! 
        </RegularText>
        <RegularText fontSize={ 16 } style={styles.bodyText}>
          The great news is you can refer your local dentist to us and we can introduce them to the benefits of the in-office plan concept and even help get them set-up!
        </RegularText>
      <RoundyButton
        eventLabel="More Ways to Save - Refer A Dentist"
        style={{ 
            height: 52, 
            width: '45%', 
            marginRight: 20,
            marginBottom: 20,
        }} 
        textStyle={{
            fontSize: 16
        }}
        onPress={() => setRequestOffice(true)}
      >Refer A Dentist</RoundyButton> 
    </View>
  );
}

const styles = StyleSheet.create({
  headerText: {
    marginBottom: 20,
  },
  subtext: {
    marginBottom: 5,
  },
  bodyText: {
    marginBottom: 25,
  }
});
