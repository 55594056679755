import { MailDataRequired } from "@sendgrid/mail";
import { AuthSessionResult } from "expo-auth-session";
import { AddressInfo, AuthResponse, BusinessAccount, BusinessItem, ClaimedBusinessItem, Config, MatchSort, User } from '../constants/Models';
import { Headers, HTTPMethod, request, Request, Service } from "./_abstract";

declare var process : {
    env: {
        EXPO_API_HOST: string;
        EXPO_API_KEY: string;
    }
}

const GeneralRequests = {
    info: new Request<{ config: Config }>(HTTPMethod.get, 'info'),
    request: new Request<{ requests: MailDataRequired}>(HTTPMethod.post, 'request'),
    reverseGeo: new Request<AddressInfo>(HTTPMethod.get, `v1/google/geo`)
}

const AuthRequests = {
    loginClient: new Request<AuthResponse>(HTTPMethod.post, "auth/client"),
    loginUserCreatedClient: new Request<AuthResponse>(HTTPMethod.post, "auth/userCreation"),
    loginBusiness: new Request<AuthResponse>(HTTPMethod.post, "auth/business"),
    loginAdmin: new Request<AuthResponse>(HTTPMethod.post, 'auth/admin'),
    me: new Request<User>(HTTPMethod.get, 'auth/me'),
    updateMe: new Request<void>(HTTPMethod.patch, 'auth/me')
}

const SearchRequests = {
    search: new Request<{data: BusinessItem[], paging: { limit: number, hasMore: boolean }}>(HTTPMethod.get, "v1/search"),
    itemDetails: (itemId: string) => new Request<BusinessItem>(HTTPMethod.get, `v1/search/${itemId}`)
}

const BusinessRequests = {
    accounts: new Request<BusinessAccount[]>(HTTPMethod.get, 'v1/google/accounts'),
    locations: (accountId: string) => {
        return new Request<BusinessItem[]>(HTTPMethod.get, `v1/google/accounts/${accountId}/locations`)
    },
    claim: (accountId: string, locationId: string) => new Request<void>(HTTPMethod.post, `v1/google/accounts/${accountId}/locations/${locationId}/claim`),
    
    list: new Request<ClaimedBusinessItem[]>(HTTPMethod.get, `v1/businesses`),
    get: (id: string) => new Request<ClaimedBusinessItem>(HTTPMethod.get, `v1/businesses/${id}`),
    op: (opType: HTTPMethod, id: string) => new Request<void>(opType, `v1/businesses/${id}`)

    // claimedListing: (id: string) => new Request<ClaimedBusinessItem>(HTTPMethod.get, `v1/businesses/${id}`),
    // updateListing: (id: string) => new Request<void>(HTTPMethod.put, `v1/businesses/${id}`)
}

const AdminRequests = {
    businesses: new Request<ClaimedBusinessItem[]>(HTTPMethod.get, 'v1/businesses'),
    multiBusinessDelete: new Request<void>(HTTPMethod.delete, 'v1/businesses'),
    businessOp: (op: HTTPMethod, id: string) => new Request<void>(op, `v1/businesses/${id}`),

    users: new Request<User[]>(HTTPMethod.get, `v1/users`),
    userOp: (op: HTTPMethod, id: string) => new Request<void>(op, `v1/users/${id}`),
    get: (id: string) => new Request<User>(HTTPMethod.get, `v1/users/${id}`),

    importResource: <T>(path: 'businessesStaging'|'users'|'configs') => new Request<T>(HTTPMethod.post, `v1/${path}/import`),
    mergeResource: <T>(path: 'businessesStaging') => new Request<T>(HTTPMethod.post, `v1/${path}/merge`),
    listResource: <T>(path: 'businesses'|'users'|'configs') => new Request<T[]>(HTTPMethod.get, `v1/${path}`),
    countResource: <T>(path: 'businesses'|'users'|'configs') => new Request<T>(HTTPMethod.get, `v1/${path}/count`),
    resourceOp: <T>(path: 'businesses'|'users'|'configs', op: HTTPMethod, id: string) => new Request<void>(op, `v1/${path}/${id}`),
}

export interface SearchQueryParams {
    page: number;
    sortBy: MatchSort;
    // limit: number;
    // postalCode: string;
    query: string;
}

export interface AdminListingsParams {
    page: number;
    limit: number;
    [someKey: string]: any;
}
class Backend implements Service {
    baseUrl = process.env.EXPO_API_HOST

    private tokenProvider?: () => Promise<string|undefined>

    authToken: string | undefined | null

    headers = async () => {
        let h: Headers = { 'x-api-key': process.env.EXPO_API_KEY }
        if (this.tokenProvider) {
            try {
                const authToken = await this.tokenProvider()
                if (authToken && authToken.length > 0) {
                    return { ...h, Authorization: `Bearer ${authToken}`}
                }
            }
            catch (ex) { }
        }
        return h
    }

    ////////////////// General
    getInfo = async (): Promise<{ config: Config }> => {
        return request(this, GeneralRequests.info)
        .call()
    }

    requestAppointment = async (appt: MailDataRequired): Promise<{ requests: MailDataRequired }> => {
        console.log(appt)
        return await request(this, GeneralRequests.request)
        .withBody(appt)
        .call()
        
    }

    reverseGeoCode = async (latitude: number, longitude: number): Promise<AddressInfo> => {
        return request(this, GeneralRequests.reverseGeo)
        .withQuery({ lat: latitude, lng: longitude})
        .call()
    }

    ///////////////// Auth
    registerTokenProvider = (provider: () => Promise<string|undefined>) => {
        this.tokenProvider = provider
    }

    registerClient = async (email: string): Promise<AuthResponse> => {
        this.authToken = null

        const response = await request(this, AuthRequests.loginClient)
        .withBody({ email })
        .call()

        this.authToken = response.token
        console.log({response});
        return response
    }

    registerUserCreatedClient = async (email: string, employer: string): Promise<AuthResponse> => {
        this.authToken = null

        const response = await request(this, AuthRequests.loginUserCreatedClient)
        .withBody({ email, employer })
        .call()

        this.authToken = response.token
        console.log({response});
        return response
    }

    registerBusiness = async (gauth: AuthSessionResult): Promise<AuthResponse> => {
        if (gauth.type != 'success') { throw new Error('Invalid response') }

        const response = await request(this, AuthRequests.loginBusiness)
        .withBody({
            ...gauth.authentication
        })
        .call()

        return response
    }

    authAdmin = async (email: string, password: string): Promise<AuthResponse> => {
        
        const authData = Buffer.from(`${email}:${password}`, 'utf-8').toString('base64')
        return request(this, AuthRequests.loginAdmin)
        .withHeaders({
            Authorization: `Basic ${authData}`
        })
        .call()
    }

    getProfile = async (): Promise<User> => {
        return request(this, AuthRequests.me).call()
    }

    getUser = async (id: string): Promise<User> => {
        return request(this, AdminRequests.get(id))
        .call()
    }

    updateProfile = async (data: Partial<User>): Promise<void> => {
        return request(this, AuthRequests.updateMe).call()
    }

    updateUser = async (id: string, updates: Partial<User>): Promise<void> => {
        return request(this, AdminRequests.userOp(HTTPMethod.patch, id))
        .withBody(updates)
        .call()
    }
    
    // registerUser = async (googleResponse: any): Promise<AuthResponse> => {
        
    // }

    ///////////////// Search
    search = async (query: SearchQueryParams): Promise<{data: BusinessItem[], paging: { limit: number, hasMore: boolean }}> => {
        return request(this, SearchRequests.search)
        .withQuery(query)
        .call()
    }

    searchItemDetails = async (itemId: string): Promise<BusinessItem> => {
        return request(this, SearchRequests.itemDetails(itemId))
        .call()
    }

    ///////////////// Business
    businessAccounts = async (): Promise<BusinessAccount[]> => {
        return request(this, BusinessRequests.accounts)
        .call()
    }
    
    businessLocations = async (accountId: string): Promise<BusinessItem[]> => {
        return request(this, BusinessRequests.locations(accountId))
        .call()
    }

    claimListing = async (accountId: string, locationId: string): Promise<void> => {
        const res = await request(this, BusinessRequests.claim(accountId, locationId))
        .rawCall()

        if (res.status == 201) {
            return
        }
        
        throw new Error('Claim Error: ' + res.statusText)
    }

    ///////////////// Saved Businesses
    getListing = async (id: string): Promise<ClaimedBusinessItem> => {
        return request(this, BusinessRequests.get(id))
        .call()
    }

    updateListing = async (id: string, updates: ClaimedBusinessItem): Promise<void> => {
        return request(this, BusinessRequests.op(HTTPMethod.patch, id))
        .withBody(updates)
        .call()
    }

    //////////////// Admin
    adminBusinesses = async (query: AdminListingsParams): Promise<ClaimedBusinessItem[]> => {
        return request(this, AdminRequests.businesses)
        .withQuery(query)
        .call()
    }

    adminUsers = async (query: AdminListingsParams): Promise<User[]> => {
        return request(this, AdminRequests.users)
        .withQuery(query)
        .call()
    }

    adminConfigs = async (query: AdminListingsParams): Promise<Config[]> => {
        return request(this, AdminRequests.listResource<Config>('configs'))
        .withQuery(query)
        .call()
    }

    adminUpdateConfig = async (id: string, updates: Partial<Config>): Promise<void> => {
        return request(this, AdminRequests.resourceOp('configs', HTTPMethod.patch, id))
        .withBody(updates)
        .call()
    }

    adminUsersImport = async (users: Partial<User>[]): Promise<void> => {
        return request(this, AdminRequests.importResource<void>('users'))
        .withBody(users)
        .call()
    }

    adminBusinessesImport = async (businesses: Partial<BusinessItem>[]): Promise<void> => {
        return request(this, AdminRequests.importResource<void>('businessesStaging'))
        .withBody(businesses)
        .call()
    }

    adminBusinessesMerge = async (): Promise<void> => {
        return request(this, AdminRequests.mergeResource<void>('businessesStaging'))
        .withBody({})
        .call()
    }

    adminBusinessesCount = async (): Promise<void> => {
        return request(this, AdminRequests.countResource<void>('businesses'))
        .call()
    }

    adminBusinessesDelete = async (ids: String[]): Promise<void> => {
        return request(this, AdminRequests.multiBusinessDelete)
        .withBody({ ids })
        .call()
    }
}

export default new Backend()