import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import Colors from "../../constants/Colors";
import { ViewStyle } from "react-native";

interface ExpandCaretProps {
    style?: ViewStyle;
    isExpanded: boolean;
}

export const ExpandCaret = ({ style, isExpanded } : ExpandCaretProps) => (
    <FontAwesome5 
        style={style} 
        size={20} 
        name={isExpanded ? 'angle-up' : 'angle-down'} 
        color={Colors.lightGrey} 
    />
)

