import * as React from 'react';
import { View } from 'react-native';
import { MediumText} from '../StyledText';
import { styles } from "./styles";

interface TitleHeaderProps {
    headerText: string;
    headerNumber: string;
}

export const TitleHeader = ({ headerText, headerNumber }: TitleHeaderProps) => (
    <View style={[styles.headerText, { display: 'flex', flexDirection: 'row', marginTop: 19 }]}>
      <MediumText fontSize={ 18 } style={{ marginRight: 10 }}>
        { headerNumber }
      </MediumText>
      <MediumText fontSize={ 18 } style={{ maxWidth: '95%' }}>
        { headerText }
      </MediumText>
    </View>
  );
