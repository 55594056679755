import { AppointmentRequest } from "./RequestAppointment";
import moment from "moment";
import { formatPhoneNumber } from "../helpers/FormatePhoneNumber";

export const NewAptPlainTextTemplate = (appointmentRequest: AppointmentRequest) => 
    `A new patient would like to schedule an appointment with you!
    please review the following information and contact the patient to confirm their appointment.

    ${appointmentRequest.firstName} ${appointmentRequest.lastName}
    ${formatPhoneNumber(appointmentRequest.phone)}
    ${appointmentRequest.email}
    Preferred date: ${moment(new Date(appointmentRequest.date1)).format("MMMM Do, YYYY")}
    Secondary date: ${moment(new Date(appointmentRequest.date2)).format("MMMM Do, YYYY")}
    Preferred time: ${appointmentRequest.preferredTime}`

export const NewAptEmailTemplate = (appointmentRequest: AppointmentRequest) => 
`<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;1,400&display=swap"
        rel="stylesheet">
    <meta name="color-scheme" content="light">
    <meta name="supported-color-schemes" content="light">

</head>

<body style="font-family: 'IBM Plex Sans', sans-serif; background-color: white; padding: 10">
    <header style="
        margin: auto;
        display: flex;
        padding: 20 0;
        border: 1px solid #E6E6E6;
        border-top: none;
        border-left: none;
        border-right: none;">
        <table style="margin: auto;">

            <tr>
                <td>
                    <img src="http://cdn.mcauto-images-production.sendgrid.net/26999c8feeb52b80/584ba2f5-0e88-4495-9753-14c9ec9bfd39/398x109.png"
                        alt="My Dentist Match Logo" style="max-width: 275;" />
                </td>
        </table>

    </header>
    <section class="hero" style="text-align: center; margin-bottom: 20;">
        <h3 style="font-weight: 500; color: #0099FF;">A new patient would like to schedule an appointment with you!</h3>
        <div style="color: #727481; font-size: smaller;">
            please review the following information and contact the patient to confirm their appointment
        </div>
    </section>

    <table width="250" style="text-align: center;
        font-family: sans-serif;
        border: 1px solid #e6e6e6;
        border-radius: 16px;
        padding: 20 0;
        width: 300;
        max-width: 90%;
        margin: auto;
        margin-top: 20px;
        text-align: left;
        line-height: 1.3;">
        <tr>
            <td style="padding:0px 0px 10px 0px;" bgcolor="#FFF"></td>
        </tr>
        <tr style="
            text-align: center;
            border: 1px solid #E6E6E6;
            border-top: none;
            border-left: none;
            border-right: none;
            padding-bottom: 10;
            margin-bottom: 10;">

        <tr>
            <td>
                <div style="margin: auto;
            background-color: #0099FF;
            border-radius: 50%;
            height: 120px;
            text-align: center;
            width: 120px;
            background: rgb(16, 106, 255);
            background: linear-gradient(0deg, rgba(16, 106, 255, 1) 0%, rgba(0, 212, 255, 1) 100%);">
                    <span style="        
                color: white;
                font-family: 'IBM Plex Sans', sans-serif;
                font-weight: 500;
                font-size: 60px;
                line-height: 2;

                ">${appointmentRequest.firstName.charAt(0)}${appointmentRequest.lastName.charAt(0)}</span>
                    <div>
            </td>
        </tr>
        <tr style="text-align: center; color: #727481;">
            <th>${appointmentRequest.firstName} ${appointmentRequest.lastName}</th>
        </tr>
        <tr style="text-align: center; ">
            <td><a "tel:+1${appointmentRequest.phone}">${formatPhoneNumber(appointmentRequest.phone)}</a></td>
        </tr>
        <tr style="text-align: center; margin-bottom: 10; line-height: 1.5;">
            <td><a href="mailto:${appointmentRequest.email}">${appointmentRequest.email}</a></td>
        </tr>
        </tr>
        <tr>
            <td style="padding:0px 0px 10px 0px;" bgcolor="#FFF"></td>
        </tr>
        <tr style="margin: 10 0;">
            <td style="padding:0px 0px 1px 0px;" bgcolor="#E6E6E6"></td>
        </tr>
        <tr>
            <td style="padding:0px 0px 10px 0px;" bgcolor="#FFF"></td>
        </tr>

        <tr style="font-size: 80%;">
            <td style="padding: 0 0 0 10px">Preferred date: <strong>${ moment(new Date(appointmentRequest.date1)).format("MMMM Do, YYYY") }</strong></td>
        </tr>
        <tr style="font-size: 80%;">
            <td style="padding: 0 0 0 10px">Secondary date: <strong>${ moment(new Date(appointmentRequest.date2)).format("MMMM Do, YYYY") }</strong></td>
        </tr>
        <tr style="font-size: 80%;">
            <td style="padding: 0 0 0 10px">Preferred time: <strong>${appointmentRequest.preferredTime}</strong></td>
        </tr>
        <tr>
            <td style="padding:0px 0px 10px 0px;" bgcolor="#FFF"></td>
        </tr>
    </table>
</body>

</html>`

