import React, { PropsWithChildren } from "react"
import { ButtonProps, StyleSheet, TouchableHighlight, TouchableHighlightProps, View, GestureResponderEvent } from "react-native"
import Colors from "../constants/Colors"
import events from '../helpers/GoogleAnalytics'

interface ShadowBoxButtonProps extends PropsWithChildren<TouchableHighlightProps> { 
    eventLabel: string,
}

export default ({style, onPress, eventLabel, ...props}: ShadowBoxButtonProps) => {
    const onPressWithEvent = (e: GestureResponderEvent) => {
        events.sendButtonClicked(eventLabel)
        if (onPress) {
            onPress(e)
        }
    }
    return (
        <TouchableHighlight onPress={onPressWithEvent} underlayColor={Colors.offwhite} {...props} style={[style, styles.container]} />
    )
}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: Colors.border,
        shadowColor: '#050505',
        shadowOffset: {
            width: 0,
            height: 3
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        padding: 16,
        borderRadius: 8,
        backgroundColor: Colors.white
    }
})