
import React, { useContext, useState } from "react"
import { TouchableHighlight, View, StyleSheet, ViewProps } from "react-native"
import { FontAwesome5 } from "@expo/vector-icons"

import StyledInput from "../../components/StyledInput"
import Colors from "../../constants/Colors"
import { AlternatePlanFee } from "../../constants/Models"
import { MediumText, RegularText } from "../../components/StyledText"
import TextInputWrapper from "../../components/TextInputWrapper";
import { MediaQueryContext, ScreenSize } from "../../contexts/MediaQueryContext"
import events from '../../helpers/GoogleAnalytics'
interface OptionalAlternatePlanFeeProps extends ViewProps {
    optionalPlans: AlternatePlanFee[]|null;
    onChange: (optionalPlans: AlternatePlanFee[]) => void;
}

export default ({ optionalPlans, onChange, ...props}: OptionalAlternatePlanFeeProps) => {
    const { screenSize } = useContext(MediaQueryContext)

    const removeTapped = (item: AlternatePlanFee, idx: number) => {
        if (optionalPlans?.length) {
            const filteredPlans = optionalPlans?.filter((_, index) => index != idx)
            onChange(filteredPlans);
        }
    }

    const addPlanTapped = () => {
        onChange((optionalPlans||[]).concat({
            name: '',
            annually: '$0 /Year',
            monthly: '$0 /Month',
            deposit: '',
            savingsDescription: '',
        }))
        events.sendButtonClicked('Optional Service Fee - Add Plan')
    }

    const updateFee = (idx: number, updates: AlternatePlanFee) => {
        let blah = [...optionalPlans || []]
        blah[idx] = updates
        onChange(blah)
    }

    ////////////// Rendering
    const renderFeeItem = (fee: AlternatePlanFee, idx: number) => {

        return (
            <>
            <View style={[styles.feeContainer]} key={idx}>
                <TouchableHighlight onPress={() => {
                    removeTapped(fee, idx)
                    events.sendButtonClicked('Optional Service Fee - Remove Fee')
                }} style={styles.removeIconContainer} underlayColor={'red'}>
                    <FontAwesome5 size={12} name={'minus'} color={Colors.error}></FontAwesome5>
                </TouchableHighlight>


                <View style={{ flex: 1, flexDirection: screenSize > ScreenSize.tablet ? 'row' : 'column', justifyContent: 'space-between', alignItems: screenSize > ScreenSize.tablet ? 'center' : 'flex-start'}}>
                    <StyledInput 
                        onChangeText={v => updateFee(idx, {...fee, name: v })} 
                        containerStyle={screenSize > ScreenSize.tablet ? {marginRight: 12} : {width: '100%', paddingHorizontal: 0}}
                        style={[styles.input, {maxWidth: undefined}, screenSize <= ScreenSize.tablet && {marginHorizontal: 0, marginBottom: 12}]} 
                        value={fee.name} placeholder={'Name'} />

                    
                    <View style={{flexDirection: 'row', flex: 1, maxWidth: '100%', justifyContent: 'space-between'}}>
                    <TextInputWrapper label={screenSize > ScreenSize.tablet ? '' : 'Annual Price'} containerStyle={styles.inputContainer} style={styles.input} onChangeText={v => updateFee(idx, {...fee, annually: v })} value={fee.annually ?? '0'} placeholder={'$0 /Year'} />
                    <TextInputWrapper label={screenSize > ScreenSize.tablet ? '' : 'Monthly Price'} containerStyle={styles.inputContainer} style={styles.input} onChangeText={v => updateFee(idx, {...fee, monthly: v })} value={fee.monthly ?? '0'} placeholder={'$0 /Month'} />
                    <TextInputWrapper label={screenSize > ScreenSize.tablet ? '' : 'Downpayment'} containerStyle={styles.inputContainer} style={[styles.input, {marginRight: 0}]} onChangeText={v => updateFee(idx, {...fee, deposit: v })} value={fee.deposit ?? ''} placeholder={''} />
                    </View>
                </View>
            </View>
            <View>
                <MediumText style={{ marginBottom: 12 }} fontSize={20}>Description of plan</MediumText>
                <StyledInput
                    style={{
                        minHeight: 120,             
                        paddingVertical: 10,
                        paddingHorizontal: 18,
                        marginBottom: 20,
                    }}
                    placeholder={'Description of plan'}
                    multiline 
                    numberOfLines={0} 
                    onChangeText={desc => updateFee(idx, {...fee, savingsDescription: desc })}
                    value={fee.savingsDescription} />
                <MediumText style={{ marginBottom: 12 }} fontSize={20}>Plan IOP Link</MediumText>
                <StyledInput
                    style={{
                        width: '65%',
                        height: 50,
                        paddingVertical: 10,
                        paddingHorizontal: 18,
                        marginBottom: 20,
                    }}
                    placeholder={'Plan IOP Link'}
                    multiline 
                    numberOfLines={1} 
                    onChangeText={iopLink => updateFee(idx, {...fee, iopLink, })}
                    value={fee.iopLink} />
            </View>
            </>
        )
    }

    return (
        <View {...props}>
            <MediumText fontSize={18} style={styles.title}>Additional Plans</MediumText>
            {optionalPlans?.map(renderFeeItem)}

            <TouchableHighlight style={[styles.addContainer]} onPress={addPlanTapped} underlayColor={'white'}>
                <>
                    <FontAwesome5 name={'plus-circle'} size={18} color={Colors.primary} />
                    <RegularText fontSize={18} style={{color: Colors.primary, paddingLeft: 12}}>Add Additional Plan</RegularText>
                </>
            </TouchableHighlight>
        </View>
    )
}

const styles = StyleSheet.create({
    title: {
        marginBottom: 18
    },
    
    addContainer: {
        flexDirection: 'row', 
        alignItems: 'center'
    },
    feeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 18,
        justifyContent: 'space-between',
        flex: 1,
    },

    removeIconContainer: {
        borderWidth: 2,
        borderColor: Colors.error,
        paddingHorizontal: 2,
        paddingVertical: 1,
        borderRadius: 99999,
        marginRight: 12
    },
    input: {
        paddingHorizontal: 12,
        paddingVertical: 12,
        fontSize: 18,
        // marginHorizontal: 8,
        // maxWidth: '12%'
        flex: 1,
        flexGrow: 1
    },
    inputContainer: {
        flexBasis: '33.3333333%',
        // minWidth: '100px', 
        flex: 1
    }
})