import React, { useCallback} from "react"
import { View , StyleSheet, ViewProps, Linking } from "react-native"
import { LinkItem } from "."
import Colors from "../../constants/Colors"
import { ContactInfo as iContactInfo } from "../../constants/Models"
import call from "../../helpers/Call"

interface ContactInfoProps extends ViewProps {
    info: iContactInfo;
    openLink: (website: string) => void
}

export const ContactInfo = (props: ContactInfoProps) =>  {
    const { info: { phone, website, address }} = props

    const web = useCallback(() => {
        if (!website) { return }
        console.log(`Open ${website}`)
        props.openLink(website)
    }, [website])

    const openMaps = (str: string) => {
        // Todo
    }

    return (
        <View style={[style.container, props.style]}>
            { 
                phone && 
                    <LinkItem eventLabel="Contact Info - Phone" icon='phone' text={phone} action={() => call(phone)} /> 
            }
            { 
                website && 
                    <LinkItem 
                        eventLabel="Contact Info - Website"
                        icon='link' 
                        color={Colors.primary} 
                        text={website.replace(/https?:\/\//, '').split('/')[0]} 
                        action={web} 
                    /> 
            }
            <LinkItem
                eventLabel="Contact Info - Address"
                icon='map-marker-alt' 
                text={`${address.line1}${address.line2 ? '\n' + address.line2 : ''}\n${address.city}, ${address.state}`} 
                action={openMaps} 
            />
        </View>
    )
} 

const style = StyleSheet.create({
    container: {

    },
})