import { FontAwesome5 } from '@expo/vector-icons'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useContext, useEffect, useState } from 'react'
import { ViewProps, View, FlatList, StyleSheet, TouchableHighlight, ListRenderItem } from "react-native"
import backend, { SearchQueryParams } from '../backend/backend'
import BusinessListing from '../components/BusinessListing'
import Footer from '../components/Footer'
import LoadingComponent from '../components/LoadingComponent'
import MatchYouWant from '../components/MatchYouWant'
import MaxWidthContainer from '../components/MaxWidthContainer'
import Navbar from '../components/Navbar'
import Pagination from '../components/Pagination'
import RequestOffice from '../components/RequestOffice'
import SearchButton from '../components/SearchButton'
import ShadowBoxButton from '../components/ShadowBoxButton'
import { MediumText, RegularText } from '../components/StyledText'
import Colors from '../constants/Colors'
import { defaultSortOptions, MatchSort, BusinessItem, titleForMatchSort } from '../constants/Models'
import { MediaQueryContext, ScreenSize } from '../contexts/MediaQueryContext'
import { RootStackParamList } from '../types'
import MoreWaysToSave from './MoreWaysToSave'
import events from '../helpers/GoogleAnalytics'


type SearchResultsRouteProp = RouteProp<RootStackParamList, 'SearchResults'>

type SearchResultProps = {
    route: SearchResultsRouteProp
}

export default ({ route }: SearchResultProps) => {
    const [results, setResults] = useState<BusinessItem[]|null>(null)
    const [paging, setPaging] = useState<{limit: number, hasMore: boolean}>({limit: 10, hasMore: false})
    const [page, setPage] = useState(0)
    const [wayToSave, setWayToSave] = useState(false);
    const [requestOffice, setRequestOffice] = useState(false);

    useEffect(() => {
        loadData(page)
    }, [page, route.params])

    const loadData = async (page: number) => {
        try {
            const query: SearchQueryParams = {
                page,
                // limit: paging.limit,
                query: route.params.query,
                sortBy: route.params.sortBy as MatchSort
            }
            const { data, paging: pagingInfo } = await backend.search(query)
            setResults(data)
            setPaging(pagingInfo)
        } catch (ex) {
            console.error(ex)
        }
    }

    const loadMore = async () => {
        setPage(page + 1)
    }

    return (
        <MaxWidthContainer style={{flex: 1}}>
            <SearchResultsNav setRequestOffice={ setRequestOffice } setWayToSave={ setWayToSave } />
            { /* TODO: Use a Context instead of state and props for this waysToSave setup */
                wayToSave && !requestOffice && <MoreWaysToSave setRequestOffice={ () => setRequestOffice(false) } onClose={ () => setWayToSave(false) } />
            }
            {
                requestOffice && <RequestOffice setWayToSave={ setWayToSave } />
            }
            {
                (results && !requestOffice) ? (results.length > 0 ? 
                <SearchResultsList 
                    hasPrev={page > 0}
                    setWayToSave={ setWayToSave }
                    setRequestOffice={ setRequestOffice }
                    loadPrev={() => setPage(page-1)} 
                    loadNext={loadMore} 
                    style={{marginTop: 20}} 
                    hasMore={paging.hasMore}
                    data={results} />
                : (
                    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <MediumText fontSize={20}>
                            No offices found in {route.params.zip}
                        </MediumText>
                    </View>
                )
                ) : (!wayToSave && !requestOffice) ? <LoadingComponent /> : <Footer />
            }
        </MaxWidthContainer>
    )
}

interface SearchResultsListProps extends ViewProps {
    data: BusinessItem[];
    loadPrev: () => void;
    loadNext: () => void;
    hasPrev: boolean;
    hasMore: boolean;
    setWayToSave: Function;
    setRequestOffice: Function;
}
const SearchResultsList = ({ data, loadPrev, loadNext, style, hasMore, hasPrev, setWayToSave, setRequestOffice }: SearchResultsListProps) => {
    const [expandedResults, setExpandedResults] = useState<number[]>([])
    const { screenSize } = useContext(MediaQueryContext)



    const getRenderResult: (setWayToSave: Function) => ({ item, index }: any) => JSX.Element = (setWayToSave: Function) => ({ item, index }: any) => {
        const isExpanded = expandedResults.includes(index)
        const onOverallTap = () => {
            if (isExpanded) { 
                const results = [...expandedResults]
                results.splice(results.indexOf(index), 1)

                setExpandedResults(results) 
                
            }
            else { setExpandedResults(expandedResults.concat([index]))}
        }

        return <BusinessListing isExpanded={isExpanded} item={item} onPress={onOverallTap} requestAppointment setWayToSave={ setWayToSave }/>
    }

    const renderFooter = (setWayToSave: Function, setRequestOffice: Function) => {
        return (
            <>
                <Pagination
                    label="Search Results"
                    hasMore={hasMore} 
                    hasPrev={hasPrev} 
                    loadPrev={loadPrev} 
                    loadNext={loadNext} />
                <MatchYouWant 
                    setWayToSave={ setWayToSave }
                    setRequestOffice={ setRequestOffice }
                />
                <Footer/>
            </>
        )
    }

    const renderHeader = () => null

    return (
        <>
            <FlatList
                style={style}
                showsVerticalScrollIndicator={false}
                data={data}
                renderItem={getRenderResult(setWayToSave)}
                keyExtractor={(_: any, idx: number) => `${idx}`}
                ListHeaderComponent={renderHeader()}
                ListFooterComponent={renderFooter(setWayToSave, setRequestOffice)}
            />

        </>
    )
}


const SearchResultsNav = ({ setRequestOffice, setWayToSave }: any) => {
    const navigation = useNavigation()
    const route = useRoute<SearchResultsRouteProp>()
    const [viewSort, setViewSort] = useState<MatchSort|null>(null)
    const [sortDropdownVisible, setSortDropdownVisible] = useState(false)
    const { screenSize } = useContext(MediaQueryContext)

    useEffect(() => {
        if (viewSort == route.params.sortBy) { return }
        let val: string|null = route.params.sortBy as MatchSort
        if (val == 'null') { val = null }
        setViewSort(val as MatchSort)
        setSortDropdownVisible(false)
    }, [route.params.sortBy])
    
    const matchPressed = () => {
        navigation.navigate('SearchOptions', {
            matchSort: route.params.matchSort,
            zip: route.params.query
        })
    }

    const updateViewSort = (op: MatchSort|null) => {
        // setViewSort(op)
        navigation.navigate('SearchResults', {
            ...route.params,
            sortBy: op == null ? undefined : op
        })
    }

    const sortPressed = () => {
        // TODO: Implement this
        setSortDropdownVisible(!sortDropdownVisible)
    }

    const ViewSortOption: ({ op, label }: {op: MatchSort|null, label: string}) => JSX.Element = ({ op, label }) => {
        const isSelected = op == viewSort
        return (
            <TouchableHighlight onPress={() => {
                updateViewSort(op)
                events.sendButtonClicked('Search Results - View Sort Option')
            }} underlayColor={'clear'}>
                {
                    isSelected ? <MediumText fontSize={18}>{label}</MediumText> : <RegularText fontSize={18}>{label}</RegularText>
                }
            </TouchableHighlight>
        )
    }


    const isCompact = screenSize < ScreenSize.large
    
    return (
        <Navbar style={{zIndex: 999}}>
        <>
            <View style={[styles.headerContainer]}>
                <MediumText style={[{flex: 1}, isCompact && {width: '100%', textAlign: 'center', marginBottom: 12}]} fontSize={16}>MyDentist Match</MediumText>

                <View style={{flexDirection: 'row'}}>
                    {/* MatchSort Options */ }
                    <ShadowBoxButton eventLabel="Search Results - MatchSort Options" style={{flexDirection: 'row', backgroundColor: Colors.white, marginLeft: screenSize < ScreenSize.tablet ? 0 : 20, marginRight: 20}} onPress={matchPressed}>
                        <>
                            <FontAwesome5 style={{marginRight: 12}} name="sort-amount-down" size={16} color="black" />
                            <MediumText fontSize={16}>
                                {titleForMatchSort(viewSort)}
                            </MediumText>

                            <View style={{backgroundColor: Colors.border,  width: 1, marginHorizontal: 20, marginVertical: -8}} />

                            <FontAwesome5 style={{marginRight: 12}} name='map-marker-alt' size={24} color='black' />
                            <MediumText fontSize={16}>{route.params.query}</MediumText>
                        </>
                    </ShadowBoxButton>


                    {/* TODO: This matchSortOptions should take the corrent match sort options and just replace the first with viewSort */}
                    <SearchButton 
                        label="Search Results"
                        showTitle={!isCompact} 
                        style={styles.searchButton} 
                        fontSize={16} 
                        matchSortOptions={viewSort ?[viewSort] : []}
                        query={route.params.query} 
                        setWayToSave={ setWayToSave }
                        setRequestOffice={ setRequestOffice }
                    />

                </View>
            </View>

            {/* View Sort Button */}
            {/* TODO: Add back in when ready
            { screenSize > ScreenSize.large  &&
            <ShadowBoxButton onPress={sortPressed} style={{marginLeft: 24, flexDirection: 'row'}}>
                <>
                    <RegularText>Sort: </RegularText>
                    <MediumText>{titleForMatchSort(viewSort)}</MediumText>
                    <View style={{width: 20}} />
                    <Ionicons name='ios-arrow-down' color={Colors.lightGrey} size={16}/>
                </>
            </ShadowBoxButton>
            } */}


            {sortDropdownVisible && (
            <View style={styles.sortDropdownContainer}>
                <ViewSortOption op={null} label="Match" />
                {
                    defaultSortOptions.map((p) => <ViewSortOption key={p} op={p} label={titleForMatchSort(p)} />)
                }
            </View>
            )}
        </>
        </Navbar>
    )
}

const styles = StyleSheet.create({
    headerContainer: {
        flex: 1, 
        borderRadius: 8,
        paddingVertical: 16,
        paddingHorizontal: 32,
        backgroundColor: Colors.offwhite, 
        borderColor: Colors.border, 
        borderWidth: 1, 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        zIndex: 99
    },


    searchButton: {width: undefined, paddingVertical: 16, paddingHorizontal: 24},
    sortDropdownContainer: {
        zIndex: 999,
        position: 'absolute', 
        right: 0, 
        top: '80%', 
        borderColor: Colors.border, 
        borderWidth: 1, 
        padding: 20, 
        backgroundColor: Colors.white
    }
})