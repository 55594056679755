
import {
    IBMPlexSans_400Regular,
    IBMPlexSans_500Medium, useFonts
} from '@expo-google-fonts/ibm-plex-sans';
import { Prompt } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import * as WebBrowser from 'expo-web-browser';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Animated,
    ButtonProps, Image, StyleSheet, TouchableHighlight, View
} from 'react-native';
import { APIError } from '../backend/_abstract';
import Footer from '../components/Footer';
import MaxWidthContainer from '../components/MaxWidthContainer';
import RoundyButton from '../components/RoundyButton';
import StyledInput from '../components/StyledInput';
import { MediumText, RegularText } from '../components/StyledText';
import Colors from '../constants/Colors';
import { AuthContext } from '../contexts/AuthContext';
import events from '../helpers/GoogleAnalytics'


declare var process : {
    env: {
        EXPO_GOOGLE_CLIENT_ID: string;
    }
}


WebBrowser.maybeCompleteAuthSession();

export default ({ Navbar, isDentalDifference }: { Navbar: any, isDentalDifference?: boolean }) => () => {
    const [fontsLoaded] = useFonts({
        IBMPlexSans_400Regular,
        IBMPlexSans_500Medium,
    })

    const { auth, registerClient, registerUserCreatedClient, registerBusinessAccount } = useContext(AuthContext)
    const [email, setEmail] = useState('');
    const [employer, setEmployer] = useState('');
    const [emailError, setEmailError] = useState<string|null>(null);
    const [userCreation, setUserCreation] = useState(false);
    const errorAnimation = useRef(new Animated.Value(0)).current
    // const navigation = useNavigation()
    const [request, response, promptAsync] = Google.useAuthRequest({
        webClientId: process.env.EXPO_GOOGLE_CLIENT_ID,
        prompt: Prompt.Consent,
        scopes: [
            'https://www.googleapis.com/auth/business.manage'
        ]
    })

    useEffect(() => {
        console.log(`Email error check`)
        Animated.timing(errorAnimation, {
            duration: 500,
            useNativeDriver: false,
            toValue: errorAnimation == null ? 0 : 1
        }).start()
    }, [emailError])

    // useEffect(() => {
    //     console.log(`Has auth? ${auth}`)
    //     if (!auth || !auth.role) { return }

    //     // We have a user
    //     switch (auth.role) {
    //         case 'business':
    //             navigation.navigate('BusinessListings')
    //             return
    //         case 'client':
    //             navigation.navigate('SearchOptions')
    //             return
    //     }

    // }, [auth])

    if (!fontsLoaded) {
        return null;
    }

    const onSubmitEmail = async () => {
        try {
            if(!employer && !userCreation){
                await registerClient(email.toLocaleLowerCase())
            } else if (userCreation && !employer) {
                setEmailError('Please enter your employer to proceed');
            } else {
                await registerUserCreatedClient(email.toLocaleLowerCase(), employer);
            }
        }
        catch (ex) {
            console.log(ex)
            if ((ex as APIError).details as {[key: string]: string}) {
                const details = (ex as APIError).details as {[key: string]: string}
                if (details['message'] === 'ALLOW_USER_CREATION') {
                    setUserCreation(true);
                } else {
                    setEmailError(details['message'] || (ex as Error).message)
                }
            }
            else {
                setEmailError((ex as Error).message)
            }
        }
    }

    const onGoogleAuth = async () => {
        try {
            const result = await promptAsync()
            console.log(`register response `, result)
            if (result.type == 'success') {
                registerBusinessAccount(result)
            }
            else {
                console.log(`invalid result `, result)
            }
        }
        catch (ex) {
            console.error(ex)
        }
    }

    const onSubmitError = async () => {
        setEmailError('Uh-oh, we don’t recognize that email address. Contact your employer to gain access.')
    }


    const isError = emailError != null
    const inputBorder = isError ? Colors.error : Colors.inputBorder

    return (
        <MaxWidthContainer style={{flexDirection: 'column', backgroundColor: Colors.white}}>
            <Navbar />
            <View style={{padding: 40, alignItems: 'center'}}>
                <RegularText fontSize={24}>
                    Verify your email address to proceed.
                </RegularText>

                <StyledInput 
                    value={email}
                    onChangeText={setEmail}
                    onEnter={onSubmitEmail}
                    placeholder='email@website.com' 
                    style={{borderColor: inputBorder, marginVertical: 20}} />
                {
                    userCreation 
                    && 
                    <React.Fragment>
                        <RegularText fontSize={24}>
                        Enter your Employer
                        </RegularText>
                        <StyledInput
                            onChangeText={setEmployer}
                            onEnter={onSubmitEmail}
                            placeholder='employer name' 
                            style={{borderColor: inputBorder, marginVertical: 20}}
                            />
                    </React.Fragment>
                }
                <RegularText 
                    fontSize={18} 
                    style={{
                        maxWidth: 425,
                        opacity: errorAnimation,
                        color: Colors.error
                    }}>
                        {emailError}
                </RegularText>
                <RoundyButton eventLabel="Auth - Verify your Email" onPress={onSubmitEmail} style={{marginTop: 20, backgroundColor: (!isDentalDifference) ? Colors.primary : '#642C89'}}>
                    Verify your Email
                </RoundyButton>

                { !isDentalDifference &&
                  <>
                    <View style={{flex: 1, width: '100%', height: 1, backgroundColor: Colors.border, marginVertical: 40}} />
                    
                    <MediumText fontSize={24} style={{marginBottom: 18}}>Dentist Owners:</MediumText>
                    <RegularText fontSize={18} style={{marginBottom: 40}}>Sign in with your Google Account to claim and manage your MyDentist Match listing</RegularText>
                    
                    
                    <GoogleSigninButton disabled={!request} onPress={onGoogleAuth} title='Sign in with Google' />
                  </>
                }
            </View>
            <Footer />
        </MaxWidthContainer>
    )
}



interface GoogleSigninButtonProps extends ButtonProps {

}

const googleSigninStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 18,
        borderRadius: 9999999,
        maxWidth: 425,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        position: 'relative',

        borderColor: Colors.inputBorder,
        borderWidth: 1
    },
    main: {
        color: Colors.white,
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'IBMPlexSans_500Medium'
    },
    icon: {
        position: 'absolute',
        top: 18,
        left: 18,
        resizeMode: 'contain',
        width: 24, 
        height: 24
    }
})

const GoogleSigninButton = ({ onPress, title }: GoogleSigninButtonProps) => {
    events.sendButtonClicked('Google Signin')
    return (
        <TouchableHighlight onPress={onPress} underlayColor={'white'} style={googleSigninStyles.container}>
            <>
            <Image source={require('../assets/images/google_icon.png')} style={googleSigninStyles.icon} />
            <RegularText fontSize={20}>{title}</RegularText>
            </>
        </TouchableHighlight>
    )
}