import React, { useContext } from 'react'
import { StyleSheet, useWindowDimensions, View, ViewProps, ViewStyle } from "react-native"
import { ScrollView } from 'react-native-gesture-handler'
import Colors from '../constants/Colors'
import { MediaQueryContext, ScreenSize } from '../contexts/MediaQueryContext'
import { ScrollContext, ScrollProvider } from '../contexts/ScrollContext'


interface ContainerProps extends ViewProps {
    children?: React.ReactNode
}

const mainStyles = StyleSheet.create({
    scroller: {
        backgroundColor: Colors.white,
        minHeight: '100%',
        flex: 1
    },
    container: {
        backgroundColor: Colors.white,
        width: '100%',
        maxWidth: 1000,
        paddingHorizontal: 20,
        margin: 'auto',
        minHeight: '100%',
        flex: 1
    }
})

export default (props: ContainerProps) => {
    const { height } = useWindowDimensions()
    return (
        <ScrollProvider>
            <ScrollContainer {...props} />
        </ScrollProvider>
    )
}

const ScrollContainer = (props: ContainerProps) => {
    const { scrollEnabled } = useContext(ScrollContext)

    return (
        <ScrollView 

            scrollEnabled={scrollEnabled} 
            disableScrollViewPanResponder={!scrollEnabled} 
            bounces={scrollEnabled}
            canCancelContentTouches={scrollEnabled}
            nestedScrollEnabled={!scrollEnabled}
            contentContainerStyle={mainStyles.scroller}>
            <View {...props} style={[props.style, mainStyles.container ]} />
        </ScrollView>
    )
}