import { FontAwesome5 } from "@expo/vector-icons"
import moment from "moment"
import React from "react"
import { StyleSheet, Text, TouchableHighlight, View } from "react-native"
import CalendarPickerModal from "./CalendarPickerModal"
import TextInputWrapper from "./TextInputWrapper"

type CalendarPickerProps = {
    style?: any;
    currentDate: string;
    modalVisible: boolean;
    setModalVisible: Function;
    onChange: Function;
    label: string;
}

export default ({ 
    style,
    currentDate, 
    modalVisible, 
    setModalVisible, 
    onChange,
    label,
}: CalendarPickerProps)  => {
    return (
        <View style={[style, { display: 'flex', flexDirection: 'row', justifyContent: 'center' }]}>
            <TouchableHighlight onPress={() => setModalVisible(!modalVisible) } underlayColor={'transparent'}>
                <View style={{flexDirection: 'column'}}>
                    <TextInputWrapper 
                        value={currentDate} 
                        style={{width: 195, height: 48, flex: 1}}
                        label={ label } 
                        placeholder="Select Date" 
                    />
                    <FontAwesome5 
                        name='calendar-alt' 
                        color='black' 
                        size={20} 
                        style={{
                            marginTop: 52, 
                            flexDirection: 'column', 
                            width: 18,
                            position: 'absolute',
                            left: '82%'
                        }} 
                    />
                </View>
            </TouchableHighlight>
            <CalendarPickerModal 
                visible={ modalVisible } 
                setModalVisible={ setModalVisible } 
                currentDate={ moment(currentDate) } 
                onChange={ onChange }
            />
        </View>
    )
}
