import { IBMPlexSans_400Regular, useFonts } from '@expo-google-fonts/ibm-plex-sans'
import React from 'react'
import {
    StyleSheet,
    TextInput,
    TextInputProps,
    View,
} from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons'


export interface StyledInputProps extends TextInputProps {
    onEnter?: () => void;
}

export default ({style, ...props}: StyledInputProps & TextInput['props']) => {
    const [loaded] = useFonts({ IBMPlexSans_400Regular })
    if (!loaded) { return null }

    const onKeyPress = ({ nativeEvent: { key }}: { nativeEvent: { key?: string }} ) => {
        if (key && key.toLowerCase() == 'enter') {
            props.onEnter && props.onEnter()
        }
    }

    return (
        <View style={[styles.container, style]}>
            <FontAwesome5 style={styles.icon} name='search' color='black' size={24} />
            <TextInput {...props} onKeyPress={onKeyPress} style={styles.input} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center', 
        backgroundColor: '#FFFFFF',
        borderColor: '#E5E5E5',
        borderWidth: 1,
        borderRadius: 8,
        boxShadow: '0 3px 4px 0 rgba(5, 5, 5, 0.25)',
        width: 230,
        height: 52,
    },
    icon: {
        margin: 5,
        marginLeft: 15,
        flexBasis: '5%',
    },
    input: {
        display: 'flex',
        flex: 1,
        height: 52,
        flexBasis: '55%',
        border: 'none',
        outlineStyle: 'none',
        fontFamily: 'IBMPlexSans_500Medium',
        fontSize: 16,
        lineHeight: 19,
        marginLeft: 8,
        maxWidth: 160,
    },
})

