import { States } from "../constants/States";

export function convertUsStateAbbrAndName(input: string): string {
    const toAbbr = input.length !== 2;

    let i; // Reusable loop variable
  
    if (toAbbr) {
      input = input.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      for (i = 0; i < States.length; i++) {
        if (input.includes(States[i][0])) {
          return input.replace(States[i][0], States[i][1] );
        }
      }
    }
    return input;
  }