import { Link } from "@react-navigation/native"
import React from "react"
import { StyleSheet, Text, View, ViewProps } from "react-native"
import RoundyButton from "./RoundyButton"
import { MediumText, RegularText } from "./StyledText"


const styles = StyleSheet.create({
    container: {
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    linkText: {
        color: '#2e78b7',
    },
    link: {
        marginBottom: 40,
        marginLeft: 20,
        flexDirection: 'column'
    }
})

type matchYouWantProps = {
    setWayToSave: Function;
    setRequestOffice: Function;
}

export default ({ setWayToSave, setRequestOffice }: matchYouWantProps)  => {
    return (
        <View 
            style={{ 
                flex: 1, 
                width: '65%',
                flexDirection: 'column',
                marginBottom: 100,
                marginLeft: '7%'
            }}
        >
            <MediumText style={{ marginBottom: 15 }} fontSize={ 24 }>Want to see more matches?  Search using Zip Code.</MediumText>
            <RegularText style={{ marginBottom: 15 }} fontSize={ 24 }>Not seeing a match you want?</RegularText>
            <RegularText style={{ marginBottom: 15 }} fontSize={ 16 }>Have you tried using a zip code?</RegularText>
            <RegularText style={{ marginBottom: 15 }} fontSize={ 16 }>You can use your benefit dollars at any dental office. If they accept credit card payments, you are good to go!</RegularText>
            <RegularText style={{ marginBottom: 25 }} fontSize={ 16 }>Maybe you already have a dentist and want them to offer an in-office plan for you. Refer your dentist to us and we'll discuss the benefits of offering in-office plans and help get them set up!</RegularText>
            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                <RoundyButton
                    eventLabel="Match You Want - Refer A Dentist"
                    style={{ 
                        height: 52, 
                        maxWidth: 262, 
                        marginRight: 20,
                        marginBottom: 20,
                    }} 
                    textStyle={{
                        fontSize: 16
                    }}
                    onPress={() => setRequestOffice(true)}
                >Refer A Dentist</RoundyButton> 
                <RoundyButton
                    eventLabel="Match You Want - More Ways to Save"
                    style={{ 
                        height: 52, 
                        maxWidth: 262, 
                    }} 
                    textStyle={{
                        fontSize: 16
                    }}
                    onPress={() => setWayToSave(true)}
                >More Ways to Save</RoundyButton> 
            </View>
        </View>
    )
}