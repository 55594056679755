import React from 'react'
import { FontAwesome5 } from '@expo/vector-icons'
import { ViewProps, TouchableHighlight, StyleProp, ViewStyle } from "react-native"
import Colors from '../constants/Colors'

interface CheckBoxProps extends ViewProps {
    value?: boolean;
    onValueChange?: Function;
    style?: StyleProp<ViewStyle>;
    iconType?: { [x: string]: string };
    color?: string;
    size?: number;
}

const iconType: { [x: string]: string } = {
    undefined: 'minus-square',
    false: 'square',
    true: 'check-square',
}

export default (props: CheckBoxProps) => {
    const { 
        value, 
        onValueChange, 
        style, 
        iconType: propsIconType, 
        color: propsColor, 
        size: propsSize 
    } = props;

    const primaryOrGray = (value) ? Colors.primary : 'gray';
    const color = (propsColor) ? propsColor : primaryOrGray;
    const iconTypes = (propsIconType) ? propsIconType : iconType;
    const size = (propsSize) ? propsSize : 24;

    const onPress = () => { 
        console.log(onValueChange)
        if (onValueChange) {
            console.log('called')
            onValueChange(!value);
        }
    }
    
    return (
        <TouchableHighlight underlayColor='transparent' onPress={onPress}>
            <FontAwesome5 style={ style }  color={color} name={iconTypes[value?.toString() || 'undefined']} size={size} />
        </TouchableHighlight>
    )
}
