import React from 'react'
import { View } from 'react-native'
import { FontAwesome5 } from "@expo/vector-icons";
import Colors from "../../constants/Colors";
import { MediumText, RegularText } from '../StyledText';




export interface OfferingProps {
    available: boolean
    title: string
    position?: any
    style?: any
}

export default ({ available, title, position, style }: OfferingProps) => (
    <View style={[{ flexDirection: 'row', position: (position || 'relative'), left: '70%', width: 173, height: 'auto' }, style]}>
        {available ?
            <FontAwesome5 style={{ marginRight: 8, color: Colors.green }} size={20} name={'check'} />
            :
            <MediumText fontSize={16} style={{ color: Colors.inputBorder }}>N/A </MediumText>
        }
        
        <RegularText fontSize={16} >
            {title}
        </RegularText>
    </View>
)