const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  primary: '#0099FF',
  white: '#ffffff',
  offwhite: '#F5F5F5',

  // Used for light grey text
  lightGrey: '#727481',
  border: '#E6E6E6',

  inputBorder: '#979797',

  green: '#32C100',
  error: 'red',



  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};
