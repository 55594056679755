import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

const LinkingConfig: LinkingOptions = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: '',
      SearchOptions: 'landing',
      SearchResults: 'search',
      BusinessListings: 'listings',
      DentalDifference: 'dentaldifference',
      EditListing: 'edit',
      PrivacyPolicy: 'privacy',
      TermsOfService: 'terms',
      FAQ: 'faq',
      NotFound: '*',

      Admin: {
        path: 'admin',
        screens: {
          // Dashboard: '',
          Config: 'config',
          Users: 'users',
          Businesses: 'businesses',
          NotFound: '*'
        }
      },
    },
  },
};


export default LinkingConfig