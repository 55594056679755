import { FontAwesome5 } from "@expo/vector-icons"
import React from "react"
import { View, ViewProps, StyleSheet } from "react-native"
import { RegularText } from "../StyledText"
import Colors from "../../constants/Colors";

interface RatingsProps extends ViewProps {
    count: number;
    rating: number;
}

export const Ratings: (props: RatingsProps) => JSX.Element = ({ count, rating, style }) => {

    const Star = ({ idx }: {idx: number}) => <FontAwesome5 name='star' solid={rating > idx} size={20} color={rating > idx ? 'orange' : Colors.border} />

    return (
        <View style={[styles.container, style]}>
            <Star idx={0} />
            <Star idx={1} />
            <Star idx={2} />
            <Star idx={3} />
            <Star idx={4} />
            <RegularText fontSize={16}>{count}</RegularText>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
    }
})