import { FontAwesome5 } from "@expo/vector-icons"
import React from "react"
import { TouchableHighlight, View } from "react-native"
import { RegularText } from "../StyledText"
import events from '../../helpers/GoogleAnalytics'

interface LinkItemProps {
    icon?: string;
    text: string;
    color?: string;
    action: (val: string) => void
    eventLabel: string
}

export const LinkItem = ({ icon, text, color, action, eventLabel }: LinkItemProps) => {
        
    return (
        <TouchableHighlight 
            onPress={() => {
                action(text)
                events.sendButtonClicked(`Link Item - ${eventLabel}`, 'link')
            }} 
            underlayColor='white'>
            <View style={{
                    flexDirection: 'row',
                    marginBottom: 16,
                    marginLeft: 4
            }}>
                {
                    icon 
                    && 
                        (<FontAwesome5 
                            style={{ marginRight: 12 }} 
                            name={icon} color={color || 'black'} 
                            size={18}>
                        </FontAwesome5>)
                }
                <RegularText fontSize={14} style={{color: color || 'black'}}>{text}</RegularText>
            </View>
        </TouchableHighlight>
    )
}