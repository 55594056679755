import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { SubHeader } from '../components/FAQ';
import HelperPageLayout from '../components/HelperPageLayout';
import { RegularText } from '../components/StyledText';


export default function TermsOfService() {
  return (
      <HelperPageLayout header="TERMS AND CONDITIONS OF USE">
        <View>
          <SubHeader headerText="PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS SITE." />
          <RegularText style={styles.bodyText}>
            By using this site, you give your consent to these Terms and Conditions. If you do not agree to all of these Terms and Conditions of use, do not use this site.
          </RegularText>
          <RegularText style={styles.bodyText}>
            WellifyMe, Inc. (“WellifyME”) reserves the right, at its discretion, to change, modify, add, or remove portions of these terms and conditions of use at any time. Please check these terms and conditions periodically for changes. Your continued usage of the WellifyME website (the “Site”) means that you accept such changes.
          </RegularText>

          <SubHeader headerText="General:" />
          <RegularText style={styles.bodyText}>
                  WellifyME may use video, images, graphics, text, or other formats on this Site (the “Content”), which is for informational purposes only. WellifyME does not recommend or endorse any specific dental or other healthcare providers, products, procedures, opinions, or other information that may be mentioned on this Site. Reliance on any information provided by WellifyME, WellifyME employees, others appearing on the Site at the invitation of WellifyME, or other visitors to the Site, is solely at your own risk.

            This Site is intended for use by persons over the age of eighteen (18). No individual under the age of thirteen (13) may use the Site, provide any personal information to WellifyME, or otherwise submit personal information through the Site (including, for example, a name, address, telephone number, or email address). You may only use the Site if you can form a binding contract with WellifyME and are not legally prohibited from using the Site.

            This Site Does Not Provide Medical Advice:

            The Content contained in this Site is intended to provide accurate and helpful information for those business or individuals seeking alternatives to traditional employer based dental or vision benefits. The Site is made available with the understanding that the author and publisher are not providing medical, health, psychological, or any other kind of professional services on the Site. The information should not be considered comprehensive and does not cover all diseases, ailments, and physical conditions, or their treatment.

            The information regarding heath care services on this Site is general in nature. It does not cover all possible treatments, complications, or side effects of the services mentioned.

            Always seek the advice of your qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this Site!
          </RegularText>

          <SubHeader headerText="No Liability" />
          <RegularText style={styles.bodyText}>
            THE CONTENT IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WELLIFYME AND THE OPERATORS OF THIS SITE, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.

            WellifyME and the operator(s) of this Site specifically disclaim all liability, loss, or risk, personal or otherwise, incurred as a consequence, directly or indirectly, of the use and application of this Site or any of the Content. In no event shall WellifyME, its content providers, its suppliers, or any third parties mentioned on the Site be liable for any claims or damages (including, without limitation, direct, incidental and consequential damages, personal injury/wrongful death, lost profits, or other damages resulting from lost data or business interruption) resulting from the use of or inability to use the Site or the Content, whether based on warranty, contract, tort, or any other legal theory, and whether or not WellifyME is advised of the possibility of such damages. WellifyME is not liable for any personal injury, including death, caused by the use or misuse of the Site or its Content.

            WellifyME reserves the right to take actions it deems necessary to protect the personal safety of its users, sponsors, employees and staff, and the integrity of its systems and sites, to include, without limitation, terminating or suspending your access to the Site. WellifyME has no liability or responsibility to users of the Site or any other person or entity for performance or nonperformance of the aforementioned activities.
          </RegularText>

          <SubHeader headerText="Indemnity" />
          <RegularText style={styles.bodyText}>
            You agree to defend, indemnify, and hold WellifyME, its officers, directors, employees, and agents harmless from and against any claims, actions or demands, liabilities and settlements including, without limitation, reasonable attorneys’ fees, resulting from, or alleged to result from, your violation of these Terms and Conditions.
          </RegularText>
          
          <SubHeader headerText="Intellectual Property" />
          <RegularText style={styles.bodyText}>
            Unless otherwise designated, all Site Content, including, without limitation, all text, editorial content, images, graphics, illustrations, photographs, video, audio, and other materials, as well as the designs, layout, and all other graphical elements, and all software of this Site and all intellectual property rights in any of the foregoing are the sole and exclusive property of WellifyME.

            WellifyME authorizes you to view or download a single copy of the material on the Site solely for your personal, noncommercial use if you include the following copyright notice: “WellifyME, LLC

            Copyright ©2020. All rights reserved” and other copyright and proprietary rights notices that are contained in the Content. Any special rules for the use of certain software and other items accessible on the Site may be included elsewhere within the Site and are incorporated into these Terms and Conditions by reference.

            The Content is protected by copyright under both United States and foreign laws. Title to the Content remains with WellifyME. Any use of the Content not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may violate copyright, trademark, and other laws. Content and features are subject to change or termination without notice in the editorial discretion of WellifyME. All rights not expressly granted herein are reserved to WellifyME.

            If you violate any of these Terms and Conditions, your permission to use the Content automatically terminates and you must immediately destroy any copies you have made of any portion of the Content.
          </RegularText>

          <SubHeader headerText="Digital Millennium Copyright Act; Copyright Agent" />
          <RegularText style={styles.bodyText}>
            WellifyME complies with the provisions of the Digital Millennium Copyright Act (DMCA). If you believe any materials accessible on or from the Site infringe your copyright, you may request removal of those materials (or access thereto) from this Site by contacting WellifyME’s copyright agent (identified below) and providing the following information:
          </RegularText>
          <RegularText style={styles.bodyText}>
            · Identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work.
          </RegularText>
          <RegularText style={styles.bodyText}>
            · Identification of the material that you believe to be infringing and its location. Please describe the material, and provide us with its URL or any other pertinent information that will allow us to locate the material.
          </RegularText>
          <RegularText style={styles.bodyText}>
            · Your name, address, telephone number and (if available) e-mail address.
          </RegularText>
          <RegularText style={styles.bodyText}>
            · A statement that you have a good faith belief that the complained of use of the materials is not authorized by the copyright owner, its agent, or the law.
          </RegularText>
          <RegularText style={styles.bodyText}>
            · A statement that the information that you have supplied is accurate, and indicating that “under penalty of perjury,” you are the copyright owner or are authorized to act on the copyright owner’s behalf.
          </RegularText>
          <RegularText style={styles.bodyText}>
            · A signature or the electronic equivalent from the copyright holder or authorized representative.
          </RegularText>

          <SubHeader headerText="WellifyME’s copyright agent for issues relating to this Site is as follows:" />
          <RegularText style={styles.bodyText}>
            WellifyME, LLC
          </RegularText>
          <RegularText style={styles.bodyText}>
            Attn: support@wellifyme.com
          </RegularText>
          <RegularText style={styles.bodyText}>
            In an effort to protect the rights of copyright owners, WellifyME may terminate, in appropriate circumstances, Site subscribers and account holders that repeatedly infringe.
          </RegularText>

          <SubHeader headerText="Miscellaneous:" />
          <RegularText style={styles.bodyText}>
            This Agreement shall be governed in all respects by the laws of the State of Tennessee as they apply to agreements entered into and to be performed entirely within Tennessee between Tennessee residents, without regard to conflict of law provisions. You agree that any claim or dispute you may have against WellifyME must be resolved exclusively by a state court located in Williamson County, Tennesee or a federal court in Davidson County, Tennessee. Except as expressly provided in a particular “legal notice” on the Site, these Terms and Conditions constitute the entire agreement between you and WellifyME with respect to the use of the Site, and Content. This Agreement replaces all prior or contemporaneous understandings or agreements, written or oral, regarding the subject matter hereof. The failure of either party to exercise, any right provided for herein shall not be deemed a waiver of any further rights hereunder. If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain enforceable and in full force and effect. You may not assign, transfer or sublicense this Agreement except with WellifyME’s prior written consent. WellifyME may assign this Agreement in whole or in part at any time without your consent. No agency, partnership, joint venture, or employment is created as a result of this Agreement and you may not bind WellifyME in any respect whatsoever. Any notice to WellifyME that is required or permitted by this Agreement shall be in writing and delivered by email to support@wellifyme.com.
          </RegularText>
          <SubHeader headerText="WellifyME, LLC Copyright © 2020" />
        </View>
      </HelperPageLayout>

  );
}

const styles = StyleSheet.create({
  headerText: {
    fontWeight: 'bold',
    marginTop: 19,
  },
  subtext: {
  },
  bodyText: {
    marginVertical: 6,
  }
});

