import React from 'react'
import { PropsWithChildren } from 'react'
import { useWindowDimensions, ViewProps } from 'react-native'
import Layout from '../constants/Layout'



interface MediaQueryContextInterface {
    width: number;
    height: number;

    containerWidth: number;

    screenSize: ScreenSize
}


export enum ScreenSize {
    phone = 0,  // < 475
    tablet = 1, // 476 - 768
    large = 2,  // 769 - 1000
    jumbo = 3   // 1001+
}


export const MediaQueryContext = React.createContext<MediaQueryContextInterface>({
    width: 1000,
    height: 1000,

    containerWidth: 1000,

    screenSize: ScreenSize.large
})


const screenSizeForWidth: (width: number) => ScreenSize = (width) => {
    switch (true) {
        case (width <= 475): return ScreenSize.phone
        case (width <= 768): return ScreenSize.tablet
        case (width <= 1000): return ScreenSize.large
        default: return ScreenSize.jumbo
    }
}


interface MediqQueryProviderProps extends PropsWithChildren<ViewProps> {

}

export const MediaQueryProvider = ({ children }: MediqQueryProviderProps) => {
    const { width, height } = useWindowDimensions()

    return (
        <MediaQueryContext.Provider value={{
            width,
            height,
            containerWidth: Math.min(width, Layout.maxContainerWidth) - (Layout.gutterWidth*2),
            screenSize: screenSizeForWidth(width)
        }} >
        {children}
        </MediaQueryContext.Provider>
    )
    
}