import * as React from 'react';
import { Text, TextProps, StyleSheet, ViewStyle, TextStyle, Animated } from 'react-native'
// import { Text, TextProps } from './Themed';
import {
  useFonts,
  IBMPlexSans_400Regular,
  IBMPlexSans_500Medium,
  IBMPlexSans_400Regular_Italic,
} from '@expo-google-fonts/ibm-plex-sans';
import { MediaQueryContext, ScreenSize } from '../contexts/MediaQueryContext';


const fname: (val: number) => string | undefined = (val) => {
  return val.toString().split('/').pop()?.replace('.ttf', '')
}

interface IBMTextProps extends TextParams {
  font: number;
}


const fontScaleForSize: (size: ScreenSize) => number = (fontSize) => {
  switch (fontSize) {
    case ScreenSize.phone: return 0.8;
    default: return 1.0
  }
}

const IBMText = ({ fontSize, ...props}: IBMTextProps & Text['props']) => {
  // const [fontLoaded] = useFonts([props.font])
  const { screenSize } = React.useContext(MediaQueryContext)

  // if (!fontLoaded) { return null }

  return (
    <Animated.Text {...props} style={[props.style, {
      fontFamily: fname(props.font),
      fontSize: fontSize * fontScaleForSize(screenSize),
      
    }]} />
  )
}

export function MonoText(props: Text['props']) {
  return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}


interface TextParams {
  fontSize: number;
}

export const MediumText = (props: TextParams & Text['props'] & any) => {
  return <IBMText {...props} font={IBMPlexSans_500Medium} />
}

export const RegularText = (props: TextParams & Text['props'] & any) => {
  return <IBMText {...props} font={IBMPlexSans_400Regular} />
}
