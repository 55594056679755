import React, { useContext, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  ActivityIndicator,
  ViewProps,
  TouchableHighlight,
} from "react-native";
import Navbar from "../components/Navbar";
import { Feather, FontAwesome5 } from "@expo/vector-icons";
import { MediumText, RegularText } from "../components/StyledText";
import Colors from "../constants/Colors";
import MaxWidthContainer from "../components/MaxWidthContainer";
import StyledInput from "../components/StyledInput";
import { RouteProp, useNavigation } from "@react-navigation/native";
import SearchButton, { SearchButtonRef } from "../components/SearchButton";
import {
  defaultSortOptions,
  MatchSort,
  titleForMatchSort,
} from "../constants/Models";
import { RootStackParamList } from "../types";
import { DragSortableView } from "react-native-drag-sort";
import { MediaQueryContext } from "../contexts/MediaQueryContext";
import { ScrollContext } from "../contexts/ScrollContext";
import * as Location from "expo-location";
import backend from "../backend/backend";
import { AuthContext } from "../contexts/AuthContext";
import { useEffect } from "react";
import events from '../helpers/GoogleAnalytics'

// interface SortOption {
//     id: string;
//     text: string;
// }

// const availableSortOptions: SortOption[] = [
//     {id: 'price', text: 'Price'},
//     {id: 'location', text: 'Location'},
//     {id: 'reviews', text: 'Reviews'},
//     {id: 'hours', text: 'Hours'},
//     {id: 'services', text: 'Service'}
// ]

const sortOptionsStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginBottom: 0,
    alignItems: "center",
  },

  indexText: {
    color: Colors.lightGrey,
    marginRight: 28,
  },

  title: {
    textAlign: "center",
    borderRadius: 8,
    flex: 1,
    padding: 24,
    backgroundColor: Colors.border,
  },
});

interface SortOptionProps extends ViewProps {
  option: MatchSort;
  index: number;
  width: number;
}

const SortOption = ({ option, index, width, ...props }: SortOptionProps) => {
  return (
    <View {...props} style={[sortOptionsStyles.container, { width }]}>
      <MediumText fontSize={22} style={sortOptionsStyles.indexText}>
        {index}
      </MediumText>

      <RegularText fontSize={20} style={sortOptionsStyles.title}>
        {titleForMatchSort(option)}
      </RegularText>
    </View>
  );
};

type SearchOptionsScreenProp = RouteProp<RootStackParamList, "SearchOptions">;

type SearchOptionsProps = {
  route: SearchOptionsScreenProp;
};

export default (props: SearchOptionsProps) => {
  return (
    <MaxWidthContainer style={styles.container}>
      <InternalThing {...props} />
    </MaxWidthContainer>
  );
};

const InternalThing = ({ route }: SearchOptionsProps) => {
  const navigation = useNavigation();
  const { scrollEnabled, setScrollEnabled } = useContext(ScrollContext);
  const [matchSort, setMatchSort] = useState(
    (route.params && route.params.matchSort?.split("|")) || defaultSortOptions
  );
  const [query, setQuery] = useState(route.params.query || "");
  const { containerWidth } = useContext(MediaQueryContext);
  const searchRef = useRef<SearchButtonRef>(null);
  const [fetchingGeo, setFetchingGeo] = useState(false);
  const [geo, setGeo] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const { user } = React.useContext(AuthContext);
  const isDemoUser = user?.roles.includes("demo");

  useEffect(() => {
    if (isDemoUser) {
      tryCurrentLocation();
    }
  }, [user]);

  const tryCurrentLocation = async () => {
    try {
      let location = await Location;
      const perms = await Location.requestPermissionsAsync();
      console.log({ perms });
      if (perms.granted) {
        setFetchingGeo(true);
        let pos = await Location.getLastKnownPositionAsync({ requiredAccuracy: Location.Accuracy.Lowest });
        if (!pos) {
          pos = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Lowest });
        }

        // TODO: Turn lat/lng into zip
        if (pos) {
          const {
            coords: { latitude, longitude },
          } = pos;
          console.log(pos.coords);
          setGeo({ longitude, latitude });
          await backend.updateProfile({ location: { latitude, longitude } });
          const result = await backend.reverseGeoCode(latitude, longitude);
          setQuery(result.postalCode);
        }
      } else if (perms.status === "undetermined") {
        alert(
          "Couldn't determine location. Please check your system configuration and try again."
        );
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setFetchingGeo(false);
      events.sendButtonClicked('Search Options - Try Current Location')
    }
  };

  const maxContainer = Math.min(containerWidth, 550);
  return (
    <>
      <Navbar />
      <View style={styles.pageContainer}>
        <View style={styles.headerContainer}>
          <FontAwesome5
            style={{ marginRight: 12 }}
            name="sort-amount-down"
            size={24}
            color="black"
          />
          <RegularText style={{ marginRight: 8 }} fontSize={24}>
            What's most important to you?
          </RegularText>
          <RegularText fontSize={24} style={{ color: Colors.lightGrey }}>
            Drag to rank order.
          </RegularText>
        </View>
        <View style={styles.orderingContainer}>
          <RegularText
            fontSize={16}
            style={{
              color: Colors.lightGrey,
              textAlign: "center",
              marginVertical: 32,
            }}
          >
            Most Important
          </RegularText>

          <DragSortableView
            dataSource={matchSort}
            parentWidth={maxContainer}
            onDragStart={() => setScrollEnabled(false)}
            onDragEnd={() => setScrollEnabled(true)}
            childrenHeight={90}
            childrenWidth={maxContainer}
            delayLongPress={0}
            scaleDuration={0}
            isDragFreely={false}
            onDataChange={(data) => {
              setMatchSort(data);
            }}
            keyExtractor={(itm) => itm}
            renderItem={(item, index) => (
              <SortOption
                width={maxContainer}
                option={item}
                index={index + 1}
              />
            )}
          />

          <RegularText
            fontSize={16}
            style={{
              color: Colors.lightGrey,
              textAlign: "center",
              marginVertical: 32,
            }}
          >
            Least Important
          </RegularText>
        </View>

        <View style={styles.footerContainer}>
          <RegularText style={{ marginBottom: 24 }} fontSize={24}>
            <FontAwesome5
              style={{ marginRight: 12 }}
              name="map-marker-alt"
              size={24}
              color="black"
            />
            Your location
          </RegularText>

          {isDemoUser ? (
            geo === null ? (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingVertical: 8,
                }}
              >
                <ActivityIndicator
                  style={{ marginRight: 8 }}
                  size="small"
                  color={Colors.inputBorder}
                />
                <RegularText
                  style={{ color: Colors.inputBorder }}
                  fontSize={18}
                >
                  Getting Your Location...
                </RegularText>
              </View>
            ) : (
              <RegularText
                style={{ color: Colors.inputBorder, textAlign: "center" }}
                fontSize={18}
              >
                {query}
              </RegularText>
            )
          ) : (
            <>
              <StyledInput
                onChangeText={setQuery}
                onEnter={() => searchRef.current?.onSearchPress()}
                value={query}
                placeholder="Enter City, State or Zip Code"
              >
                <Feather
                  style={{ position: "absolute", right: 24, top: 24 }}
                  name="navigation"
                  filled
                  size={24}
                  color="#ccc"
                />
              </StyledInput>
              <TouchableHighlight
                onPress={tryCurrentLocation}
                underlayColor="transparent"
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingVertical: 8,
                  }}
                >
                  {fetchingGeo && (
                    <ActivityIndicator
                      style={{ marginRight: 8 }}
                      size="small"
                      color={Colors.inputBorder}
                    />
                  )}
                  <RegularText
                    style={{ color: Colors.inputBorder }}
                    fontSize={18}
                  >
                    {fetchingGeo
                      ? "Getting Location..."
                      : "Use your current location"}
                  </RegularText>
                </View>
              </TouchableHighlight>
            </>
          )}

          <SearchButton
            label="Search Options"
            disabled={isDemoUser && !geo ? true : fetchingGeo}
            ref={searchRef}
            geo={{ attempt: false, coords: geo || undefined }}
            matchSortOptions={matchSort as MatchSort[]}
            query={query}
            style={styles.searchButton}
          />
          <View style={{ height: 20 }} />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  pageContainer: {
    // flex: 1,
    height: "100%",
    paddingVertical: 40,
    paddingHorizontal: 0,
    alignItems: "center",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
  },

  footerContainer: {
    flex: 1,
    width: "100%",
    maxWidth: 500,
  },

  searchButton: {
    marginTop: 40,
    alignSelf: "center",
    marginBottom: "40px", // at some point we will probably want to tweak how things are positioned so this value is respected.
  },
  orderingContainer: {
    position: "relative",
    width: "100%",
    alignItems: "center",
  },
});
