/**
 * Screen for editing a given listing.
 * 
 * Available to: admin|business roles
 */
import { FontAwesome5 } from '@expo/vector-icons'
import Slider from '@react-native-community/slider'
import { RouteProp, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useContext, useEffect, useState } from 'react'
import { ActivityIndicator, Alert, StyleSheet, Text, TouchableHighlight, View, ViewProps } from 'react-native'
import backend from '../backend/backend'
import LoadingComponent from '../components/LoadingComponent'
import MaxWidthContainer from '../components/MaxWidthContainer'
import Navbar from '../components/Navbar'
import RoundyButton from '../components/RoundyButton'
import StyledInput from '../components/StyledInput'
import { MediumText, RegularText } from '../components/StyledText'
import TextInputWrapper from '../components/TextInputWrapper'
import Colors from '../constants/Colors'
import { ClaimedBusinessItem, Config, PlanFee, ServiceItem } from '../constants/Models'
import { AuthContext } from '../contexts/AuthContext'
import { MediaQueryContext, ScreenSize } from '../contexts/MediaQueryContext'
import { RootStackParamList } from '../types'
import AdditionalServiceFees from './Editing/AdditionalServiceFees'
import OptionalServiceFees from './Editing/OptionalServiceFees'
import USDTextInputWrapper from './Editing/USDTextInputWrapper'
import events from '../helpers/GoogleAnalytics'

const styles = StyleSheet.create({
    bottomMarg: { marginBottom: 12 },
    bottomMarg2: { marginBottom: 24 },
    // formGroup: { 
    //     // flexBasis: "50%",
    //     // display: "flex", 
    //     // flexDirection: "column"
    // },
    // formPair: {
    //     // display: "flex", 
    //     // gap: "12px"
    // },
    inputPadding: {
        paddingVertical: 10,
        paddingHorizontal: 18
    },

    sectionPadding: {
        marginVertical: 24
    },
    widthAuto: {
        width: '75%',
        height: '50%'
    }
})


type EditListingsRouteProp = RouteProp<RootStackParamList, 'EditListing'>

type EditListingsProps = {
    navigation: StackNavigationProp<RootStackParamList>,
    route: EditListingsRouteProp
}

export default ({ navigation, route }: EditListingsProps) => {
    const [item, setItem] = useState<ClaimedBusinessItem|null>(null)
    const [config, setConfig] = useState<Config|null>(null)


    useEffect(() => {
        const loadData = async () => {
            try {
                if (route.params.id && route.params.id.length > 0) {
                    
                }
                setItem(await backend.getListing(route.params.id))
                setConfig((await backend.getInfo()).config)
            }
            catch (ex) {
                console.error(ex)
                navigation.goBack()
            }
        }

        loadData()
    }, [route.params.id])

    return (
        <MaxWidthContainer style={{flexDirection: 'column', backgroundColor: Colors.white}}>
            <Navbar />
            
            {
                (item && config) ? <EditListingScreen key={ item.name } config={config} item={item} /> : <LoadingComponent />
            }


        </MaxWidthContainer>
    )
}

interface EditListingScreenProps {
    item: ClaimedBusinessItem;
    config: Config
} 

const EmptyPlan: PlanFee = { name: '', annually: 0, monthly: 0, deposit: 0 }

const EditListingScreen = ({ item, config }: EditListingScreenProps) => {
    const navigation = useNavigation()
    const { user } = useContext(AuthContext)
    const { screenSize } = useContext(MediaQueryContext)
    const [scratchItem, setScratchItem] = useState<ClaimedBusinessItem>(item)
    const fullItem = item as ClaimedBusinessItem
    // NOTE: Temporarily disable until further instructions
    const [inOfficePlan, setInOfficePlan] = useState(fullItem.pricing.inOfficePlan)
    const [inOfficePlans, setInOfficePlans] = useState<any>(fullItem.pricing)
    const [optionalPlans, setOptionalPlans] = useState<any>(fullItem.pricing.plans || [])
    const [details, setDetails] = useState(item.details || '')
    const [email, setEmail] = useState(fullItem.contact.email || '')
    const [address, setAddress] = useState({
        line1: fullItem.contact.address.line1,
        line2: fullItem.contact.address.line2 ?? "",
        city: fullItem.contact.address.city,
        state: fullItem.contact.address.state,
        postalCode: fullItem.contact.address.postalCode
    })
    const [name, setName] = useState(item.name || '')
    const [phone, setPhone] = useState(fullItem.contact.phone || '')
    const [url, setUrl] = useState(fullItem.contact.website || '')
    const [isSaving, setSaving] = useState(false)

    const pricingPlanTypes = [
        'adult',
        'perio',
        'child',
    ];

    useEffect(() => {
        setScratchItem({
            ...scratchItem,
            name,
            contact:{
               phone,
               email,
               website: url,
               address: {
                   ...address
               }
            },
            details,
            pricing: {
                inOfficePlan,
                ...inOfficePlans,
                plans: optionalPlans
            }
        })
    }, [inOfficePlans, optionalPlans, details, address, url, email, phone, name])

    ////////// Actions
    const viewListing = () => {
        events.sendButtonClicked('Edit Listing - View Listing')
    }

    const cancel = () => {
        /// TODO: Are you sure?
        // TODO: navigate to root on refresh then cancel (currently broken)
        navigation.goBack()
        events.sendButtonClicked('Edit Listing - Cancel', 'link')

    }

    const saveChanges = async () => {
        setSaving(true)


        try {
            const result = await backend.updateListing(fullItem._id || '', scratchItem)
        } 
        catch (ex) {
            Alert.alert('Error', `There was an error saving your changes.  ${(ex as Error).message}`, [{text: 'OK'}])
        }

        setSaving(false)
        navigation.goBack()
    }
    

    return (
        <>
            <RegularText style={[styles.bottomMarg, { paddingTop: 48 }]} fontSize={24}>Manage your listing for: {item.name}</RegularText>
            <RegularText style={[styles.bottomMarg2, {color: Colors.lightGrey}]}>
                Providing more information increases your ranking power on MyDentist Match search results.
            </RegularText>
            <MediumText style={styles.bottomMarg } fontSize={26}>Contact info</MediumText>
            <div style={{marginBottom:12}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <MediumText style={styles.bottomMarg} fontSize={20}>Name</MediumText>
                    <StyledInput
                        style={[styles.inputPadding, styles.bottomMarg2]}
                        placeholder={'Name'}
                        numberOfLines={0} 
                        onChangeText={setName} 
                        value={name} />
                </div>
                <div style={{display: 'flex', gap: 12}} >
                <div style={{display: "flex", flexBasis: "50%", flexDirection: "column"}}>
                    <MediumText style={styles.bottomMarg} fontSize={20}>email</MediumText>
                    <StyledInput
                        style={[styles.inputPadding, styles.bottomMarg2]}
                        placeholder={'email@example.com'}
                        numberOfLines={0} 
                        onChangeText={setEmail} 
                        value={email} />
                </div>
                <div style={{display: "flex", flexBasis: "50%", flexDirection: "column"}}>
                    <MediumText style={styles.bottomMarg} fontSize={20}>Website</MediumText>
                    <StyledInput
                        style={[styles.inputPadding, styles.bottomMarg2]}
                        placeholder={'www.website.com'}
                        numberOfLines={0} 
                        onChangeText={setUrl} 
                        value={url} />
                </div>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <MediumText style={styles.bottomMarg} fontSize={20}>Line 1</MediumText>
                    <StyledInput
                        style={[styles.inputPadding, styles.bottomMarg2]}
                        placeholder={'Line 1'}
                        numberOfLines={0} 
                        onChangeText={(input) => setAddress({...address, line1: input }) } 
                        value={address.line1} />
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <MediumText style={styles.bottomMarg} fontSize={20}>Line 2</MediumText>
                    <StyledInput
                        style={[styles.inputPadding, styles.bottomMarg2]}
                        placeholder={'Line 2'}
                        numberOfLines={0} 
                        onChangeText={(input) => setAddress({...address, line2: input }) } 
                        value={address.line2} />
                </div>
                <div style={{display: 'flex', gap: 12}} >
                    <div style={{flexBasis: "33%", display: "flex", flexDirection: "column"}}>
                        <MediumText style={styles.bottomMarg} fontSize={20}>City</MediumText>
                        <StyledInput
                            style={[styles.inputPadding, styles.bottomMarg2]}
                            placeholder={'City'}
                            numberOfLines={0} 
                            onChangeText={(input) => setAddress({...address, city: input }) } 
                            value={address.city} />
                    </div>
                    <div style={{flexBasis: "33%", display: "flex", flexDirection: "column"}}>
                        <MediumText style={styles.bottomMarg} fontSize={20}>State</MediumText>
                        <StyledInput
                            style={[styles.inputPadding, styles.bottomMarg2]}
                            placeholder={'State'}
                            numberOfLines={0} 
                            onChangeText={(input) => setAddress({...address, state: input }) } 
                            value={address.state} />
                    </div>
                    <div style={{flexBasis: "33%", display: "flex", flexDirection: "column"}}>
                        <MediumText style={styles.bottomMarg} fontSize={20}>Postal Code</MediumText>
                        <StyledInput
                            style={[styles.inputPadding, styles.bottomMarg2]}
                            placeholder={'Postal code'}
                            numberOfLines={0} 
                            onChangeText={(input) => setAddress({...address, postalCode: input }) } 
                            value={address.postalCode} />
                    </div>
                </div>
            </div>
            <MediumText style={styles.bottomMarg} fontSize={20}>Description of business</MediumText>
            <StyledInput
                style={[styles.inputPadding, styles.bottomMarg2, {minHeight: 120}]}
                placeholder={'Description of business'}
                multiline 
                numberOfLines={0} 
                onChangeText={setDetails} 
                value={details} />

            {/* In Office Plan setup */}
            { /* TODO: Re-enable when inOffice plans is re-enabled */ }
            <MediumText style={styles.bottomMarg } fontSize={26}>In Office Plan Setup</MediumText>
            <View style={{flexDirection: screenSize > ScreenSize.tablet ? 'row' : 'column-reverse', justifyContent: 'space-between'}}>
                <View style={[styles.bottomMarg2, {flexDirection: 'column', flex: 1}]}>
                    <MediumText style={styles.bottomMarg} fontSize={18}>Do you have in-office plans?</MediumText>
                    <View style={[styles.bottomMarg2, {flexDirection: 'row'}]}>
                        <RadioOption style={{marginRight: 24}} checked={inOfficePlan} onChange={val => setInOfficePlan(true)} text={'Yes'} />
                        <RadioOption checked={inOfficePlan == false} onChange={val => setInOfficePlan(false) } text={'No'} />
                    </View>
                </View>
                {/* <ImageComponent /> */}
            </View>




            { /* In-office plans */
                inOfficePlan && pricingPlanTypes.map((planType, index) => {
                    const displayPlanType = `${planType.charAt(0).toUpperCase()}${planType.slice(1)}`
                    return (<View key={index} style={{ marginBottom: 50}}>
                        {(
                        <>
                            <MediumText style={styles.bottomMarg} fontSize={18}>{`${displayPlanType} In-Office Plan Name`}</MediumText>
                            <StyledInput
                                style={[styles.inputPadding, { width: '75%', height: '75%'}]}
                                placeholder='Name'
                                multiline 
                                numberOfLines={1} 
                                onChangeText={
                                    (name: string) => ( setInOfficePlans({ 
                                        ...inOfficePlans,
                                        [`${planType}InOfficePlan`]: {
                                            ...inOfficePlans[`${planType}InOfficePlan`],
                                            name,
                                        },
                                    }))
                                } 
                                value={inOfficePlans[`${planType}InOfficePlan`]?.name || ''} 
                            />
                            <AdultInOfficePlanFees 
                                onUpdate={
                                    (planFees) => setInOfficePlans({ 
                                        ...inOfficePlans,
                                        [`${planType}InOfficePlan`]: {
                                            ...inOfficePlans[`${planType}InOfficePlan`],
                                            ...planFees,
                                        },
                                    })
                                } 
                                fee={inOfficePlans[`${planType}InOfficePlan`]} 
                                planType={planType}
                            />
                        </>
                        )
                        }
                        <MediumText style={styles.bottomMarg} fontSize={18}>{`Description of ${displayPlanType} savings`}</MediumText>
                        <StyledInput
                            style={[styles.inputPadding, {minHeight: 120}]}
                            placeholder={`${displayPlanType} Savings Description`}
                            multiline 
                            numberOfLines={1} 
                           onChangeText={
                                (savingsDescription: string) => ( setInOfficePlans({ 
                                    ...inOfficePlans,
                                    [`${planType}InOfficePlan`]: {
                                        ...inOfficePlans[`${planType}InOfficePlan`],
                                        savingsDescription,
                                    },
                                }))
                            } 
                            value={inOfficePlans[`${planType}InOfficePlan`]?.savingsDescription || ''} 
                        />
                        <MediumText style={styles.bottomMarg} fontSize={18}>{`Description of ${displayPlanType} IOP includes`}</MediumText>
                        <StyledInput
                            style={[styles.inputPadding, styles.bottomMarg, {minHeight: 120}]}
                            placeholder={`${displayPlanType} IOP Description`}
                            multiline 
                            numberOfLines={1} 
                            onChangeText={
                                (iopDescription: string) => ( setInOfficePlans({ 
                                    ...inOfficePlans,
                                    [`${planType}InOfficePlan`]: {
                                        ...inOfficePlans[`${planType}InOfficePlan`],
                                        iopDescription,
                                    },
                                }))
                            } 
                            value={inOfficePlans[`${planType}InOfficePlan`]?.iopDescription || ''} 
                        />
                        <MediumText style={styles.bottomMarg} fontSize={18}>{`${displayPlanType} IOP link`}</MediumText>
                        <StyledInput
                            style={[styles.inputPadding, { width: '75%', height: '75%'}]}
                            placeholder='IOP Link'
                            multiline 
                            numberOfLines={1} 
                            onChangeText={
                                (iopLink: string) => ( setInOfficePlans({ 
                                    ...inOfficePlans,
                                    [`${planType}InOfficePlan`]: {
                                        ...inOfficePlans[`${planType}InOfficePlan`],
                                        iopLink,
                                    },
                                }))
                            } 
                            value={inOfficePlans[`${planType}InOfficePlan`]?.iopLink || ''} 
                        />
                    </View>
                )})
            }
            <OptionalServiceFees style={styles.sectionPadding} optionalPlans={optionalPlans} onChange={setOptionalPlans} />

            <MediumText style={styles.bottomMarg} fontSize={20}>Dental Services</MediumText>
            <ExtraServices services={config.generalServices} item={scratchItem as ClaimedBusinessItem} onUpdate={setScratchItem} />


            <AdditionalServiceFees availableService={config.optionalServices} item={scratchItem} onUpdate={setScratchItem} />


            { user?.roles.includes('admin') && <AdminExtras item={scratchItem} onUpdate={setScratchItem} />}
            

            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 40}}>

                <TouchableHighlight onPress={viewListing} underlayColor='white'>
                    <MediumText fontSize={18}>View your listing</MediumText>
                </TouchableHighlight>

                <View style={{flexDirection: 'row', alignItems: 'center'}}> 
                    <TouchableHighlight onPress={cancel} underlayColor='white' style={{marginRight: 24}}>
                        <MediumText fontSize={18} style={{color: Colors.lightGrey}}>Cancel</MediumText>
                    </TouchableHighlight>
                    <RoundyButton eventLabel="Edit Listing - Save Listing" style={{paddingVertical: 12}} textStyle={{fontSize: 18}} onPress={saveChanges}>
                        {isSaving ? <ActivityIndicator color={'white'} /> : 'Save Listing'}
                    </RoundyButton>
                </View>
            </View>

        </>
    )
}

interface ExtraServiceProps {
    services: ServiceItem[];
    item: ClaimedBusinessItem;
    onUpdate: (item: ClaimedBusinessItem) => void;
}
const ExtraServices = ({ services, item, onUpdate }: ExtraServiceProps) => {

    const serviceItem = (itm: ServiceItem) => {


        const onChange = (val: string) => {
            onUpdate({
                ...item,
                regularServices: {
                    ...item.regularServices,
                    [itm.key]: val
                }
            })
        }

        const itmVal = item.regularServices && item.regularServices[itm.key]
        const itmValString = (itmVal && `${itmVal}`) || ''

        return (
            <USDTextInputWrapper 
                style={{
                    marginBottom: 12,
                    flexDirection: 'row', 
                    justifyContent: 'space-between'}}
                key={itm.key}
                onChangeText={onChange}
                label={itm.label}
                value={itmValString}
                placeholder={'$0.00'} 
            />
        )
    }
    return (
        <View style={{flexDirection: 'column'}}>
            { services.map(serviceItem) }
        </View>
    )
}

interface RadioOptionProps extends ViewProps {
    text: string;
    checked: boolean;
    onChange: (val: boolean) => void;
}
export const RadioOption = (props: RadioOptionProps) => {
    const checked = props.checked
    const setChecked = (val: boolean) => {
        props.onChange(val)
    }
    const onToggle = () => {
        const update = !checked
        setChecked(update)
    }
    
    return (
        <TouchableHighlight style={[props.style, {flexDirection: 'row'}]} onPress={onToggle} underlayColor={'transparent'}>
            <>
                {
                    checked ? 
                    <FontAwesome5 name='check-circle' filled size={18} /> :
                    <View style={{width: 18, height: 18, borderWidth: 2, borderRadius: 9, borderColor: 'black'}} />
                }
                <RegularText style={{marginLeft: 12}} size={18}>{props.text}</RegularText>
            </>
        </TouchableHighlight>
    )
}


interface GoogleBusinessInfoProps extends ViewProps { }
const GoogleBusinessInfo = ({ style }: GoogleBusinessInfoProps) => {

    return (
        <View style={[style, {minHeight: 100, backgroundColor: '#ccc'}]}>
            <Text>Google Business Info</Text>
        </View>
    )
}

const ImageComponent = () => {

    return (
        <View style={{flexDirection: 'column', maxHeight: 300, minWidth: 200, maxWidth: 300}}>
            <MediumText fontSize={24}>Update your profile image</MediumText>
            <View style={{backgroundColor: '#ccc', flex: 1, minHeight: 200}}>

            </View>
        </View>
    )
}



interface AdultInOfficePlanFeeProps {
    fee: PlanFee;
    planType: string;
    onUpdate: (val: PlanFee) => void;
}

const inOfficePlanStyles = StyleSheet.create({
    inputContainer: {
        flexDirection: 'row',
        flex: 1
    },
    input: {
        flexDirection: 'column',
        flex: 1,
        maxWidth: 130,
        marginRight: 12,

    }
})

const numToUSD = (val?: number | null): string => {
    if (!val) { return "0" }
    return `${val.toFixed(2)}`
}

const AdultInOfficePlanFees = (props: AdultInOfficePlanFeeProps) => {
    const [annually, setAnnually] = useState(numToUSD(props.fee.annually))
    const [monthly, setMonthly] = useState(numToUSD(props.fee.monthly))
    const [deposit, setDeposit] = useState(numToUSD(props.fee.deposit))


    useEffect(() => {
        console.log(`Prepping change`)
        const timeout = setTimeout(() => {
            console.log(`props on update`)
            props.onUpdate({
                ...props.fee,
                annually: parseFloat(annually),
                monthly: parseFloat(monthly),
                deposit: parseFloat(deposit)
            })
        }, 750)

        return () => {
            clearTimeout(timeout)
        }

    }, [ annually, monthly, deposit ])


    return (
        <View>
            <MediumText fontSize={18}>
                {`${props.planType} In-Office Plan Fees`}
            </MediumText>

            <View style={[inOfficePlanStyles.inputContainer, styles.bottomMarg]}>
                <TextInputWrapper value={annually} onChangeText={setAnnually} style={inOfficePlanStyles.input} label="Annual Price" placeholder="$00.00" />
                <TextInputWrapper value={monthly} onChangeText={setMonthly} style={inOfficePlanStyles.input} label="Monthly Price" placeholder="$00.00" />
                <TextInputWrapper value={deposit} onChangeText={setDeposit} style={inOfficePlanStyles.input} label="Downpayment" placeholder="$00.00" />
            </View>
        </View>
    )
}



interface AdminExtrasProps {
    item: ClaimedBusinessItem
    onUpdate: (updates: ClaimedBusinessItem) => void;
}

const AdminExtras = ({ item, onUpdate }: AdminExtrasProps) => {
    const [bonus, setBonus] = useState(item.meta?.matchBonus || 0)

    useEffect(() => {
        if (bonus == (item.meta?.matchBonus || 0)) return;

        /// Prepare to update
        const timer = setTimeout(() => {
            console.log(`run it`)
            onUpdate({
                ...item,
                meta: {
                    ...item.meta,
                    matchBonus: bonus
                }
            })
        }, 500)
    }, [bonus])

    return (
        <View>
            <RegularText>
                Bonus Match %: {bonus}
            </RegularText>
            <Slider value={bonus} minimumValue={0} step={1} maximumValue={100} onValueChange={setBonus}></Slider>
        </View>
    )
}