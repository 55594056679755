import * as React from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import {  MediumText, RegularText } from '../components/StyledText';
import { Link } from '@react-navigation/native';
import { FontAwesome5 } from '@expo/vector-icons';
import MaxWidthContainer from '../components/MaxWidthContainer';

interface HelperPageLayoutProps {
    children: JSX.Element;
    header: string;
}

export default function HelperPageLayout({children, header}: HelperPageLayoutProps) {
  return (
    <MaxWidthContainer>
        <View >
          <Link to="/">
            <TouchableOpacity style={{flexDirection: 'row', paddingVertical: 12, justifyContent: 'flex-start', alignItems: 'center'}}>
              <FontAwesome5 name='arrow-left' size={12} style={{marginRight: 12, color: '#2e78b7'}} />
              <RegularText style={styles.linkText}>Back</RegularText>
            </TouchableOpacity>
          </Link>
          <View style={{flex: 1}} />
        </View>
        <MediumText fontSize={ 20 } style={styles.headerText}>
          { header }
        </MediumText>
        <View >
            { children }
        </View>
        <View style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/">
            <TouchableOpacity  style={styles.link}>
              <Text style={styles.linkText}>Go back</Text>
            </TouchableOpacity>
          </Link>
        </View>
    </MaxWidthContainer>
  );
}

const styles = StyleSheet.create({
    headerText: {
        fontWeight: 'bold',
        marginVertical: 20,
      },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: '#2e78b7',
    },
  });