import { useNavigation } from "@react-navigation/native"
import React, { useContext, useEffect, useState } from "react"
import { View, Text, StyleSheet } from "react-native"
import LoadingComponent from "../components/LoadingComponent"
import { AuthContext } from "../contexts/AuthContext"
import Navigation from "../navigation"
import Auth from "./Auth"





export default () => {
    const { user, auth, bootstrap } = useContext(AuthContext)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        bootstrap().finally(() => setLoading(false))
    }, [])


    if (loading) {
        return (
            
            <View style={{flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <LoadingComponent text={"Loading"} />
            </View>
        )
    }

    return (
        <Navigation />
    )
}