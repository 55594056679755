import React, { useEffect, useState } from "react"
import { View } from "react-native"
import FormInput from "./FormInput";
import RoundyButton from "./RoundyButton";
import { MediumText, RegularText } from "./StyledText";
import { WebView } from 'react-native-web-webview';

type OfficeReferral = {
    firstName: string;
    officeName: string; 
    phone: string;
    email: string;
    zip: string;
    sourceEmail: string;
    sourceName: string;
    note: string;
};

type RequestOfficeProps = {
    setWayToSave: Function;
}

export default ({ setWayToSave }: RequestOfficeProps)  => {
    const [officeReferral, setOfficeReferral] = useState<OfficeReferral>({
        firstName: '',
        officeName: '',
        phone: '',
        email: '',
        zip: '',
        sourceEmail: '',
        sourceName: '',
        note: '',
    });
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [onLoadCount, setOnLoadCount] = useState<number>(0);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (hasSubmitted && onLoadCount >= 3) {
            setHasSubmitted(false);
            setWayToSave(false);
            setSubmitSuccess(true);
        }
    }, [hasSubmitted, onLoadCount]);
    if (submitSuccess) {
        return (
            <View style={{ display: 'flex', alignItems: 'center' }}>
                <View style={{ flexDirection: 'column', marginRight: 40, marginBottom: 20 }}>
                    <MediumText style={{ marginBottom: 20, marginTop: 10 }}>Thank you for referring this dental office to us. We will reach out to them shortly and make them aware of options available they can use to assist their patients. Please remember that you can use your benefit dollars at any dental office including your current office. If they accept credit card payments, you are good to go!</MediumText>
                </View>
            </View>
        )
    }
    if (hasSubmitted) {
        return (
            <View style={{ height: 500, width: '100%' }}>
                <WebView
                    onNavigationStateChange={(e: any) => console.log(e)}
                    onLoad={() => setOnLoadCount(onLoadCount + 1)}
                    originWhitelist={['*']}
                    source={{ html: 
                        `<html>
                            <body onload="document.forms[0].submit();">
                                <form action="https://service.capsulecrm.com/service/newlead" accept-charset="UTF-8" method="post">
                                    <input type="hidden" name="FORM_ID" value="bda93bdf-fefe-4cd6-aa36-08bf30f4b33a">
                                    <input type="hidden" name="COMPLETE_URL" value="https://www.mydentistmatch.com/">
                                    <input type="hidden" name="TAG" value="Employee">
                                    <input type="hidden" name="TAG" value="Lead">
                                    <input type="hidden" name="TAG" value="Employee Referral to QDP">
                                    <input type="hidden" name="FIRST_NAME" value="${officeReferral.firstName}">
                                    <input type="hidden" name="ORGANISATION_NAME" value="${officeReferral.officeName}">
                                    <input type="hidden" name="PHONE" value="${officeReferral.phone}">
                                    <input type="hidden" name="EMAIL" value="${officeReferral.email}">
                                    <input type="hidden" name="ZIP" value="${officeReferral.zip}">
                                    <input name="CUSTOMFIELD[SourceEmail]" type="hidden" id="yourEmail" value="${officeReferral.sourceEmail}">
                                    <input name="CUSTOMFIELD[Source]" type="hidden" value="${officeReferral.sourceName}">
                                    <input type="hidden" value="Submit">
                                </form> 
                            </body>
                        </html>`
                    }}
                />
            </View>
        )
    }
    return (
        <>
            <View style={{ display: 'flex', alignItems: 'center' }}>
                <MediumText fontSize={ 24 } style={{ marginBottom: 20, marginTop: 10 }}>Refer A Dentist</MediumText>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <View style={{ flexDirection: 'column', marginRight: 40, marginBottom: 20 }}>
                        <RegularText fontSize={ 16 } style={{ marginBottom: 10 }}>Dental Office's Info</RegularText>
                        <FormInput 
                            value={ officeReferral.firstName }
                            onChange={ (firstName: string) => setOfficeReferral(Object.assign({}, officeReferral, { firstName })) }
                            label="Dentist Name: " 
                            placeholder="Dentist Name"
                        />
                        <FormInput 
                            value={ officeReferral.officeName }
                            onChange={ (officeName: string) => setOfficeReferral(Object.assign({}, officeReferral, { officeName })) }
                            label="Office Name: " 
                            placeholder="Office Name"
                        />
                        <FormInput 
                            value={ officeReferral.phone }
                            onChange={ (phone: string) => setOfficeReferral(Object.assign({}, officeReferral, { phone })) }
                            label="Office Phone: " 
                            placeholder="Office Phone"
                        />
                        <FormInput 
                            value={ officeReferral.email }
                            onChange={ (email: string) => setOfficeReferral(Object.assign({}, officeReferral, { email })) }
                            label="Office Email: " 
                            placeholder="Office Email"
                        />
                        <FormInput 
                            value={ officeReferral.zip }
                            onChange={ (zip: string) => setOfficeReferral(Object.assign({}, officeReferral, { zip })) }
                            label="Zip: " 
                            placeholder="Zip"
                        />
                    </View>
                    <View style={{ flexDirection: 'column', marginRight: 40 }}>
                        <RegularText fontSize={ 16 } style={{ marginBottom: 10 }}>Your Info</RegularText>
                        <FormInput 
                            value={ officeReferral.sourceEmail }
                            onChange={ (email: string) => setOfficeReferral(Object.assign({}, officeReferral, { sourceEmail: email })) }
                            label="Email: " 
                            placeholder="Email"
                        />
                        <FormInput 
                            value={ officeReferral.sourceName }
                            onChange={ (name: string) => setOfficeReferral(Object.assign({}, officeReferral, { sourceName: name })) }
                            label="Name: " 
                            placeholder="Name"
                        />
                    </View>
                </View>
                { !hasSubmitted &&
                    <RoundyButton
                        eventLabel="Request Office - Submit"
                        style={{ marginTop: 20 }}
                        onPress={ () => { 
                            setHasSubmitted(true);
                        } }
                    >
                        Submit
                    </RoundyButton>
                }
            </View>
        </>
    )
}