import { FontAwesome5 } from "@expo/vector-icons"
import React from "react"
import { StyleSheet, TouchableHighlight, View, ViewProps } from "react-native"
import Colors from "../constants/Colors"
import events from '../helpers/GoogleAnalytics'

export interface PaginationProps extends ViewProps {
    hasMore: boolean;
    hasPrev: boolean;
    loadPrev: () => void;
    loadNext: () => void;
    label: string
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
})

export default ({ hasPrev, hasMore, loadPrev, loadNext, label }: PaginationProps) => {

    const onPressPrev = () => {
        loadPrev()
        events.sendButtonClicked(`Pagination Previous - ${label}`)
    }

    const onPressNext = () => {
        loadNext()
        events.sendButtonClicked(`Pagination Next - ${label}`)
    }
    return (
        <View style={styles.container}>
            {
                hasPrev ? (
                    <TouchableHighlight underlayColor='white' onPress={onPressPrev}>
                        <FontAwesome5 
                            size={18}
                            color={Colors.primary} 
                            name='arrow-left' />
                    </TouchableHighlight>
                ) : <View />
            }

            { hasMore && (
                <TouchableHighlight underlayColor='white' onPress={onPressNext}>
                    <FontAwesome5 
                        size={18}
                        color={Colors.primary} 
                        name='arrow-right' />
                </TouchableHighlight>
            )}
        </View>
    )
}