/** 
 * Screen for the business listings associated with a google my business account
 * 
 * Ref: https://marvelapp.com/prototype/f4gi7eh/screen/73387078
 */
import { Link, useNavigation } from '@react-navigation/native'
import React, { useContext, useEffect, useState } from 'react'
import { ListRenderItem, Text, View, FlatList, ActivityIndicator, Alert} from 'react-native'

import backend from '../backend/backend'
import BusinessListing from '../components/BusinessListing'
import MaxWidthContainer from '../components/MaxWidthContainer'
import Navbar from '../components/Navbar'
import { MediumText, RegularText } from '../components/StyledText'
import Colors from '../constants/Colors'
import { BusinessAccount, BusinessItem } from '../constants/Models'
import { AuthContext } from '../contexts/AuthContext'
import BusinessListingList from '../components/BusinessListingList'
import { APIError } from '../backend/_abstract'



export default () => {
    const { auth, logout } = useContext(AuthContext)
    const [accounts, setAccounts] = useState<BusinessAccount[]|null>(null)
    const [currentAccount, setCurrentAccount] = useState<BusinessAccount|null>(null)
    const [listings, setListings] = useState<BusinessItem[]|null>(null)
    const navigation = useNavigation()

    const handleSessionExpired = () => {
        // Alert.alert('Session Expired', "You're session has expired.  Please login again", [{
        //     text: 'OK', onPress: () => {
        //         navigation.navigate('Root')
        //     }}])
        alert(`You're session has expired`)
        logout()
        navigation.reset({
            index: 0,
            routes: [{ name: 'Root' }]
        })
    }

    useEffect(() => {
        if (!auth) { 
            const timeout = setTimeout(handleSessionExpired, 1000)
            return () => {
                clearTimeout(timeout)
            }
        }
        const asyncLoad = async () => {
            console.log(`Loading accounts`)
            try {
                setAccounts(await backend.businessAccounts())
            }
            catch (ex) {
                if ((ex as APIError).code == 401) {
                    handleSessionExpired()
                    return
                }

                setAccounts([])
                setListings([])
            }
        }

        asyncLoad()
    }, [auth])

    useEffect(() => {
        if (!accounts || accounts.length == 0) { return }

        setCurrentAccount(accounts[0])
    }, [accounts])

    useEffect(() => {
        if (!currentAccount) {
            setListings(null)
            return
        }
        console.log(`Loading listings ${currentAccount.name}`)


        loadListings()

    }, [currentAccount])

    const loadListings = async () => {
        if (!currentAccount) { return }
        try {
            setListings(await backend.businessLocations(currentAccount.accountId))
        }
        catch (ex) {
            console.error(ex)
            setListings([])
        }
    }


    ////////////// Actions
    const onClaim = async (item: BusinessItem) => {
        if (!currentAccount?.accountId) { return }
        if (!item.meta.google?.locationId) { return }
        try {
            const res = await backend.claimListing(currentAccount?.accountId, item.meta.google?.locationId)
            console.log(`Claimed: `)
            loadListings()
        }
        catch (ex) {
            console.error(`Failed to claim `, ex)
            alert(`Error claiming listing: ${ex.message}`)
        }
    }

    return (
        <MaxWidthContainer style={{flexDirection: 'column', backgroundColor: Colors.white}}>
            <Navbar />

            <View style={{minHeight: 300, paddingVertical: 20}}>
            {
                listings && listings.length > 0 ? 
                <BusinessListingList allowEdit={true} onClaim={onClaim} style={{marginTop: 20}} data={listings} />
                :
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    { !listings && <ActivityIndicator style={{marginTop: '20%'}} size="large" color="black" />}
                    <MediumText fontSize={20}>
                        { !listings ? 'Loading' : `We couldn't find any google business accounts associated with your email. Please check google and try again`}
                    </MediumText>
                </View>
            }
            </View>

            <View style={{justifyContent: 'center', alignItems: 'center', borderTopColor: Colors.border, borderTopWidth: 1, paddingVertical: 20}}>
                <RegularText fontSize={16}>
                    Don't see your listing?&nbsp;
                    <Link style={{color: Colors.primary}} to='mailto:support@wellify.com' target='_blank'>Contact us</Link>    
                </RegularText>
            </View>
        </MaxWidthContainer>
    )
}