import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { ViewStyle } from "react-native";
import Colors from "../constants/Colors";





export default ({ style, isExpanded }: { style?: ViewStyle, isExpanded: boolean }) => (
    <FontAwesome5 style={style} size={20} name={isExpanded ? 'angle-up' : 'angle-down'} color={Colors.lightGrey} />
)