import Slider, { SliderProps } from '@react-native-community/slider'
import { useFocusEffect } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, SwitchProps, TextInput, View } from "react-native"
import { ScrollView, Switch } from 'react-native-gesture-handler'
import backend from '../../backend/backend'
import LoadingComponent from '../../components/LoadingComponent'
import StyledInput from '../../components/StyledInput'
import { MediumText } from "../../components/StyledText"
import TextInputWrapper from '../../components/TextInputWrapper'
import Colors from '../../constants/Colors'
import { Config, ConfigWeights, ServiceItem } from '../../constants/Models'

interface ConfigItem {
    key: keyof Config;
    label: string;
    type: 'slider'|'text'|'toggle';
}

export default () => {
    const [config, setConfig] = useState<Config|null>(null)
    const [scratch, setScratch] = useState<Partial<Config>|null>({})
    useFocusEffect(useCallback(() => {
        loadConfig()
    }, []))

    useEffect(() => {
        const timer = setTimeout(() => {
            updateConfig()
        }, 750)

        return () => {
            clearTimeout(timer)
        }
    }, [scratch])

    const updateConfig = async () => {
        if (!config || !scratch) { return }
        try {
            await backend.adminUpdateConfig(config._id, {
                ...scratch,
            })
        }
        catch (ex) {
            console.error(ex);
        }
    }

    const loadConfig = async () => {
        const result = await backend.adminConfigs({page: 0, limit: 1})
        setConfig(result[0])
    }

    if (!config) {
        return <LoadingComponent />
    }

    const renderWeights = () => {
        const weights = (scratch?.weights || config.weights as ConfigWeights)
        const scratchWeights = (scratch?.weights || {} as ConfigWeights)

        const setWeights = (k: string) => {
            return (v: number) => {
                setScratch({
                    ...scratch,
                    weights: {
                        ...(scratch?.weights || {}) as ConfigWeights,
                        [k]: v
                    }
                })
            }
        }

        /// Use config as the master list
        const keys = Object.keys(config.weights)
        
        return (
            <>
            { keys.map((k) => (
                <SliderInputWrapper 
                    key={k} 
                    value={scratchWeights[k as keyof ConfigWeights] || config.weights[k as keyof ConfigWeights]}
                    onValueChange={setWeights(k)}
                    label={k} />
            )) }
            </>
        )
    }

    const renderServices = (label: string, services: ServiceItem[], onUpdate: (val: ServiceItem[]) => void) => {
        // if (!scratch || !scratch.generalServices) { return }
        const generalServices = services

        const onKeyChanged = (k: number) => {
            return (val: string) => {
                let tmp = [...generalServices]
                tmp[k] = {
                    ...tmp[k],
                    key: val
                }
                onUpdate(tmp)
            }
        }

        const onValueChanged = (k: number) => {
            return (val: string) => {
                let tmp = [...generalServices]
                tmp[k] = {
                    ...tmp[k],
                    label: val
                }
                onUpdate(tmp)
            }
        }

        return (
            <>
            <MediumText fontSize={24}>{label}</MediumText>
            { generalServices.map((k, idx) => (
                
                <View key={k.key} style={{flexDirection: 'row', marginVertical: 8}}>
                    <TextInput style={[styles.input, { marginRight: 20 }]} value={k.key} onChangeText={onKeyChanged(idx)} />
                    <TextInput style={[styles.input, { flex: 1}]} value={k.label} onChangeText={onValueChanged(idx)} />
                </View>
            ))}
            </>
        )
    }
    
    return (
        <ScrollView>
            {/* <pre>
                {JSON.stringify(scratch, null, 2)}
            </pre> */}
            <MediumText style={{marginBottom: 20}}>Admin Config</MediumText>

            { renderWeights() }

            <SliderInputWrapper
                label="Weight Threshold"
                value={scratch?.matchHideThresh || config.matchHideThresh || 0}
                onValueChange={matchHideThresh => setScratch({...scratch, matchHideThresh })}
                maximumValue={100}
                minimumValue={0} />

            <SliderInputWrapper
                label="Match Curve"
                accessibilityHint="Adjust all matches with given baseline"
                value={scratch?.matchCurve || config.matchCurve || 0}
                onValueChange={matchCurve => setScratch({...scratch, matchCurve })}
                maximumValue={100}
                minimumValue={0} />

            <ToggleInputWrapper 
                label="Allow anonymous users" 
                value={scratch?.allowAnonymousClients != undefined ? scratch.allowAnonymousClients : config.allowAnonymousClients} 
                onValueChange={allowAnonymousClients => setScratch({...scratch, allowAnonymousClients})}
                />

            <ToggleInputWrapper 
                label="Allow user creation" 
                value={scratch?.allowUserCreation != undefined ? scratch.allowUserCreation : config.allowUserCreation} 
                onValueChange={allowUserCreation => setScratch({...scratch, allowUserCreation})}
                />

            { renderServices('Regular Services', scratch?.generalServices || config.generalServices, val => setScratch({ ...scratch, generalServices: val })) }
            
            { renderServices('Optional Services', scratch?.optionalServices || config.optionalServices, val => setScratch({ ...scratch, optionalServices: val })) }

        </ScrollView>
    )
}

interface SliderInputWrapperProps extends SliderProps {
    label: string;
}
const SliderInputWrapper = ({ label, ...props}: SliderInputWrapperProps) => (
    <View style={{flexDirection: 'row'}}>
        <MediumText style={{marginRight: 20, width: '20%', minWidth: 200}}>{label}</MediumText>
        <Slider style={{flex: 1}} {...props} ref={undefined} />
    </View>
)

interface ToggleInputWrapperProps extends SwitchProps {
    label: string;
}

const ToggleInputWrapper = ({ label, ...props }: ToggleInputWrapperProps) => {

    return (
        <View style={{flexDirection: 'row'}}>
            <MediumText style={{marginRight: 20, width: '20%', minWidth: 200}}>{label}</MediumText>
            <Switch {...props} /> 
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        borderWidth: 1, borderRadius: 8, borderColor: Colors.inputBorder,
        paddingHorizontal: 8,
        paddingVertical: 8
    }
})