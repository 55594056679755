import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as DocumentPicker from 'expo-document-picker'
import React, { useCallback, useEffect, useState } from 'react'
import { ListRenderItemInfo, StyleSheet, Text, TouchableHighlight, TouchableOpacity, View } from "react-native"
import backend from '../../backend/backend'
import { APIError } from '../../backend/_abstract'
import BusinessListingList from '../../components/BusinessListingList'
import LoadingComponent from '../../components/LoadingComponent'
import Pagination from '../../components/Pagination'
import { MediumText, RegularText } from "../../components/StyledText"
import { BusinessItem, ClaimedBusinessItem, ListingType } from '../../constants/Models'
import uriToBlob from '../../util/uriToBlob'
import { FontAwesome5 } from '@expo/vector-icons'
import Colors from '../../constants/Colors'
import SearchInput from '../../components/SearchInput'
import CheckBox from '../../components/CheckBox'
import useDebounce from '../../hooks/useDebounce'
import { FlatList } from 'react-native-gesture-handler'
import papaparse from 'papaparse'
import events from '../../helpers/GoogleAnalytics'



const requestBatchSize: number = 500000 // 500kb / ~5000 rows
const waitBetweenRequests: number = 600 // 600ms 

let processedCount: number = 0
let isImportProcessing: boolean = false; 
let csvChunk: any = [];


export default () => {
    const [data, setData] = useState<null|ClaimedBusinessItem[]>(null)
    const [paging, setPaging] = useState({page: 0, limit: 50})
    const [searchText, setSearchText] = useState('')
    const [selectedListingIds, setSelectedListingIds] = useState<string[]>([])

    const [regularList, setUseRegularList] = useState(false)
    const [totalBusinesses, setTotalBusinesses] = useState(0)
    const [importStatus, setImportStatus] = useState({
        working: false,
        text: ''
    })

    const getTotalBusinesses = async () => {
        const totalBusinesses: any = await backend.adminBusinessesCount()
        if (totalBusinesses && totalBusinesses.count) {
            setTotalBusinesses(totalBusinesses.count)
        }
    }

    const debounceSearchText = useDebounce(searchText, 500)

    useFocusEffect(useCallback(() => {
        loadData()
        getTotalBusinesses()
    }, [paging]))

    useFocusEffect(useCallback(() => {
        if (paging.page != 0) {
            setPaging({ ...paging, page: 0 })
        }
        else {
            loadData()
        }
    }, [debounceSearchText]))

    useEffect(() => {
        setPaging({...paging, limit: regularList ? 10 : 50})
    }, [regularList])

    /////// Load data
    const loadData = async () => {
        const results = await backend.adminBusinesses({
            ...paging,
            name: searchText
        })

        setData(results)
    }

    const loadPage = (page: number) => {
        setPaging({ ...paging, page })
    }

    const handleMultiDelete = async () => {
        const answer = confirm(`Are you sure you want to delete ${selectedListingIds.length} businesses?`)
        
        if (!answer) {
            return setSelectedListingIds([])
        }


        try {
            const res = await backend.adminBusinessesDelete(selectedListingIds)
            setSelectedListingIds([])
            alert('Success')
            loadData()
        }
        catch (ex) {
            console.error(ex)
            alert((ex as Error).message)
        } finally {
            events.sendButtonClicked('Admin Businesses - Multi Delete')
        }


    }

    interface RawCSVResult {
        'office name': string,
        'last name': string,
        'website': string,
        'website page listing savings': string,
        'phone': string,
        'address': string,
        'city': string,
        'state': string,
        'zip code': string,
        'adult name': string,
        'iop adult': string,
        'iop link adult': string,
        'adult annual': number,
        'adult monthly': number,
        'adult deposit': number,
        'adult% savings on general services': number,
        'adult iop description': string,
        'perio name': string,
        'iop perio' : string,
        'iop link perio': string,
        'perio annual': number,
        'perio monthly': number,
        'perio deposit': number,
        'perio% savings on general services': number,
        'perio iop description': string,
        'child name': string,
        'iop child': string,
        'iop link child': string,
        'child annual': number,
        'child monthly': number,
        'child deposit': number,
        'child% savings on general services': number,
        'child iop description': string,
        'adult description of additional savings': string,
        'perio description of additional savings': string,
        'child description of additional savings': string,
        'image': string,
        'iop links': string,
        'email address': string,
    } // TODO not sure what to do about iopAdditional and link fields in type ^ 
    
    const formatCsvLine = (rawCsv: { data: RawCSVResult }) => {
        const { data } = rawCsv;
        const lowercaseData = Object.fromEntries(
            Object.entries(data).map(([k, v]) => [k.toLowerCase(), v])
        );

        const { inOfficePlan, additionalPlans } = Object.keys(lowercaseData).reduce((all: any, curr) => { // TODO add type
            if(curr.match(/iop (adult|child|perio)/)) {
                all = Object.assign({}, all, { inOfficePlan: (all.inOfficePlan || lowercaseData[curr] == '1' || lowercaseData[curr].toLowerCase() == 'true') })
            }

            if (curr.match(/iop additional\d+$/)) {
                const name = lowercaseData[`${curr} name`]
                all = Object.assign(
                    {}, 
                    all, 
                    { 
                        additionalPlans: Object.assign(
                            {}, 
                            all.additionalPlans, 
                            {
                                [name]: Object.assign(
                                    {},
                                    all.additionalPlans[name] || {}, 
                                    { 
                                        name: name, 
                                        iop:  (lowercaseData[curr] == '1' || lowercaseData[curr].toLowerCase() == 'true'),
                                    },
                                ),
                            },
                        ),
                    },
                );
            }
            else if (curr.match(/iop link additional\d+$/)) {
                const cleanedName: string = curr.replace(/ link /, ' ')
                const name = lowercaseData[`${cleanedName} name`]
                all = Object.assign(
                    {}, 
                    all, 
                    { 
                        additionalPlans: Object.assign(
                            {}, 
                            all.additionalPlans, 
                            {
                                [name]: Object.assign(
                                    {}, 
                                    all.additionalPlans[name] || {}, 
                                    { name: name, iopLink: lowercaseData[curr] },
                                ),
                            },
                        ),
                    },
                );
            }
            else if (curr.match(/iop (additional\d+ (annually|monthly|deposit))/i)) {
                const matchType: RegExpMatchArray | null = curr.match(/annually|monthly|deposit/i)
                const typeKey: string = (matchType?.length) ? matchType[0] : 'default'
                const cleanedName: string = curr.replace(typeKey, '').toLowerCase().trimEnd()
                const name = lowercaseData[`${cleanedName} name`];
                all = Object.assign(
                    {}, 
                    all, 
                    { 
                        additionalPlans: Object.assign(
                            {}, 
                            all.additionalPlans, 
                            {
                                [name]: Object.assign(
                                    {}, 
                                    all.additionalPlans[name] || {}, 
                                    { name: name, [typeKey]: lowercaseData[curr] },
                                ),
                            },
                        ),
                    },
                );
            }
            else if (curr.match(/iop (additional\d+ description)/)) {
                const cleanedName: string = curr.replace(' description', '')
                const name = lowercaseData[`${cleanedName} name`];
                all = Object.assign(
                    {}, 
                    all, 
                    { 
                        additionalPlans: Object.assign(
                            {}, 
                            all.additionalPlans, 
                            {
                                [name]: Object.assign(
                                    {}, 
                                    all.additionalPlans[name] || {}, 
                                    { name: name, savingsDescription: lowercaseData[curr] },
                                ),
                            },
                        ),
                    },
                );
            }

            return all;
        }, { inOfficePlan: false, additionalPlans: {}, });

        const postalCode = (lowercaseData['zip code'] || '').padStart(5, '0')
        return {
            name: lowercaseData['office name'],
            image: lowercaseData['image'],
            owner: lowercaseData['last name'],
            type: ListingType.regular,
            pricing: {
                inOfficePlan,
                plans: Object.keys(additionalPlans).map(key => additionalPlans[key]),
                perioInOfficePlan: {
                    name: lowercaseData['perio name'] || 'Perio',
                    iop: (lowercaseData['iop perio'] && (lowercaseData['iop perio'] == '1' || lowercaseData['iop perio'].toLowerCase() == 'true')),
                    annually: lowercaseData['perio annual'],
                    monthly: lowercaseData['perio monthly'],
                    deposit: lowercaseData['perio deposit'],
                    percentSavings: lowercaseData['perio% savings on general services'],
                    iopDescription: lowercaseData['perio iop description'],
                    iopLink: lowercaseData['iop link perio'],
                    savingsDescription: lowercaseData['perio description of additional savings']
                },
                adultInOfficePlan: {
                    name: lowercaseData['adult name'] || 'Adult',
                    iop: (lowercaseData['iop adult'] && (lowercaseData['iop adult'] == '1' || lowercaseData['iop adult'].toLowerCase() == 'true')),
                    annually: lowercaseData['adult annual'],
                    monthly: lowercaseData['adult monthly'],
                    deposit: lowercaseData['adult deposit'],
                    percentSavings: lowercaseData['adult% savings on general services'],
                    iopDescription: lowercaseData['adult iop description'],
                    iopLink: lowercaseData['iop link adult'],
                    savingsDescription: lowercaseData['adult description of additional savings']
                },
                childInOfficePlan: {
                    name: lowercaseData['child name'] ||'Child',
                    iop: (lowercaseData['iop child'] && (lowercaseData['iop child'] == '1' || lowercaseData['iop child'].toLowerCase() == 'true')),
                    annually: lowercaseData['child annual'],
                    monthly: lowercaseData['child monthly'],
                    deposit: lowercaseData['child deposit'],
                    percentSavings: lowercaseData['child% savings on general services'],
                    iopDescription: lowercaseData['child iop description'],
                    iopLink: lowercaseData['iop link child'],
                    savingsDescription: lowercaseData['child description of additional savings']
                },
            },
            contact: {
                address: {
                    line1: lowercaseData['address'],
                    city: lowercaseData['city'],
                    state: lowercaseData['state'],
                    postalCode: postalCode
                },
                website: lowercaseData['website'],
                websiteSavingsListing: lowercaseData['website page listing savings'],
                email: lowercaseData['email address'],
                phone: lowercaseData['phone']
            }
        };
    }

    const parseCsvLine = async (results: any) => {
        csvChunk.push(formatCsvLine(results));
    }

    const completeCsvParse = async () => {
        try {
            setImportStatus({
                working: true,
                text: `Processed ${processedCount}`,
            })
            await backend.adminBusinessesImport(csvChunk)
            processedCount = processedCount + csvChunk.length
            // wait before next request.
            await new Promise(resolve => setTimeout(() => resolve(''), waitBetweenRequests))
            isImportProcessing = false
            csvChunk.length = 0
        } catch (err) {
            console.log('ERROR importing csv chunk', err)
            isImportProcessing = false
            csvChunk.length = 0
        }
    }

    const parseFileChunk = (fileChunk: any): Promise<any> | void => {
        if (fileChunk.size) {
            isImportProcessing = true
            papaparse.parse(fileChunk, {
                delimiter: ",",
                skipEmptyLines: 'greedy',
                header: true,
                transformHeader: (line: string) => line.replace(/^[',"]/, '').replace(/^ [',"]/, '').replace(/[',"]$/, ''),
                step: parseCsvLine,
                complete: completeCsvParse,
            })
            return new Promise((resolve) => {
                const waitForImportProcess = setInterval(() => {
                    if (!isImportProcessing) {
                        clearInterval(waitForImportProcess)
                        resolve('')
                    }
                }, 500)
            });
        }
    }

    const handleImport = async () => {
        try {
            const result = await DocumentPicker.getDocumentAsync({ type: 'text/csv' })
            if (result.type == 'success' && result.file) {
                setImportStatus({
                    working: true,
                    text: 'Importing'
                });
                const blob = await uriToBlob(result.uri);
                const totalLength = blob.size;
                const parts = totalLength / requestBatchSize;
                for (let i = 0; i <= (parts + 1); i++) {
                    const startingPosition = i * requestBatchSize;
                    const fileChunk = (blob as any).slice(startingPosition, startingPosition + requestBatchSize, 'text/csv');
                    await parseFileChunk(fileChunk);
                }
                await backend.adminBusinessesMerge()
                loadData()
                processedCount = 0
                setImportStatus({
                    working: false,
                    text: ''
                })

            }
        } catch (ex) {
            console.error(ex)
            processedCount = 0
            setImportStatus({
                working: false,
                text: ''
            })
            if ((ex as APIError).details as {[key: string]: string}) {
                const details = (ex as APIError).details as {[key: string]: string}
                alert(`Error importing: ${details['message'] || (ex as Error).message}`)
            } else {
                alert(`Error importing: ${(ex as Error).message}`)
            }
        }
    }

    if (!data) {
        return <LoadingComponent />
    }

    if (importStatus.working) {
        return <LoadingComponent text={importStatus.text} />
    }

    return (
        <View style={{flex: 1}}>
            
            <View style={{
                flexDirection: 'row', 
                alignItems: 'center', 
                flexWrap: 'wrap', 
                height: 'auto',
                marginTop: 20,
                marginBottom: 20
            }}>

                <View>
                    {  
                         (selectedListingIds.length) ? 
                            <TouchableOpacity                       
                                style={{
                                    width: 243,
                                    height: 52,
                                    borderRadius: 26,
                                    backgroundColor: '#FF4646',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }} 
                                onPress={handleMultiDelete}
                            >
                                <>
                                    <FontAwesome5 style={{ flexDirection: 'column' }} name='cloud-upload-alt' color='white' size={24} />
                                    <Text style={{
                                        marginLeft: 10,
                                        fontFamily: 'IBMPlexSans_500Medium',
                                        fontSize: 16,
                                        lineHeight: 19,
                                        color: 'white',
                                        flexDirection: 'column'
                                    }}>Delete { `${selectedListingIds.length} ${(selectedListingIds.length == 1) ? 'Business' : 'Businesses' }` }</Text>
                                </>
                            </TouchableOpacity>
                        : <SearchInput 
                            style={{ marginLeft: 10 }} 
                            onChangeText={setSearchText} 
                            value={searchText} 
                            placeholder="Search"
                        />
                    }
                </View>

                <Text style={{
                    display: 'flex', 
                    flex: 1, 
                    justifyContent: 'flex-end', 
                    color: '#989898'
                    }}>{`${totalBusinesses} Businesses`}</Text>

                <View style={{flexDirection: 'row', marginHorizontal: 12}}>
                    <TouchableOpacity                       
                        style={{
                            width: 154,
                            height: 52,
                            borderRadius: 26,
                            backgroundColor: Colors.primary,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} 
                        onPress={() => {
                            handleImport()
                            events.sendButtonClicked('Admin Businesses - Import')
                        }}
                    >
                        <>
                            <FontAwesome5 style={{ flexDirection: 'column' }} name='cloud-upload-alt' color='white' size={24} />
                            <Text style={{
                                marginLeft: 10,
                                fontFamily: 'IBMPlexSans_500Medium',
                                fontSize: 16,
                                lineHeight: 19,
                                color: 'white',
                                flexDirection: 'column'
                            }}>Import</Text>
                        </>
                    </TouchableOpacity>
                    
                    <TouchableHighlight style={{marginTop: 13, marginLeft: 25}} onPress={() => {
                        setUseRegularList(true)
                        events.sendButtonClicked('Admin Businesses - Use Regular List true')
                    }} underlayColor='white'>
                        <FontAwesome5 name='stream' size={24} color={regularList ? 'black' : Colors.inputBorder} />
                    </TouchableHighlight>

                    <TouchableHighlight style={{marginTop: 13, marginLeft: 15}} onPress={() => {
                        setUseRegularList(false)
                        events.sendButtonClicked('Admin Businesses - Use Regular List false')
                    }} underlayColor='white'>
                        <FontAwesome5 name='th-list' size={24} color={regularList ? Colors.inputBorder : 'black'} />
                    </TouchableHighlight>
                </View>
            </View>
            
            {
                regularList ?
                    <BusinessListingList data={data} allowEdit={true} />
                    :
                    <BusinessListingTableList 
                        refresh={loadData}
                        data={data} 
                        selectedListingIds={selectedListingIds} 
                        setSelectedListingIds={setSelectedListingIds}
                    />
            }
            <Pagination 
                label="Admin Businesses"
                hasMore={data.length == paging.limit} 
                hasPrev={paging.page > 0} 
                loadNext={() => loadPage(paging.page+1)} 
                loadPrev={() => loadPage(paging.page-1)} />
        </View>
    )
}


interface BusinessListingTableListProps {
    selectedListingIds: string[];
    setSelectedListingIds: Function,
    data: ClaimedBusinessItem[];
    refresh: () => void;
}

const businessListStyles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#E5E5E5', // TODO move these to common colors file
        paddingVertical: 8,
        backgroundColor: 'white',
        
    },
    checkBox: {
        height: 24, 
        width: 21, 
        marginLeft: 10 
    },
    headerText: {
        color: '#9A9A9A'
    }
})


interface FieldItem { title: string; width: string; }
interface FieldItemWithRender extends FieldItem {
    render: (itm: BusinessItem) => string;
}
interface FieldItemWithId extends FieldItem {
    id: keyof BusinessItem
}
const fields: (FieldItemWithId|FieldItemWithRender)[] = [
    {id: 'name', title: 'Name', width: '30%' },
    {render: (itm) => (itm.contact.address.line1 || 'N/A'), title: 'Address', width: '20%'}
]

const BusinessListingTableList = ({ selectedListingIds, setSelectedListingIds, data, refresh }: BusinessListingTableListProps ) => {
    const navigation = useNavigation()

    const unselectAllRows = () => {
        if (selectedListingIds.length) {
            setSelectedListingIds([])
            events.sendButtonClicked('Admin Businesses - Unselect all rows')
        }
    }

    const handleEdit = (item: ClaimedBusinessItem) => {
        navigation.navigate('EditListing', { id: item._id })
    }

    const handleDelete = async (item: ClaimedBusinessItem) => {

        const answer = confirm(`Are you sure you want to delete "${item.name}"?`)
        if (!answer) { return }

        try {
            await backend.adminBusinessesDelete([item._id as string])
            alert('Success')
            refresh()
        }
        catch (ex) {
            console.error(ex)
            alert((ex as Error).message)
        }
    }

    const renderHeader = () => {

        return (
            <View style={businessListStyles.row}>
                    <TouchableHighlight underlayColor='transparent' onPress={unselectAllRows}>
                    {
                        (selectedListingIds.length) ? 
                            <FontAwesome5 style={businessListStyles.checkBox} color={Colors.primary} name='minus-square' size={24} />
                            : <FontAwesome5 style={businessListStyles.checkBox} color='gray' name='square' size={24} />
                    }
                    </TouchableHighlight>
                {
                    fields.map((f, i) => <MediumText key={i} style={[businessListStyles.headerText, { width: f.width, marginLeft: 25 }]}>{f.title}</MediumText>)
                }
                <MediumText style={[businessListStyles.headerText, { display: 'flex', justifyContent: 'flex-end', marginRight: 14, width: '40%'  }]}>Actions</MediumText>
            </View>
        )
    }

    
    const renderItem = ({ item }: ListRenderItemInfo<ClaimedBusinessItem>) => {

        const selectedIdx = selectedListingIds.indexOf(item._id as string)
        const isSelected = selectedIdx !== -1
        let tmp = [...selectedListingIds]
        const updateChecked = (val: boolean) => {
            if (val) {
                tmp.push(item._id as string)
            }
            else {
                tmp.splice(selectedIdx, 1)
            }
            setSelectedListingIds(tmp)
        }

        return (
            <View style={businessListStyles.row}>
               
                <CheckBox style={businessListStyles.checkBox} value={isSelected} onValueChange={updateChecked} />
                {
                    fields.map((f, i) => <RegularText key={`list-item-${i}`} style={{ width: f.width, marginLeft: 25 }}>{'render' in f ? f.render(item) : item[f.id]}</RegularText>)
                }

                <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', flex: 1, paddingHorizontal: 8}}>
                    {
                        <>
                        <TouchableHighlight underlayColor='transparent' style={{paddingHorizontal: 8}} onPress={() => {
                            handleDelete(item)
                            events.sendButtonClicked('Admin Businesses - Delete')
                        }}>
                            <FontAwesome5 name='trash-alt' color={Colors.inputBorder} size={22}/>
                        </TouchableHighlight>
                        <TouchableHighlight underlayColor='transparent' onPress={() => {
                            handleEdit(item)
                            events.sendButtonClicked('Admin Businesses - Edit')
                        }}>
                            <FontAwesome5 name='edit' color={Colors.inputBorder} size={24}/>
                        </TouchableHighlight>
                        </>
                    }
                </View>
            </View>
        )
    }

    return (
        <FlatList 
            data={data}
            showsVerticalScrollIndicator={false}
            stickyHeaderIndices={[0]}
            initialNumToRender={50}
            ListHeaderComponent={renderHeader}
            renderItem={renderItem}
            keyExtractor={(d,idx) => `${d.name}-${idx}`}    
        />
    )
}
