import { Link } from "@react-navigation/native"
import React from "react"
import { StyleSheet, Text, View, ViewProps } from "react-native"


const styles = StyleSheet.create({
    container: {
        marginTop: 40,
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    linkText: {
        color: 'gray',
    },
    link: {
        marginBottom: 40,
        marginLeft: 20,
        flexDirection: 'column'
    }
})

export default ()  => {
    return (
        <View>
            <View
                style={{
                    borderBottomColor: '#E6E6E6',
                    borderBottomWidth: 1,
                    marginBottom: 30,
                    marginTop: 40,
                }}
            />
            <View style={{ flex: 1, flexDirection: 'row', marginBottom: 10}}>
                <Link to='/privacy' style={ styles.link }>   
                    <Text style={styles.linkText}>Privacy Policy</Text>
                </Link>
                <Link to='/terms' style={ styles.link }>   
                    <Text style={styles.linkText}>Terms Of Service</Text>
                </Link>
                <Link to='/faq' style={ styles.link }>   
                    <Text style={styles.linkText}>FAQ</Text>
                </Link>
            </View>
        </View>
    )
}