import React, { useContext, useEffect, useState } from "react";
import { View, Text, ActivityIndicator, StyleSheet } from "react-native";
import { MediumText } from "../../components/StyledText";
import Colors from "../../constants/Colors";
import RoundyButton from "../../components/RoundyButton";
import StyledInput from "../../components/StyledInput";
import { AuthContext } from "../../contexts/AuthContext";

export default () => {
  const { registerAdmin, user } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmitTapped = async () => {
    setLoading(true);
    try {
      await registerAdmin(email, pass);
    } catch (ex) {
      // TODO: Show error
      setError((ex as Error).message);
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <View>
        {error && (
          <MediumText style={{ color: Colors.error }}>{error}</MediumText>
        )}
        <StyledInput
          style={styles.input}
          value={email}
          onChangeText={setEmail}
          placeholder="Email"
        />
        <StyledInput
          secureTextEntry={true}
          style={styles.input}
          value={pass}
          onChangeText={setPass}
          placeholder="Password"
        />

        <RoundyButton eventLabel="Admin Auth - Login" onPress={onSubmitTapped}>
          {loading ? <ActivityIndicator size="large" color="white" /> : "Login"}
        </RoundyButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  input: {
    marginBottom: 12,
  },
});
