import * as React from 'react';
import { View } from 'react-native';
import { MediumText, RegularText } from '../StyledText';
import { TitleHeader } from './TitleHeader';
import { styles } from "./styles";

interface QuestionAnswerProps {
    headerNumber: string;
    question: string;
    answer: string;
}

export const QuestionAnswer = ({ headerNumber, question, answer}: QuestionAnswerProps) => (
  <View>
    <TitleHeader headerText={ question } headerNumber={ headerNumber }/>
    <View style={{ marginLeft: 28 }}>
      <RegularText fontSize={ 16 } style={styles.bodyText}>
        <MediumText fontSize={ 18 } style={[styles.headerText]}>
          { 'A:  ' }
        </MediumText>
        { answer } 
      </RegularText>
    </View>
  </View>
);
