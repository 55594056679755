import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,


  // Maximum width
  maxContainerWidth: 1000,

  // The padding on each side of the view
  gutterWidth: 20
};
