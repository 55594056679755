import React from 'react'
import { Platform } from 'react-native';
import AdminBusinesses from './AdminBusinesses';
import AdminUsers from './AdminUsers';
import AdminConfig from './AdminConfig';
import AdminNavBar from '../../components/AdminNavbar';
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Colors from '../../constants/Colors';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";


export type AdminTabsParamsList = {
    Dashboard: undefined;
    Import: undefined;
    Config: undefined;
    Users: undefined;
    Businesses: undefined;
  };

const Tab = createBottomTabNavigator<AdminTabsParamsList>()

export default () => {
    const isNotWeb = !!(Platform.OS != 'web')
    return (
        <MaxWidthContainer style={{flex: 1, backgroundColor: Colors.white}}>
        <AdminNavBar />
            <Tab.Navigator screenOptions={{}}>
                <Tab.Screen 
                    name='Businesses' 
                    component={AdminBusinesses} 
                    options={{ tabBarVisible: isNotWeb }}
                />
                <Tab.Screen 
                    name='Users' 
                    component={AdminUsers} 
                    options={{ tabBarVisible: isNotWeb }}
                />
                <Tab.Screen 
                    name='Config' 
                    component={AdminConfig} 
                    options={{ tabBarVisible: isNotWeb }}
                />
            </Tab.Navigator>
        </MaxWidthContainer>
    )
}
