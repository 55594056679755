import React, { PropsWithChildren, useState } from 'react'
import { ViewProps } from 'react-native'


export const ScrollContext = React.createContext<{
    scrollEnabled: boolean
    setScrollEnabled: (val: boolean) => void
}>({
    scrollEnabled: true,
    setScrollEnabled: () => { }
})



interface ScrollProviderProps extends PropsWithChildren<ViewProps> {

}
export const ScrollProvider = ({ children }: ScrollProviderProps) => {
    const [scrollEnabled, setScrollEnabled] = useState(true)

    return (
        <ScrollContext.Provider value={{
            scrollEnabled,
            setScrollEnabled
        }}>
            {children}
        </ScrollContext.Provider>
    )
}