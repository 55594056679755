import {  StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    headerText: {
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 6
    },
    subtext: {
    },
    bodyText: {
      marginBottom: 10,
    }
  });