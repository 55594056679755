import React from 'react'
import { StyleSheet } from 'react-native'
import Colors from '../../constants/Colors'
import { MediumText } from '../StyledText'



const styles = StyleSheet.create({
    matchText: {
        color: Colors.green,
        marginRight: 4
    },
})


export default ({ matchPercent, isCompact }: {matchPercent: number, isCompact: boolean}) => (
    <MediumText fontSize={20} style={[styles.matchText, { paddingLeft: isCompact ? 0 : 32}]}>
        {Math.min(Math.max(0, matchPercent), 100).toFixed(1)}%{isCompact ? ' ' : '\n'}
        {<MediumText fontSize={16} style={styles.matchText}>Match</MediumText>}
    </MediumText>
)