import 'react-native-gesture-handler';

import React from 'react';
import { MediaQueryProvider } from './contexts/MediaQueryContext';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { AuthProvider } from './contexts/AuthContext';
import Entry from './screens/Entry';
import './helpers/GoogleAnalytics'

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  

  if (!isLoadingComplete) {
    return null;
  } 
  
  return (
    <MediaQueryProvider>
      <AuthProvider>
        <Entry />
      </AuthProvider>
    </MediaQueryProvider>
  )
}
