import { FontAwesome5 } from '@expo/vector-icons'
import { Link, useNavigation } from '@react-navigation/native'
import React, { useContext, useState } from 'react'
import {
    Image, StyleProp, StyleSheet, TouchableHighlight, TouchableOpacity, View, ViewProps, ViewStyle
} from "react-native"
import navLogo from '../assets/images/nav-logo.png'
import Colors from '../constants/Colors'
import { AuthContext } from '../contexts/AuthContext'
import { MediaQueryContext, ScreenSize } from '../contexts/MediaQueryContext'
import { MediumText, RegularText } from './StyledText'
import events from '../helpers/GoogleAnalytics'

interface NavProps extends ViewProps {
    children?: React.ReactElement
}




export default (props: NavProps) => {
    const { user, logout: realLogout } = useContext(AuthContext)
    const { screenSize } = useContext(MediaQueryContext)
    const navigation = useNavigation()
    const isCompact = screenSize < ScreenSize.large
    const [selectedNav, setSelectedNav] = useState<any>('Businesses')

    const logout = async () => {
        try {
            const answer = confirm('Are you sure you want to logout')
            if (!answer) { return }
            await realLogout()
        }
        catch (ex) { console.error(ex) }
        navigation.navigate('Root')
    }

    const HeaderButton = (props: { title: string, style?: StyleProp<ViewStyle> }) => (
        <View style={props.style}>
            <TouchableOpacity style={styles.button} onPress={() => { 
                setSelectedNav(props.title)
                navigation.navigate(props.title)
                events.sendButtonClicked(`Admin Navbar - ${props.title}`, 'link')
            }}>
                <MediumText style={{ color: (selectedNav == props.title) ? 'black' : 'lightgray' }} >{props.title}</MediumText>
            </TouchableOpacity>
        </View>
    )

    return (
        <View style={[isCompact ? styles.containerTablet : styles.container, props.style]}>
            <Link to='/' style={{marginRight: isCompact ? 0 : 32, marginBottom: isCompact ? 18 : 0, ...styles.iconInner }}>   
                <Image source={navLogo} 
                    style={{width: 275 * (isCompact ? 0.6 : 1), height: 78 * (isCompact ? 0.6 : 1)}} />
            </Link>
            <View style={styles.grayInner}>
                <View style={[styles.floatingBar, { width: 313, height: 52, marginRight: 13, marginLeft: 13 }]}>
                    <HeaderButton title='Businesses' style={{
                        marginLeft: 18
                    }} />
                    <View style={{  
                        height: 36, 
                        borderWidth: 1, 
                        borderColor: '#CCCCCC',
                        marginLeft: 15
                        }}/>
                    <HeaderButton title='Users' />
                    <View style={{  
                        height: 36, 
                        borderWidth: 1, 
                        borderColor: '#CCCCCC',
                        }}/>
                    <HeaderButton title='Config' />
                </View>
                <View style={[styles.floatingBar, { width: 195, height: 52 }]}>
                    { user?.email && user.email.length > 0 && (
                        <TouchableHighlight style={{ 
                            flexDirection: 'row', 
                            width: '100%', 
                            height: '100%', 
                            paddingHorizontal: 12,
                            alignItems: 'center',
                        }} underlayColor='white' onPress={logout}>
                            <>
                                <RegularText fontSize={14} style={[styles.usernameText, { flexDirection: 'column' }]}>
                                    {user.email}
                                </RegularText>
                                <FontAwesome5 style={{ 
                                    flexDirection: 'column', 
                                    width: 10, 
                                    height: 16,
                                    marginLeft: 40,
                                    marginTop: 8
                                }} name='angle-down' color='black' />
                            </>
                        </TouchableHighlight>
                    )}
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 20,
        borderBottomColor: Colors.border,
        borderBottomWidth: 1
    },
    iconInner: {
        flexBasis: '15%',
    },
    grayInner: {
        flexBasis: '75%',
        backgroundColor: '#F5F5F5',
        border: '1px solid #E6E6E6',
        borderRadius: 8,
        width: 788,
        height: 84,
        flexDirection: 'row',
        alignItems: 'center'
    },
    grayText: {
        color: '#2B2C33',
        fontFamily: 'IBMPlexSans',
        fontSize: 16,
        lineHeight: 19,
        textAlign: 'left',
        marginLeft: 30,
        width: 141,
        height: 40,
        flexDirection: 'column'
    },
    floatingBar: {
        backgroundColor: 'white',
        border: '1px solid #E5E5E5',
        borderRadius: 8,
        boxShadow: '0 3px 4px 0 rgba(5, 5, 5, 0.25)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        color: 'black',
        flexDirection: 'column',
        width: 83,
        height: 20,
        alignItems: 'center'
    },
    usernameText: {
        color: '#2B2C33',
        fontFamily: 'IBMPlexSans',
        fontSize: 16,
        lineHeight: 19,
        textAlign: 'right',
    },
    containerTablet: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingVertical: 20,
        borderBottomColor: Colors.border,
        borderBottomWidth: 1
    }
})