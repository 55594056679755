import Colors from "../../constants/Colors";
import { StyleSheet } from "react-native";

export const asyncImageStyles = StyleSheet.create({
    loading: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: Colors.offwhite
    }
})

export const imageStyles = StyleSheet.create({
    big: {
        width: 180, 
        height: 120, 
        borderTopLeftRadius: 8, 
        borderBottomLeftRadius: 8,
        backgroundColor: '#ccc',
    },
    compact: { 
        flex: 1, 
        width: '100%', 
        height: 200, 
        borderRadius: 8, 
        resizeMode: 'cover', 
        marginVertical: 20,
        backgroundColor: '#ccc'
    }
})

export const planDescStyles = StyleSheet.create({
    margin: { 
        marginLeft: 40, 
        marginBottom: 8, 
        marginTop: 10 
    }
})

export const styles = StyleSheet.create({
    none: { display: 'none' },
    column: { flexDirection: 'column' },
    row: { flexDirection: 'row' },
    container: {
        overflow: 'hidden',
        maxWidth: '100%',
        borderWidth: 1, 
        borderColor: Colors.border, 
        backgroundColor: Colors.white,
        padding: 16,
        paddingRight: 32,
        borderRadius: 8,
        marginBottom: 20
    },
    containerExpanded: {
        shadowColor: '#050505',
        shadowOpacity: 0.25,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 4 },
        padding: 32,
    },
    editButton: {
        maxHeight: 50,
        marginTop: 5,
        marginBottom: 5,
    },

    /// Reviews
    reviewsContainer: {
        maxWidth: 160,
        marginTop: 18
    },
    star: { color: Colors.border },
    starFill: { color: 'orange' },

    /// contactContainer
    contactContainer: {
        marginTop: 16
    },
    contactIconPadding: { marginRight: 18 },

    rightContainer: {
        flexDirection: 'row', 
        alignItems: 'flex-start', 
        justifyContent: 'space-between'
    },

    // request appt 
    requestApptContainer: {
        flexDirection: 'row',
        flex: 1
    },
    requestApptHeading: {
        marginBottom: 10,
        flexDirection: 'column',
    },
    requestApptDate: {
        height: 'auto',
        width: 147,
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 30
    },
    requestApptInput: {
        height: 'auto',
        width: '100%',
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 30
    },

})