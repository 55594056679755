import React, { useContext } from 'react'
import { Image, StyleSheet, ViewProps, View, TouchableHighlight } from "react-native"

import myDentistMatchLogo from '../assets/images/nav-logo.png'
import dentalDifferenceLogo from '../assets/images/dental_difference.png'
import events from '../helpers/GoogleAnalytics'

interface NavProps extends ViewProps {
    children?: React.ReactElement
}


import Colors from '../constants/Colors'
import { MediumText, RegularText } from './StyledText'
import { MediaQueryContext, ScreenSize } from '../contexts/MediaQueryContext'
import { Link, useNavigation } from '@react-navigation/native'
import { AuthContext } from '../contexts/AuthContext'

export default (props: NavProps) => {
    const { user, logout: realLogout } = useContext(AuthContext)
    const { screenSize } = useContext(MediaQueryContext)
    const navigation = useNavigation()
    const isCompact = screenSize < ScreenSize.large

    const logout = async () => {
        try {
            const answer = confirm('Are you sure you want to logout')
            if (!answer) { return }
            await realLogout()
        }
        catch (ex) { console.error(ex) }
        navigation.navigate('Root')
        events.sendButtonClicked('Logout', 'link')
    }

    return (
        <View style={[isCompact ? styles.containerTablet : styles.container, props.style]}>
            <View style={{ flexDirection: isCompact ? 'column' : 'row', alignItems: 'center'}}> 
                  <Image source={dentalDifferenceLogo} 
                          style={{ flexDirection: 'row', marginRight: 40, width: 302, height: 78 }} />
                  <Image source={myDentistMatchLogo}
                      style={{ flexDirection: 'row', marginRight: 20, width: 275, height: 78 }} />
            </View>
            { props.children ?? (
              <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
                <RegularText numberOfLines={2} fontSize={20} style={{color: Colors.lightGrey, marginTop: 30, marginBottom: 30 }}>
                    Find a dentist that's a perfect match for you.
                </RegularText>
              </View>
            ) }
            { user?.email && user.email.length > 0 && (
                <TouchableHighlight style={{paddingHorizontal: 12}} underlayColor='white' onPress={logout}>
                    <RegularText fontSize={14}>
                        <MediumText fontSize={16}>Signed in:{'\n'}</MediumText>
                        {user.email}
                    </RegularText>
                </TouchableHighlight>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 20,
        borderBottomColor: Colors.border,
        borderBottomWidth: 1
    },

    containerTablet: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 20,
        borderBottomColor: Colors.border,
        borderBottomWidth: 1
    }
})