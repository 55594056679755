import GoogleAnalytics from "../helpers/GoogleAnalytics"



enum Environment {
    development = "development",
    production = "production"
}

declare var process : {
    env: {
        EXPO_API_HOST: string;
        EXPO_API_KEY: string;
        NODE_ENV: Environment;
        EXPO_GOOGLE_ANALYTICS_MEASUREMENT_ID: string;
    }
}

console.log(process.env)


export default {
    isDev: process.env.NODE_ENV == Environment.development,


    apiHost: process.env.EXPO_API_HOST,
    apiKey: process.env.EXPO_API_KEY,
    googleAnalyticsMeasurementId: "G-QZZBE4N7JB",
}