import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { SubHeader } from '../components/FAQ';
import HelperPageLayout from '../components/HelperPageLayout';
import { RegularText } from '../components/StyledText';

export default function PrivacyPolicy() {
  return (
    <HelperPageLayout header="Privacy Policy">
        <View>
          <SubHeader headerText="This privacy notice discloses the privacy practices for (wellifyme.com). This privacy notice applies solely to information collected by this website. It will notify you of the following:"/>
          <RegularText style={styles.bodyText}>
            1. What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.
          </RegularText>
          <RegularText style={styles.bodyText}>
            2. What choices are available to you regarding the use of your data.
          </RegularText>
          <RegularText style={styles.bodyText}>
            3. The security procedures in place to protect the misuse of your information.
          </RegularText>
          <RegularText style={styles.bodyText}>
            4. How you can correct any inaccuracies in the information.
          </RegularText>
            
          <SubHeader headerText=" Information Collection, Use, and Sharing" />
          <RegularText style={styles.bodyText}>

            We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.

            We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request.

            Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.

          </RegularText>

          <SubHeader headerText="Registration" />
          <RegularText style={styles.bodyText}>
            In order to use this website, a user must first complete the registration form. During registration a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest. At your option, you may also provide demographic information (such as gender or age) about yourself, but it is not required.
          </RegularText>
            
          <SubHeader headerText="Cookies" />
          <RegularText style={styles.bodyText}>
            We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.

            Some of our business partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.
          </RegularText>

          <SubHeader headerText="Sharing" />
          <RegularText style={styles.bodyText}>
            We partner with another party to provide specific services. When the user signs up for these services, we will share names, or other contact information that is necessary for the third party to provide these services. These parties are not allowed to use personally identifiable information except for the purpose of providing these services.
          </RegularText>

          <SubHeader headerText="Links" />
          <RegularText style={styles.bodyText}>
            This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. Your Access to and Control Over Information You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:
          </RegularText>
          <RegularText style={styles.bodyText}>
            ⦁ See what data we have about you, if any.
          </RegularText>
          <RegularText style={styles.bodyText}>
            ⦁ Change/correct any data we have about you.
          </RegularText>
          <RegularText style={styles.bodyText}>
            ⦁ Have us delete any data we have about you.
          </RegularText>
          <RegularText style={styles.bodyText}>
            ⦁ Express any concern you have about our use of your data.
          </RegularText>

          <SubHeader headerText="Security" />
          <RegularText style={styles.bodyText}>
            We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.

            Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for “https” at the beginning of the address of the Web page.

            While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.

            If you feel that we are not abiding by this privacy policy, you should contact us immediately via email- support@wellifyme.com.
          </RegularText>
        </View>
  </HelperPageLayout>

  );
}

const styles = StyleSheet.create({
  headerText: {
    fontWeight: 'bold',
    marginTop: 19,
  },
  subtext: {
  },
  bodyText: {
    marginVertical: 6,
  }
});
