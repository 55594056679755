import React, { useEffect, useState } from "react";
import { TextInputProps } from "react-native";
import TextInputWrapper, { TextInputWrapperProps } from "../../components/TextInputWrapper";





interface USDTextWrapperProps extends TextInputWrapperProps {}





export default ({ onChangeText, value, ...props}: USDTextWrapperProps) => {
    const [usdValue, setUSDValue] = useState(value || '')

    useEffect(() => {
        if (usdValue != value && onChangeText) {
            onChangeText(usdValue)
        }
    }, [usdValue])

    const stringToUSD = (val: string) => {
        setUSDValue(val)
    }

    const onBlur = () => {
        setUSDValue(`${parseFloat(usdValue).toFixed(2)}`)
    }

    return (
        <TextInputWrapper {...props} onChangeText={stringToUSD} onBlur={onBlur} value={usdValue} />
    )
}