import React from "react";
import { Alert, Modal, StyleSheet, View } from "react-native";
import CalendarPicker from 'react-native-calendar-picker';

const CalendarPickerModal = (props: any) => {
  const { visible, setModalVisible, onChange, currentDate, style } = props;
  return (
    <View style={styles.centeredView}>
      <Modal
        style={style}
        animationType="none"
        transparent={true}
        visible={visible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!visible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <CalendarPicker
                minDate={new Date()}
                selectedDayColor='#0099FF'
                onDateChange={(date: any) => {
                  onChange(date)
                  setTimeout(() => setModalVisible(false), 500)
                }}
                selectedStartDate={currentDate}
            />
          </View>
        </View>
      </Modal>

    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  }
});

export default CalendarPickerModal;