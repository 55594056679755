import { FontAwesome5 } from '@expo/vector-icons'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Field, FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Alert, StyleSheet, TextInput, TouchableHighlight, View } from 'react-native'
import backend from '../backend/backend'
import MaxWidthContainer from '../components/MaxWidthContainer'
import Navbar from '../components/Navbar'
import RoundyButton from '../components/RoundyButton'
import { MediumText, RegularText } from '../components/StyledText'
import Colors from '../constants/Colors'
import { Config, User } from '../constants/Models'
import { RootStackParamList } from '../types'

const styles = StyleSheet.create({
    bottomMarg: { marginBottom: 12 },
    bottomMarg2: { marginBottom: 24 },

    inputPadding: {
        paddingVertical: 10,
        paddingHorizontal: 18
    },

    sectionPadding: {
        marginVertical: 24
    },
    widthAuto: {
        width: '75%',
        height: '50%'
    },
    roles: {
    //    padding: 4
    },
    tagGroup : {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "flex-end",
    },
    tagInput: {
        marginRight: 4
    },
    input: {
        borderRadius: 8,
        focusBorder: 'none',
        borderColor: Colors.inputBorder,
        borderWidth: 1,
        fontFamily: 'IBMPlexSans_400Regular',
        fontSize: 20,
        paddingHorizontal: 18,
        paddingVertical: 12
    },
    tag: {
        borderRadius: 9999,
        fontFamily: 'IBMPlexSans_400Regular',
        fontSize: 12,
        paddingHorizontal: 4,
        paddingVertical: 2,
        backgroundColor: Colors.primary,
        color: Colors.white,
        width: "fit-content",
        marginRight: 4
    }
})

type EditUserRouteProp = RouteProp<RootStackParamList, 'EditUser'>

type EditUserProps = {
    navigation: StackNavigationProp<RootStackParamList>,
    route: EditUserRouteProp
}

interface FormValues {
    email?: string;
    roles?: ['client'|'business'|'admin', 'demo'];
    tags?: {name: string, tag: string}[]
}

export default ({ navigation, route}: EditUserProps) => {
    const [item, setItem] = useState<User|null>(null);
    const [config, setConfig] = useState<Config|null>(null);

    useEffect(() => {
                const loadData = async () => {
            try {
                if (route.params.id && route.params.id.length > 0) {
                    
                }
                setItem(await backend.getUser(route.params.id))
                setConfig((await backend.getInfo()).config)
            }
            catch (ex) {
                console.error(ex)
                navigation.goBack()
            }
        }
        loadData()
    },[])

    const initialValues: FormValues = {
        email: item?.email,
        roles: item?.roles,
        tags: item?.tags
    }

    const saveChanges = async (setSubmitting: (isSubmitting: boolean) => void, updates: Partial<User>) => {
        setSubmitting(true);
        try {
            if (item){
                await backend.updateUser(item._id, updates);
            }
        } catch (ex) {
            Alert.alert('Error', `There was an error saving your changes.  ${(ex as Error).message}`, [{text: 'OK'}])
        }
        setSubmitting(false);
        navigation.navigate("Admin");
    }

    return (
        <MaxWidthContainer style={{flexDirection: 'column', backgroundColor: Colors.white}}>
            <Navbar />
            <RegularText style={[styles.bottomMarg, { paddingTop: 48 }]} fontSize={24}>Edit user: <strong>{item?.email}</strong></RegularText>
            <RegularText style={[styles.bottomMarg, {color: Colors.lightGrey}]}>Edit user roles and tags</RegularText>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values: FormValues, {setSubmitting}) => {
                    saveChanges(setSubmitting, values);
                }}> 
                {({handleChange, values, handleSubmit}) => (
                    <Form>
                        <MediumText style={styles.bottomMarg} fontSize={18}>User Roles</MediumText>
                        <View style={[styles.roles, styles.bottomMarg, {display: 'flex', flexDirection: "row"}]}>
                            <MediumText style={{marginLeft: 4}}>
                                <Field type="checkbox" name="roles" value="business" />
                                Business
                            </MediumText>
                            <MediumText style={{marginLeft: 4}}>
                                <Field type="checkbox" name="roles" value="client"/>
                                Client
                            </MediumText>
                            <MediumText style={{marginLeft: 4}}>
                                <Field type="checkbox" name="roles" value="admin"/>
                                Admin
                            </MediumText>
                            <MediumText style={{marginLeft: 4}}>
                                <Field type="checkbox" name="roles" value="demo"/>
                                Demo
                            </MediumText>
                        </View>
                        <View style={styles.roles}>
                            <MediumText fontSize={18}>User Tags</MediumText>
                            <View style={[styles.bottomMarg, {display: 'flex', flexDirection: "row"}]}>
                            {
                                values.tags && values.tags.map((x, i) => {
                                    if(x.tag.length >0) {
                                        return(
                                            <View key={`tag${i}`} style={styles.tag}>
                                            <MediumText style={{color: Colors.white}} >{x.tag}</MediumText> 
                                            </View>
                                        )
                                    }
                                })
                            }
                            </View>
                            <FieldArray 
                                name="tags"
                                render={arrayHelpers => (
                                    <View>
                                        {
                                            values.tags && values.tags.length > 0 ?
                                            (
                                                values.tags.map((x,i) => (
                                                    <View key={i} style={styles.tagGroup}>
                                                        <View style={styles.tagInput}>
                                                            <MediumText>Name:</MediumText>
                                                            <TextInput
                                                                style={styles.input}
                                                                value={values.tags && values?.tags[i] && values.tags[i].name}
                                                                onChangeText={handleChange(`tags[${i}].name`)}/>
                                                        </View>
                                                        <View style={styles.tagInput}>
                                                            <MediumText>Tag:</MediumText>
                                                            <TextInput
                                                                style={styles.input}
                                                                value={values.tags && values?.tags[i] && values.tags[i].tag}
                                                                onChangeText={handleChange(`tags[${i}].tag`)}/>
                                                        </View>
                                                        <View style={{marginRight: 4}}>
                                                            <RoundyButton eventLabel="Edit User - Remove" style={[styles.input, {backgroundColor: "red"}]} onPress={() => arrayHelpers.remove(i)}>Remove</RoundyButton>
                                                        </View>
                                                            <RoundyButton eventLabel="Edit User - Add" style={[styles.input]} onPress={() => arrayHelpers.insert(i, {name: "", tag: ""})}>Add</RoundyButton>
                                                    </View>
                                                ))
                                                )
                                                : <RoundyButton eventLabel="Edit User - Add Tag" style={{width: 150, marginTop: 8}} onPress={() => arrayHelpers.push({name: "", tag: ""})}><FontAwesome5 name="plus" color="white"/>Add Tag</RoundyButton>
                                            }
                                    </View>
                                )}
                                />
                            </View>
                            <RoundyButton eventLabel="Edit User - Save" style={{width: 150, marginTop: 8}} onPress={handleSubmit}>Save</RoundyButton>
                        </Form>
                    )
                }
                </Formik>
         </MaxWidthContainer>
    )
}
