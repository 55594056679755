import AsyncStorage from '@react-native-community/async-storage';
import { AuthSessionResult } from 'expo-auth-session';
import React, { PropsWithChildren, useState } from 'react';
import { ViewProps } from 'react-native';
import backend from '../backend/backend';
import { AuthResponse, User } from '../constants/Models';

type CachedAuth = (AuthResponse & {user?: User|null}) | null;
export const AuthContext = React.createContext<{
  /// The user if applicable
  auth?: CachedAuth;
  user?: User | null;

  /// Attempt to load cached auth 
  bootstrap: () => Promise<string|null>;

  /// Authenticate the user via email password
  registerClient: (email: string) => Promise<void>;

  /// Authenticate the user when config.allowUserCreation is true
  registerUserCreatedClient: (email: string, employer: string) => Promise<void>;

  /// Authenticate a business user via google auth response
  registerBusinessAccount: (result: AuthSessionResult) => Promise<void>;

  /// Authenticate an admin user
  registerAdmin: (email: string, pass: string) => Promise<void>;

  /// Logout the current user 
  logout: () => Promise<void>;
}>({
  bootstrap: async () => { return null },
  registerClient: async () => { },
  registerUserCreatedClient: async () => { },
  registerBusinessAccount: async () => { },
  registerAdmin: async () => { },
  logout: async () => { }
})

interface AuthProviderProps extends PropsWithChildren<ViewProps> {
}

const LocalAuthKey = 'a'

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<CachedAuth>(null)
  // const [user, setUser] = useState<User|null>(null)

  // useEffect(() => {
  //   if (auth && auth.user) {
  //     setUser(auth.user)
  //   }
  //   else {
  //     /// Attempt to get the user and resolve
  //     setUser(null)
  //   }
  // }, [auth])

  // useEffect(() => {
  //   if (auth?.token && auth.token.length > 0) {
      // backend.getProfile()
      // .then(u => setUser(u))
      // .catch(() => clearAuth() )
  //   }
  // }, [auth?.token])

  const saveAuth = async (res: CachedAuth) => {
    try {
      console.log(`Save auth`, res)
      const rawStr = JSON.stringify(res)
      await AsyncStorage.setItem(LocalAuthKey, rawStr)
      setAuth(res)
    }
    catch (ex) {
      await clearAuth()
    }
  }

  const getLocalAuth = async () => {
    try {
      const rawStr = await AsyncStorage.getItem(LocalAuthKey)
      const authData = JSON.parse(rawStr || '{}') as AuthResponse
      if (authData.token) {
        return authData
      }
    }
    catch (ex) { }
    return null
  }

  const fetchUser = async (auth: AuthResponse) => {
    try {
      const user = await backend.getProfile()
      setAuth({ ...auth, user })
    }
    catch (ex) {
      await clearAuth()
    }
  }

  const clearAuth = async () => {
    await AsyncStorage.removeItem(LocalAuthKey)
    setAuth(null)
    // setUser(null)
  }
  
  return (
    <AuthContext.Provider value={{
      auth: auth,
      user: auth?.user,
      bootstrap: async () => {
        try {

          backend.registerTokenProvider(async () => {
            const auth = await getLocalAuth()
            return auth?.token
          })
          
          const data = await getLocalAuth()

          /// Invalid local data
          if (!data || data.token.length == 0) { 
            await clearAuth()  
            return null
          }

          await fetchUser(data)

          return data.token
        }
        catch (ex) {
          await clearAuth()
          console.error(`user is not authed. Token must have expired`)
          return null
        }
      },
      registerClient: async (email: string) => {
        await clearAuth()

        const authData = await backend.registerClient(email)
        await saveAuth(authData)
        await fetchUser(authData)
        
        // setAuth({token, role: 'client'})
        // saveAuth({
        //   ...authData,
        //   user
        // })
      },
      registerUserCreatedClient: async (email: string, employer: string) => {
        await clearAuth()

        const authData = await backend.registerUserCreatedClient(email, employer)
        await saveAuth(authData)
        await fetchUser(authData)
      },
      registerBusinessAccount: async (response: AuthSessionResult) => {
        if (response.type != 'success') {
          /// Invalid response
          return;
        }

        await clearAuth()
        const authData = await backend.registerBusiness(response)
        await saveAuth(authData)
        await fetchUser(authData)
      },
      registerAdmin: async (email: string, pass: string) => {
        await clearAuth()
        const result = await backend.authAdmin(email, pass)
        await saveAuth(result)
        await fetchUser(result)
        // saveAuth(result)
      },
      logout: async () => {
        await clearAuth()
      }
    }}>
      {children}
    </AuthContext.Provider>
  );
} 