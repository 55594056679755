import React from "react";
import { View } from 'react-native'
import { MediumText, RegularText } from "../StyledText";

interface TitleProps {
    name: string;
    owner: string;
    style?: any;
}

export const Title = ({name, owner, style }: TitleProps) => (
    <View style={ style }>
        <MediumText fontSize={20}>{name}</MediumText>
        <RegularText numberOfLines={2} fontSize={16}>{owner}</RegularText>
    </View>
)