import React, { PropsWithChildren, Ref, useImperativeHandle } from "react";
import { FontAwesome5 } from "@expo/vector-icons";
import RoundyButton from "./RoundyButton";
import { MediumText } from "./StyledText";
import { useNavigation, useRoute } from "@react-navigation/native";
import { MatchSort } from "../constants/Models";
import { TextProps } from "react-native";
import * as Location from 'expo-location'
import backend from "../backend/backend";
import { convertUsStateAbbrAndName } from "../helpers/ConvertStateToAbbr";
import events from '../helpers/GoogleAnalytics'


export interface SearchButtonProps extends PropsWithChildren<TextProps> {
    query: string;
    matchSortOptions: MatchSort[]
    fontSize?: number
    showTitle?: boolean
    setWayToSave?: Function
    setRequestOffice?: Function
    label: string
    geo?: {
        attempt: boolean
        coords?: { longitude: number, latitude: number }
    }
    disabled?: boolean
}

export interface SearchButtonRef {
    onSearchPress: () => void;
}

const SearchButton = ({query, matchSortOptions, fontSize, showTitle, setWayToSave = () => {}, setRequestOffice = () => {}, label, ...props}: SearchButtonProps, ref: Ref<SearchButtonRef>) => {
    const navigation = useNavigation()

    useImperativeHandle(ref, () => ({ 
        onSearchPress
    }))


    const onSearchPress = async () => {
        setWayToSave(false);
        setRequestOffice(false);
        
        /// Attempt to get gps
        try {
            if (props.geo && !props.geo.attempt) {
                await backend.updateProfile({ location: props.geo.coords })
            }   
            else {
                const perms = await Location.requestPermissionsAsync()
                if (perms.granted) {
                    const pos = await Location.getLastKnownPositionAsync()
                    if (pos) {
                        const { coords: { latitude, longitude } } = pos
                        await backend.updateProfile({ location: { latitude, longitude }})
                    }
                }
            }
        }
        catch (ex) {
            console.error(ex)
        }

        const matchSort = (matchSortOptions || []).join('|')
        events.sendSearchQueryEvent(query)
        events.sendSearchPriorityEvent(matchSort)
        navigation.navigate('SearchResults', {
            matchSort,
            query: convertUsStateAbbrAndName(query)
        })
    }

    const titleEnabled = showTitle == undefined || showTitle
    return (
        <RoundyButton {...props} onPress={onSearchPress} eventLabel={`Search Button - ${label}`}>
            <FontAwesome5 style={{marginRight: titleEnabled ? 12 : 0}} name='search' color='white' size={fontSize||24} />
            {
                (titleEnabled ) && <MediumText fontSize={fontSize||24}>Search</MediumText>
            }
        </RoundyButton>
    )
}

export default React.forwardRef(SearchButton)
