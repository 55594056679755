import React, { useContext } from "react";
import {
  Image,
  StyleSheet,
  ViewProps,
  View,
  TouchableHighlight,
} from "react-native";

import navLogo from "../assets/images/nav-logo.png";

interface NavProps extends ViewProps {
  children?: React.ReactElement;
}

import Colors from "../constants/Colors";
import { MediumText, RegularText } from "./StyledText";
import { MediaQueryContext, ScreenSize } from "../contexts/MediaQueryContext";
import { Link, useNavigation } from "@react-navigation/native";
import { AuthContext } from "../contexts/AuthContext";

const BlankChild = () => {
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <RegularText
        fontSize={20}
        style={{
          color: Colors.lightGrey,
        }}
      >
        Find a dentist that's a
      </RegularText>
      <RegularText
        fontSize={20}
        style={{
          color: Colors.lightGrey,
        }}
      >
        perfect match for you.
      </RegularText>
    </View>
  );
};

export default (props: NavProps) => {
  const { user, logout: realLogout } = useContext(AuthContext);
  const { screenSize } = useContext(MediaQueryContext);
  const navigation = useNavigation();
  const isCompact = screenSize < ScreenSize.large;

  const logout = async () => {
    try {
      const answer = confirm("Are you sure you want to logout");
      if (!answer) {
        return;
      }
      await realLogout();
    } catch (ex) {
      console.error(ex);
    }
    navigation.navigate("Root");
  };

  return (
    <View
      style={[
        isCompact ? styles.containerTablet : styles.container,
        props.style,
      ]}
    >
      <Link
        to="/"
        style={{
          marginRight: isCompact ? 0 : 32,
          marginBottom: isCompact ? 18 : 0,
        }}
      >
        <Image
          source={navLogo}
          style={{
            marginRight: 20,
            width: 275 * (isCompact ? 0.8 : 1),
            height: 78 * (isCompact ? 0.8 : 1),
          }}
        />
      </Link>
      {props.children ?? (
        <>
          {/* <View style={{flex: 1}} /> */}
          {!isCompact && <BlankChild />}
          <View style={{ flex: 2 }} />
        </>
      )}

      {user?.email && user.email.length > 0 && (
        <View style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingHorizontal: 12}}>
          <TouchableHighlight
            underlayColor="white"
            onPress={logout}
          >
            <RegularText fontSize={14}>
              <MediumText fontSize={16}>Signed in:{"\n"}</MediumText>
              {user.email}
            </RegularText>
          </TouchableHighlight>
          {user?.roles.includes("demo") && (
            <View style={[styles.badge, isCompact && { marginBottom: 20 }]}>
              <MediumText
                style={{ color: "white", fontWeight: 500 }}
                fontSize={12}
              >
                Demo User
              </MediumText>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 20,
    borderBottomColor: Colors.border,
    borderBottomWidth: 1,
  },
  containerTablet: {
    flexDirection: "column",
    alignItems: "center",
    paddingVertical: 20,
    borderBottomColor: Colors.border,
    borderBottomWidth: 1,
  },
  badge: {
    backgroundColor: Colors.primary,
    color: "white",
    paddingVertical: 5,
    borderRadius: 20,
    paddingHorizontal: 12,
    marginTop: 5
  },
});
