import { IBMPlexSans_400Regular, useFonts } from '@expo-google-fonts/ibm-plex-sans'
import React from 'react'

import {
    StyleSheet,
    TextInput,
    TextInputProps,
    View,
    ViewStyle
} from 'react-native'
import Colors from '../constants/Colors'


export interface StyledInputProps extends TextInputProps {
    containerStyle?: ViewStyle
    onEnter?: () => void;
}

const styles = StyleSheet.create({
    main: {
        focusBorder: 'none',
        borderColor: Colors.inputBorder,
        borderWidth: 1,
        borderRadius: 8,
        fontFamily: 'IBMPlexSans_400Regular',
        fontSize: 20,
        paddingHorizontal: 32,
        paddingVertical: 24
    }
})

export default ({style, children, containerStyle, ...props}: StyledInputProps & TextInput['props']) => {
    const [loaded] = useFonts({ IBMPlexSans_400Regular })
    if (!loaded) { return null }

    const onKeyPress = ({ nativeEvent: { key }}: { nativeEvent: { key?: string }} ) => {
        if (key && key.toLowerCase() == 'enter') {
            props.onEnter && props.onEnter()
        }
    }

    return (
        <View style={[{position: 'relative'}, containerStyle]}>
        <TextInput {...props} onKeyPress={onKeyPress} style={[styles.main, style]} />
        {children}
        </View>
    )
}
