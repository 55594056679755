import React, { useContext, useEffect, useState } from "react"
import { StyleSheet, TextInputProps, View, ViewStyle } from "react-native"
import { MediaQueryContext, ScreenSize } from "../contexts/MediaQueryContext"
import StyledInput, { StyledInputProps } from "./StyledInput"
import { RegularText } from "./StyledText"

export interface TextInputWrapperProps extends StyledInputProps {
    label?: string;
    placeholder: string;
}

const styles = StyleSheet.create({
    inputPadding: {
        paddingVertical: 10,
        paddingHorizontal: 18
    }
})
export default ({ label, placeholder, style, ...props }: TextInputWrapperProps) => {
    const [value, setValue] = useState(props.value)
    const { screenSize } = useContext(MediaQueryContext)

    useEffect(() => {
        if (props.value == value) {
            return
        }

        if (props.onChangeText && value != undefined) {
            props.onChangeText(value)
        }
    }, [value])


    if (!label || label.length == 0) {
        return <StyledInput
        
        style={styles.inputPadding}
        {...props}
        onChangeText={setValue} value={value} placeholder={placeholder} />
    } else if (!props.onChangeText) {
        return (
            <View style={[style, screenSize <= ScreenSize.tablet && { flexDirection: 'column'}]}>
                <RegularText style={{marginVertical: 8, marginLeft: 1}} fontSize={18}>{label}</RegularText>
                <StyledInput
                    {...props}
                    style={styles.inputPadding}
                    value={props.value} 
                    placeholder={placeholder} 
                />
            </View>
        )
    }

    return (
        <View style={[style, screenSize <= ScreenSize.tablet && { flexDirection: 'column'}]}>
            <RegularText style={{marginVertical: 8}} fontSize={18}>{label}</RegularText>
            <StyledInput
                {...props}
                style={styles.inputPadding}
                onChangeText={setValue} value={value} placeholder={placeholder} />
        </View>
    )
}