import { NavigationContainer, DefaultTheme, DarkTheme, Theme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { AuthContext } from '../contexts/AuthContext';
import BusinessListings from '../screens/BusinessListings';
import EditListing from '../screens/EditListing';
import EditUser from '../screens/EditUser';
// import LandingScreen from '../screens/Auth';

import NotFoundScreen from '../screens/NotFoundScreen';
import TermsOfService from '../screens/TermsOfService';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import FAQ from '../screens/FAQ';
import SearchOptions from '../screens/SearchOptions';
import SearchResults from '../screens/SearchResults';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import AdminPortal from '../screens/AdminPortal';
import Colors from '../constants/Colors';
import Auth from '../screens/Auth';
import Navbar from '../components/Navbar';
import DentalDifferenceNavbar from '../components/DentalDifferenceNavbar';


const MyTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.white,
    primary: 'rgb(255, 45, 85)',
  },
};

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation() {
  return (
    <NavigationContainer theme={MyTheme} linking={LinkingConfiguration} >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { auth, user } = React.useContext(AuthContext) 

  return (
    <Stack.Navigator headerMode='none' screenOptions={{title: 'MyDentist Match'}}>
      {
        !!user?.roles.includes('client') || !!user?.roles.includes('demo') ? (
          <>
          <Stack.Screen name='SearchOptions' initialParams={{}} component={SearchOptions} />
          <Stack.Screen name='SearchResults' component={SearchResults} options={{title: 'Search Results'}} />    
          </>
        ) : null
      }

      {
        !user && <Stack.Screen name='Root' component={ Auth({ Navbar }) } options={{title: 'MyDentist Match'}} /> 
      }

      {
        !user && <Stack.Screen name='DentalDifference' component={ Auth({ Navbar: DentalDifferenceNavbar, isDentalDifference: true }) } options={{title: 'MyDentist Match'}} /> 
      }

      {
        user?.roles.includes('business') && <Stack.Screen name='BusinessListings' component={BusinessListings} />
      }


      <Stack.Screen name='Admin' component={AdminPortal} />
      <Stack.Screen name='EditListing' component={EditListing} />
      <Stack.Screen name='EditUser' component={EditUser} />
      <Stack.Screen name='PrivacyPolicy' component={PrivacyPolicy} /> 
      <Stack.Screen name='TermsOfService' component={TermsOfService} /> 
      <Stack.Screen name='FAQ' component={FAQ} /> 
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />


    </Stack.Navigator>
  );
}
