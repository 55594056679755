


export enum MatchSort {
    price = "p",
    location = "l",
    reviews = "r",
    hours = "h",
    services = "s"
}

export const defaultSortOptions = [
    MatchSort.price,
    MatchSort.location,
    MatchSort.reviews,
    MatchSort.hours,
    MatchSort.services
]


export interface AuthResponse {
    token: string;
    role: 'client'|'business'|'admin'
}

export interface User {
    _id: string;
    email: string;
    roles: ['client'|'business'|'admin', 'demo'];
    location?: {
        latitude: number;
        longitude: number;
    },
    tags: {name: string, tag: string}[],
    dateCreated: Date,
    inactive?: boolean
}

export const titleForMatchSort: (op: MatchSort|null) => string = (op) => {
    if (!op) { return 'Match' }
    switch(op) {
        case MatchSort.price: return "Price"
        case MatchSort.location: return "Location"
        case MatchSort.reviews: return "Reviews"
        case MatchSort.hours: return "Hours"
        case MatchSort.services: return "Services"
    }
}



// Represents a single result item
export enum ListingType {
    regular = "regular",
    pro = "pro"
}

export interface AddressInfo {
    line1: string;
    line2?: string|null;
    city: string;
    state: string;
    postalCode: string;
}

export interface ContactInfo {
    website?: string|null;
    phone?: string|null;
    email?: string|null;
    address: AddressInfo
}


export interface PlanFee {
    name: string;
    monthly?: number;
    annually?: number;
    deposit?: number;
    percentSavings?: number;
    iopDescription?: string;
    savingsDescription?: string;
    iop?: boolean; 
    iopLink?: string;
}

export interface AlternatePlanFee {
    name: string;
    monthly?: string;
    annually?: string;
    deposit?: string;
    percentSavings?: number;
    iopDescription?: string;
    savingsDescription?: string;
    iop?: string; 
    iopLink?: string;
}

export interface  BusinessItem {
    name: string;
    type: ListingType;
    owner: string;
    matchPercent: number;
    imageUrl?: string | null;
    details?: string | null;
    contact: ContactInfo;
    reviews?: {
        rating: number;
        count: number;
    }
    pricing: {
        inOfficePlan: boolean;
        perioInOfficePlan?: PlanFee|null;
        childInOfficePlan?: PlanFee|null;
        adultInOfficePlan?: PlanFee|null;
        plans?: PlanFee[];
    };
    meta: {
        matchBonus?: number;
        status?: 'unknown'|'claimed',
        google?: { 
            placeId?: string; 
            locationId: string;
        }
    };
}

export interface ClaimedBusinessItem extends BusinessItem {
    _id: string;


    regularServices?: {
        [key: string]: number|string|null
    };
    optionalServices?: {
        [key: string]: number|string|null
    };
}

export interface BusinessAccount {
    /// This is generated by splitting the name and getting the last
    accountId: string;

            // accounts/{id}
    name: string;

    // PERSONAL|
    type: string;

    STATE: {
        // UNVERIFIED | 
        status: string;

        // NOT_VETTED
        vettedStatus: string;
    },

    // URL TO PHOTO
    profilePhotoUrl: string;
}



export interface ServiceItem {
    key: string;
    label: string;
}


export interface ConfigWeights {
    reg_services: number;
    opt_services: number;
    plans: number;
    hours: number;
    claimed: number;
    location: number;
    iop: number;
}
export interface Config  {
    _id: string;
    weights: ConfigWeights;
    matchHideThresh: number;
    matchCurve: number;
    allowAnonymousClients: boolean;
    allowUserCreation: boolean;
    version: string;
    current: boolean;
    generalServices: ServiceItem[];
    optionalServices: ServiceItem[];
}

export interface Requests {
    date1: Date;
    date2: Date;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    preferredTime: string;
    businessContact: Object;
}